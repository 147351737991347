/* 
Author: Sreenivassan R
*/
import React from "react";
import classes from "./error.module.css";
import { useNavigate } from "react-router-dom";
import Button from "../component/UI/Button";
import notFound from "../assetes/images/ERROR_404.png";

function ErrorPage() {
  const navigate = useNavigate();
  function errorHandler() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("modCurrent")
    localStorage.removeItem('location')
    navigate("/");
  }

  return (
    <div className={classes.error}>
      <div className={classes.errorPage}>
        <img src={notFound} alt="404" />
        <div>
          <Button
            type="button"
            customButtonWidth={classes.buttonWidth}
            onClick={errorHandler}>Back to Login Page</Button>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
