/* 
Author: Sreenivassan R
Module: Reset Password
*/
import React, { Fragment, useState } from "react";
import UserFirstScreen from "../UI/UserFirstScreen";
import UserDetailScreen from "../UI/UserDetailScreen";
import classes from "./ForgotPassword.module.css";
import Password from "../UI/Password";
import Button from "../UI/Button";
import { Form } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Toast from "../Toast";
import { toast } from "react-toastify";
import LoadingSpinner from "../UI/LoadingSpinner";
import { resetPasswordHandler } from "../../util/resetpassword";
function ResetPassword() {
  const navigate = useNavigate();
  // const state = useLocation().state;
  const params = useParams();

  const [passval, setPassval] = useState({ password: "", confirmpassword: "" });
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [passwordSet, setPasswordSet] = useState();
  const [passwdchk, setPasswdchk] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const notifysuccess = () =>
    toast.success("Password Reset Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function storePassword() {
    resetPasswordHandler(
      setError,
      setIsLoading,
      params,
      passval,
      setPasswordSet,
      notifyfail,
      navigate,
      notifysuccess
    );
  }

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      storePassword();
    }
  };
  const passwordcheckFn = (e, field) => {
    const { value } = e.target;

   /* Update the appropriate field based on the parameter */
    setPassval((prev) => ({
      ...prev,
      [field]: value,
    }));

    /* Perform password strength check only if it's the password field */
    if (field === "password") {
      var newcheck = [];
      const checkOneCapitalRegax = /[A-Z]/;
      newcheck.push(checkOneCapitalRegax.test(value));
      const checkOneSmallRegax = /[a-z]/;
      newcheck.push(checkOneSmallRegax.test(value));
      const checkOneNumberRegax = /\d/;
      newcheck.push(checkOneNumberRegax.test(value));
      const checkOneSplRegax = /[!@#$%^&*(),.?":{}|<>]/;
      newcheck.push(checkOneSplRegax.test(value));
      newcheck.push(value.length >= 8 ? true : false);
      newcheck.sort((a, b) => {
        if (a === b) {
          return 0;
        }
        if (a === true) {
          return -1;
        }
        return 1;
      });
      setPasswdchk(newcheck);
    }
  };

  return (
    <Fragment>
      <div className={classes.flexScreen}>
        <div className={classes.gridScreen}>
          <UserFirstScreen />
          <div className={classes.gridRight}>
            <Form>
              <UserDetailScreen>
                <div>
                  <div className={classes.loginContainer}>
                    <div className={classes.userContents}>
                      <h2>Reset Password</h2>
                      <p>Please type something you’ll remember</p>
                    </div>
                    <Password
                      label="Password"
                      placeholder="Password"
                      customPassword={classes.passwordBorder}
                      customPasswordEye={classes.customPasswordEye}
                      value={passval.password}
                      // onChange={(e) =>
                      //   setPassval((pre) => ({
                      //     ...pre,
                      //     password: e.target.value,
                      //   }))
                      // }
                      onChange={(e) => passwordcheckFn(e, "password")}
                      onKeyUp={handleKeyPress}
                    />
                    <div className={classes.root}>
                      <div className={classes.passwordContainMin8Characters1}>
                        <div className={classes.textBlock}>
                          <p>Password contain min 8 characters,</p>
                          <p>
                            {" "}
                            1 Upper case, 1 Lower case, 1 Numerical &amp; 1
                            Special character
                          </p>
                        </div>
                      </div>
                      <div className={classes.frame39352}>
                        {passwdchk.map((chk, index) => {
                          var classname = chk
                            ? classes[`rectangle${index + 1}_fill`]
                            : classes[`rectangle${index + 1}_emp`];
                          return <div className={classname}></div>;
                        })}
                      </div>
                    </div>
                    <Password
                      label="Confirm Password"
                      placeholder="Repeat Password"
                      customPassword={classes.passwordBorder}
                      customPasswordEye={classes.customPasswordEye}
                      customMargin={classes.confirmMargin}
                      value={passval.confirmpassword}
                      // onChange={(e) =>
                      //   setPassval((pre) => ({
                      //     ...pre,
                      //     confirmpassword: e.target.value,
                      //   }))
                      // }
                      onChange={(e) => passwordcheckFn(e, "confirmpassword")}
                      onKeyUp={handleKeyPress}
                    />
                    {error && <p className={classes.errorMessage}>{error}</p>}
                    {passwordSet && (
                      <p className={classes.sucessMessage}>passwordSet</p>
                    )}
                    <div className={classes.buttonStyle}>
                      <Button type="button" onClick={storePassword}>
                        {isLoading ? <LoadingSpinner /> : "Submit"}
                      </Button>
                    </div>
                  </div>
                </div>
              </UserDetailScreen>
            </Form>
          </div>
        </div>
      </div>
      <Toast />
    </Fragment>
  );
}

export default ResetPassword;
