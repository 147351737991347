/* 
Author: Sreenivassan R
*/
import React from "react";
import { DatePicker } from "rsuite";
import classes from "./DateSelector.module.css";
import "rsuite/dist/rsuite.min.css";

function SingleDataTime({
  name,
  setFormData,
  onFocus,
  validation,
  value,
  disabled,
  validationError,
  placeholder,
  err,
  setErr
}) {
  function dateHandler(value) {
    if (value === null || value === undefined) {
      /* If the value is null or undefined, clear the form data for this field */
      setFormData((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    } else {
     /* Otherwise, process the date normally */
      function parseISOString(s) {
        var b = s.split(/\D+/);
        return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
      }

      var originalDateString = new Date(value);
      console.log(originalDateString.toISOString());
      var originalDate = parseISOString(originalDateString.toISOString());
      var isoDateString = originalDate.toISOString();
      setFormData((prevState) => ({
        ...prevState,
        [name]: isoDateString,
      }));
      var reversedDate = originalDate;
      var reversedDateTimeString = reversedDate;
      if (reversedDateTimeString) {
        {
          setErr &&
            setErr((prev) => ({
              ...prev,
              [name]: false, 
            }));
        }
      }

      console.log(reversedDateTimeString);
    }
  }

  return (
    <div>
      <DatePicker
        className={` ${classes.DateRange} ${
          disabled ? classes.DateRangeDisable : ""
        } ${validation}`}
        format="dd MMM yyyy hh:mm aa"
        placeholder={placeholder}
        showMeridian
        onChange={dateHandler}
        name={name}
        onFocus={onFocus}
        disabled={disabled}
        disabledDate={(date) => {
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          return date.getTime() < today.getTime();
        }}
        value={value ? new Date(value) : null} // Ensure the value is null if not provided

      />
      {validationError && (
        <span className={classes.errorMsg}>{validationError}</span>
      )}
        {err ? (
        <span className={classes.errorMsg}>
          {err}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default SingleDataTime;
