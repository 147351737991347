import { memo, SVGProps } from 'react';

const Edit11Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M12.9511 8.4717C11.8457 8.84018 10.3717 7.36628 10.7402 6.26086M11.1944 5.8067L8.81284 8.18824C7.77749 9.2236 7.04299 10.5209 6.68787 11.9414L6.57279 12.4016C6.53694 12.5451 6.66685 12.675 6.81028 12.6391L7.27057 12.524C8.69107 12.169 9.98833 11.4345 11.0237 10.3991L13.4052 8.01754C13.6984 7.72437 13.8631 7.32674 13.8631 6.91212C13.8631 6.04873 13.1632 5.34882 12.2998 5.34882C11.8852 5.34882 11.4875 5.51352 11.1944 5.8067Z'
      stroke='#67A9C0'
      strokeWidth={0.85124}
    />
    <path
      d='M14.4711 15.0782H5.95868'
      stroke='#67A9C0'
      strokeWidth={0.85124}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const Memo = memo(Edit11Icon);
export { Memo as Edit11Icon };
