/* 
Author: Sreenivassan R
*/
import React, { useState, useEffect, useRef } from "react";
import classes from "./CustomInput.module.css";

function CustomInput({
  onChange,
  name,
  type,
  placeholder,
  pattern,
  label,
  customInput,
  onKeyUp,
  value,
  onFocus,
  validationErrors,
  validation,
  err,
  totalPosition,
  totalWinners,
  disabled,
  onKeyDown,
  asterisk,
  maxLength,
  onKeyPress,
  comma,
}) {
  const [formattedValue, setFormattedValue] = useState(value);
  const inputRef = useRef(null);

  useEffect(() => {
    if (type === "number" && value && comma) {
      setFormattedValue(formatNumberWithCommas(value));
    } else {
      setFormattedValue(value);
    }
  }, [value, type, comma]);

  const formatNumberWithCommas = (num) => {
    let numStr = num.toString();
    let lastThree = numStr.substring(numStr.length - 3);
    let otherNumbers = numStr.substring(0, numStr.length - 3);
    if (otherNumbers !== "") {
      lastThree = "," + lastThree;
    }
    let res = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree;
    return res;
  };

  const handleChange = (event) => {
    const inputValue = event.target.value.replace(/,/g, ""); /* Remove existing commas */
    const caretPosition = event.target.selectionStart; /* Get current cursor position */

    if (type === "number" && comma) {
      setFormattedValue(event.target.value); /* Temporarily set the formatted value without commas */
      onChange({ ...event, target: { ...event.target, value: inputValue } });

      /* Calculate the new caret position */
      const formatted = formatNumberWithCommas(inputValue);
      const newCaretPosition = calculateNewCaretPosition(event.target.value, formatted, caretPosition);

      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(newCaretPosition, newCaretPosition);
        }
      }, 0);
    } else {
      setFormattedValue(event.target.value);
      onChange(event);
    }
  };

  const calculateNewCaretPosition = (originalValue, formattedValue, originalCaretPosition) => {
    const originalBeforeCaret = originalValue.slice(0, originalCaretPosition);
    const formattedBeforeCaret = formattedValue.slice(0, formattedValue.length - (originalValue.length - originalCaretPosition));
    return formattedBeforeCaret.length;
  };

  return (
    <div className={`${classes.inputContainer} ${validation}`}>
      <div className={classes.customInputFlex}>
        <label>
          {label}
          {totalWinners && (
            <span>(Minimum tickets to enter {totalWinners})</span>
          )}
        </label>
        {asterisk && <span>*</span>}
      </div>
      <input
        ref={inputRef}
        type={"number" ? "text" : "number"}
        placeholder={placeholder}
        pattern={pattern}
        name={name}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        value={formattedValue}
        onFocus={onFocus}
        className={customInput}
        disabled={disabled}
        min={1}
        maxLength={maxLength}
        onKeyPress={onKeyPress}
      />
      {validationErrors && (
        <span className={classes.errorMsg}>{validationErrors}</span>
      )}
      {err ? (
        <span className={classes.errorMsg}>
          This {label} field is required.
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default CustomInput;
