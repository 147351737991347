/* 
Author: Sreenivassan R
Module: Create Series
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import classes from "../CatagoryManagement/CreateCatagory.module.css";
import CustomInput from "../UI/CustomInput";
import { toast } from "react-toastify";
import Button from "../UI/Button";
import { seriesCreate } from "../../util/series";

function CreateSeries({ Hide, id, updateCatagoryDataGet }) {
  const [seriesData, setSeriesData] = useState({
    name: "",
    series: "",
    status: true,
  });
  const [err, setErr] = useState({
    name: false,
    series: false,
  });
  const authToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    if (id) {
        setSeriesData(updateCatagoryDataGet);
    }
  }, [updateCatagoryDataGet]);

  /* Getting data from the Lottery Input */


  const dataHandler = (e) => {
    const { name, value, type } = e.target;

    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }

    if (type === "radio" && name === "radio") {
      setSeriesData((prevState) => ({
        ...prevState,
        status: value === "enable" ? true : false,
      }));
    } else {
      setSeriesData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  /* To Close the Modal popup */
  const cancelHandler = () => {
    Hide();
  };

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function seriesHandler() {
    seriesCreate(
      id,
      authToken,
      setSeriesData,
      setIsSubmitting,
      notifysuccess,
      Hide,
      toast,
      seriesData,
      err,
      setErr
    );
  }

  const title = id ? "Edit" : "Create";

  return (
    <ModalComponent Hide={Hide}>
      <div className={classes.modalPadd}>
        <h3>{title} Series</h3>
        <CustomInput
          label="Series Name"
          placeholder="Series Name"
          customInput={
            err.name ? classes.errorInput : classes.addCatagory
          }
          name="name"
          value={seriesData.name}
          onChange={dataHandler}
          err={err.name}
        />
        <CustomInput
          label="Quantity"
          placeholder="Quantity"
          customInput={
            err.series ? classes.errorInput : classes.addCatagory
          }
          name="series"
          value={seriesData.series}
          onChange={dataHandler}
          err={err.series}
        />
              <div className={classes.checkBoxFunction}>
          <input
            type="radio"
            id="option1"
            name="radio"
            value="enable"
            checked={seriesData.status === true}
            onChange={dataHandler}
          />
          <label htmlFor="option1">Enable</label>

          <input
            type="radio"
            id="option2"
            name="radio"
            value="disable"
            checked={!seriesData.status}
            onChange={dataHandler}
          />
          <label htmlFor="option2">Disable</label>
        </div>
        <div className={classes.functionButton}>
          <Button
            customButton={classes.resetButton}
            customButtonWidth={classes.button}
            Hide={Hide}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            customButtonWidth={classes.button}
            disabled={isSubmitting}
            onClick={seriesHandler}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default CreateSeries;
