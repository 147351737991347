/* 
Author: Sreenivassan R
Module: Forgot Password
*/
import React, { Fragment, useState } from "react";
import { Form } from "react-router-dom";
import UserDetailScreen from "../UI/UserDetailScreen";
import CustomInput from "../UI/CustomInput";
import UserFirstScreen from "../UI/UserFirstScreen";
import classes from "./ForgotPassword.module.css";
import Button from "../UI/Button";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../UI/LoadingSpinner";
import { forgotHandler } from "../../util/forgotPassword";
function Forgotpassword() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  function SendOTP() {
    forgotHandler(setIsLoading, email, navigate, setError);
  }

  const handleKeyPress = (event) => {
    event.preventDefault();
    console.log(event);
    if (event.key === "Enter") {
      SendOTP();
    }
  };
  console.log(email);
  return (
    <Fragment>
      <div className={classes.flexScreen}>
        <div className={classes.gridScreen}>
          <UserFirstScreen />
          <div className={classes.gridRight}>
            <Form>
              <UserDetailScreen>
                <div>
                  <div className={classes.loginContainer}>
                    <div className={classes.userContents}>
                      <h2>Forgot password?</h2>
                      <p>
                        Please enter the Email associated with your account.
                      </p>
                    </div>
                    <CustomInput
                      label="Email Adderss"
                      type="email"
                      placeholder="Email Address"
                      customInput={classes.topMargin}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onKeyUp={handleKeyPress}
                    />
                    {error && <p className={classes.errorMessage}>{error}</p>}
                    <div className={classes.buttonStyle}>
                      <Button
                        type="button"
                        className={classes.buttonStyle}
                        onClick={() => SendOTP()}
                      >
                        {isLoading ? <LoadingSpinner /> : "Send OTP"}
                      </Button>
                    </div>
                  </div>
                </div>
              </UserDetailScreen>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Forgotpassword;
