/* 
Author: Sreenivassan R
Module: Notification Create Modal
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import TextDescription from "../UI/TextDescription";
import { toast } from "react-toastify";
import classes from "../CreateModal.module.css";
import Button from "../UI/Button";
import SingleDataTime from "../SingleDataTime";
import { useNavigate } from "react-router-dom";
import DropDown from "../UI/DropDown";
import { notificationAdd, notifyTypes } from "../../util/notification";

function NotificationCreateModal({
  Hide,
  id,
  notifysuccess,
  notifyfail,
  updateNotificationDataGet,
  setRender,
}) {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState();
  const [notify, setNotify] = useState();
  const [notifyType, setNotifyType] = useState();
  const [subGroupOptions, setSubGroupOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [hasSubGroups, setHasSubGroups] = useState(false);
  const [notificationData, setNotificationData] = useState({
    notificationType: "",
    title: "",
    body: "",
    scheduledTime: new Date().toISOString(),
    group: "",
    value: "",
    isCustom: true,
    isSend: false,
  })

  const handleGroupChange = (group) => {
    const filtered = subGroupOptions.filter((option) => option.group === group);
    setFilteredOptions(filtered);
    setHasSubGroups(
      filtered.length > 0 &&
        filtered[0].subGroup &&
        filtered[0].subGroup.length > 0
    );
  };

  const fetchData = async () => {
    if (!authToken) {
      navigate("/");
    } else {
      try {
        const options = await notifyTypes(authToken);
        setNotify(options.groups);
        setSubGroupOptions(options.groups);
        setNotifyType(options.types);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [authToken]);

  useEffect(() => {
    if (id) {
      setNotificationData(updateNotificationDataGet);
      const initialGroup = updateNotificationDataGet.group;
      if (initialGroup) {
        const filtered = subGroupOptions.filter(
          (option) => option.group === initialGroup
        );
        setFilteredOptions(filtered);
        setHasSubGroups(
          filtered.length > 0 &&
            filtered[0].subGroup &&
            filtered[0].subGroup.length > 0
        );
      }
    }
  }, [authToken, updateNotificationDataGet, subGroupOptions]);

  /* Getting data from the Notification Input */
  const dataHandler = (e) => {
    const { name, value } = e.target;
    setNotificationData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const notificationHandler = () => {
    notificationAdd(
      setIsSubmitting,
      id,
      notificationData,
      authToken,
      toast,
      notifysuccess,
      Hide,
      setRender,
      validateForm,
      setValidationErrors,
      setNotificationData
    );
  };

  const validateForm = (notificationData) => {
    const errors = {};
    errors.title = !notificationData.title ? "Please enter Title" : "";
    errors.body = !notificationData.body ? "Please enter a Description" : "";

    errors.scheduledTime = !notificationData.scheduledTime
      ? "Please enter a scheduled Date & Time"
      : "";

    return errors;
  };

  return (
    <ModalComponent Hide={Hide}>
      <div className={classes.modalPadd}>
        <h3>Create Notification</h3>
      </div>
      <div className={classes.bannerModalHeight}>
        <CustomInput
          label="Title"
          type="text"
          placeholder="Title"
          name="title"
          value={notificationData.title}
          customInput={
            validationErrors.title ? classes.errorInput : classes.inputBorder
          }
          onChange={dataHandler}
          autoComplete="off"
          validationErrors={validationErrors.title}
        />

        <TextDescription
          lable="Description"
          name="body"
          placeholder="Description"
          value={notificationData.body}
          validation={validationErrors.body ? classes.errorInput : ""}
          validationErrors={validationErrors.body}
          onChange={dataHandler}
        />
        <DropDown
          label="Group"
          placeholder="Group"
          name={{ name: "group", id: "groupid" }}
          onChange={(index, group) => handleGroupChange(notify, index, group)}
          setFormData={setNotificationData}
          options={notify}
          value={notificationData.group}
          field="group"
          validation={validationErrors.state ? classes.errorInput : ""}
          validationErrors={validationErrors.state}
        />

        {hasSubGroups && (
          <DropDown
            label="Subgroup"
            placeholder="Subgroup"
            name={{ name: "value", id: "subGroupid" }}
            options={filteredOptions}
            setFormData={setNotificationData}
            value={notificationData.value}
            field="subGroup"
            validation={validationErrors.state ? classes.errorInput : ""}
            validationErrors={validationErrors.state}
          />
        )}

        <div className={classes.timeDate}>
          <label>Start Date & Time</label>
          <SingleDataTime
            dateHandler={dataHandler}
            setFormData={setNotificationData}
            value={notificationData.scheduledTime}
            name="scheduledTime"
            validation={
              validationErrors.scheduledTime ? classes.errorInput : ""
            }
            validationError={validationErrors.scheduledTime}
          />
        </div>

        <DropDown
          label="Notification type"
          placeholder="Notification type"
          options={notifyType}
          field="type"
          value={notificationData.notificationType}
          name={{ name: "notificationType", id: "notificationTypeid" }}
          setFormData={setNotificationData}
          validation={validationErrors.state ? classes.errorInput : ""}
          validationErrors={validationErrors.state}
        />

        <div className={classes.buttonWrap}>
          <Button
            type="button"
            customButtonWidth={classes.button}
            disable={isSubmitting}
            onClick={notificationHandler}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default NotificationCreateModal;
