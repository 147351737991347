/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";

const ADMINURL = process.env.REACT_APP_ADMINURL;
export const fetchProfileInfo = async (authToken, setProfile, profile) => {
  try {
    const id = localStorage.getItem("userId");
    if (!id) {
      throw new Error("User ID not found in local storage");
    }
    console.log("userID", id);
    const response = await fetch(ADMINURL + "/admin/getAdmin", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ AdminId: id }),
    });

    if (!response.ok) {
      throw new Error(response.message || "Failed to get data");
    }

    const data = await response.json();
    console.log("Raw data:", data);
  

    if (typeof data.data === "object" && data.data !== null) {
      if (data.sts === "2") {
        generateToken();
      }
      const {
        username,
        email,
        fullName,
        DOB,
        gender,
        address,
        empId,
        nationality,
        emiratesId,
        mobileno,
        emgcontactnumber,
        emgcontactname,
        roleId: { role, moduleslist },
        imgurl,
      } = data.data;

      let filteredModules = { view: [], add: [], edit: [], delete: [] };
      if (moduleslist && Array.isArray(moduleslist)) {
        moduleslist.forEach((item) => {
          var module = item.module;
          ["view", "add", "edit", "delete"].map((mode) => {
            if (item[mode] === true) {
              filteredModules[mode].push(module);
            }
          });
        });

        // if(filteredModules.view.length === moduleslist.length){
        //   console.log(filteredModules.view.length)
        //   console.log(moduleslist.length)
        //   filteredModules.view = ["All"];
        // } else if(filteredModules.edit.length === moduleslist.length){
        //   filteredModules.edit = ["All"];
        // }
        // console.log(filteredModules.edit.length);
        // console.log(moduleslist.length);
      }

      const formattedDOB = DOB ? new Date(DOB).toLocaleDateString("en-GB") : "";
      console.log(data);
      setProfile([
        {
          username,
          email,
          fullName,
          DOB: formattedDOB,
          gender,
          address,
          empId,
          nationality,
          emiratesId,
          mobileno,
          emgcontactnumber,
          emgcontactname,
          role,
          moduleslist,
          filteredModules,
          imgurl,
        },
      ]);
    
      console.log("Profile data:", profile);
    } else {
      throw new Error("Data received is not in the expected format");
    }

  } catch (error) {
    console.error("Error fetching profile data:", error);
  }
};
