/* 
Author: Sreenivassan R
*/
import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import classes from "./Modal.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const Backdrop = (props) => {
  return <div className={classes.backdrop} ></div>;
};

const ModalOverlay = (props) => {

  return (
    <div className={ `${classes.modal} ${props.modalComponent}`} >
      <div className={`${classes.modalButtonFlex} ${props.modalClose}`}>
        <h3>{props.title}</h3>
        <button type="button" className={classes.Close} onClick={props.Hide}> 
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      {props.children}
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const ModalComponent = (props) => {
  return (
    <Fragment>
     {ReactDOM.createPortal(<Backdrop  />, portalElement)}
      {ReactDOM.createPortal(
        <ModalOverlay Hide={props.Hide} title={props.title}  modalComponent={props.modalComponent} modalClose={props.modalClose}>{props.children}</ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default ModalComponent;
