/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL ;

export const otpHandler = async function verifyOTP (params, otp, setIsLoading, state, navigate, setError) { 
    console.log(Object.values(otp));
    console.log(Object.values(otp).join(""));
    console.log(Number(Object.values(otp).join("")));
    try {
      setIsLoading(true);
      const response = await fetch(
        ADMINURL+"/admin/verify-otp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: params.id,
            emailotp: Number(Object.values(otp).join("")),
          }),
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (responseData.sts === "0") {
        if (state && state.id) {
          console.log(state, "adasdsd");
          navigate("/resetPassword/" + params.id, { state: { id: state.id } });
        } else {
          console.error("State or its id property is null or undefined");
        }
      } else {
        setError(responseData.msg);
      }
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };