/* 
Author: Sreenivassan R
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "./ModalComponent";
import classes from "./Alphanumeric.module.css";
import Button from "./Button";

function Alphapopup({
  Hide,
  onSave,
  index,
  selectedLetters,
  setSelectedLetters,
}) {
  const [selectAll, setSelectAll] = useState(false);


 // To Rember the selected buttons
  useEffect(() => {
    setSelectAll(selectedLetters.length === 26);
  }, [selectedLetters]);

 // To Selecte the Letter button
  const handleLetterClick = (letter) => {
    if (selectedLetters.includes(letter)) {
      setSelectedLetters(
        selectedLetters.filter((selectedLetter) => selectedLetter !== letter)
      );
    } else {
      setSelectedLetters([...selectedLetters, letter]);
    }
  };

  //Check box to Selecet or Unselect All
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);
    if (isChecked) {
      setSelectedLetters(
        [...Array(26).keys()].map((i) =>
          String.fromCharCode("A".charCodeAt(0) + i)
        )
      );
    } else {
      setSelectedLetters([]);
    }
  };

// Only to Show the save button upon Selecting atleast one Letter
  const isSaveDisabled = selectedLetters.length === 0;

  return (
    <ModalComponent Hide={Hide} modalComponent={classes.ButtonmodalComponent} modalClose={classes.closeButton}>
      <div className={classes.alphanumericFlex}>
        <h5>Select</h5>
        <div className={classes.checkAll}>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
          <label>All</label>
        </div>
      </div>
      <div className={classes.popContainerWidthAlpha}>
       <div className={classes.alphaButton}>
       {Array.from({ length: 26 }, (_, i) =>
          String.fromCharCode("A".charCodeAt(0) + i)
        ).map((letter) => (
          <button
            key={letter}
            type="button"
            onClick={() => handleLetterClick(letter)}
            className={
              selectedLetters.includes(letter)
                ? `${classes.buttonPopColorSelected} ${classes.buttonPop}`
                : `${classes.buttonPopColor} ${classes.buttonPop}`
            }
          >
            {letter}
          </button>
        ))}
       </div>
        <br />
       <div className={classes.selectedTextAlpha}>
       {selectedLetters.length > 0 ? (
          selectedLetters.length === 26 ? (
            <p>Selected Buttons: All</p>
          ) : (
            <p>Selected Buttons: {selectedLetters.join(", ")}</p>
          )
        ) : null}
       </div>
        {!isSaveDisabled && (
         <div  className={classes.numericPopSaveWrap}>
           <Button
            onClick={() => onSave(index, selectedLetters)}
            customButton={classes.numericPopSave}
            customButtonWidth={classes.buttonPopup}
          >
            Save
          </Button>
         </div>
        )}
      </div>
    </ModalComponent>
  );
}

export default Alphapopup;
