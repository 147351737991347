/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL
export   const deleteImage = async  function handleDeleteFile(profileImage, imgname, AdminId, setProfileImage, setFormData, fileInputRef, name) {
    // Reset the value of the file input element
    if (profileImage.includes("https://")) {
      if (window.confirm("Are you Sure to delete this Image..")) {
        try {
          const response = await fetch(
            ADMINURL+"/admin/deleteBLOB",
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ blobName: imgname, adminId: AdminId }),
            }
          );
          const data = await response.json();
          if (data.sts == "0") {
            setProfileImage("");
            setFormData((prevState) => ({
              ...prevState,
              [name]: "",
            }));
          } else {
            alert("Image Delete Unsuccessful");
            setProfileImage("");
          }
        } catch (error) {
          alert("Image Delete Unsuccessfull");
          setProfileImage("");
        }
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      setProfileImage("");
    }
  };