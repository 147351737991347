/* 
Author: Manickam S
Module: Dashboard
*/
import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import classes from "./dashboard.module.css";
import DashCard from "./DashCard";
import ReactApexChart from "react-apexcharts";
import "./DashCard";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import NotifyCard from "./NotifyCard";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import { generateToken } from "../../util/tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;

const Dashboard = () => {
  const [dateval, setDateval] = React.useState(1);
  const [participation, setParticipation] = useState(0);
  const [costdata, setCostdata] = useState([]);
  const [sessiondata, setSessiondata] = useState([]);
  const [showFilter, setShowfilter] = useState(false);
  const [date, setDate] = React.useState({
    fromdates: new Date().toISOString().slice(0, 10) + "",
    todates: new Date().toISOString().slice(0, 10) + "",
  });
  const authToken = localStorage.getItem("token");
  console.log(authToken, "Dash");
  const [cards, setCards] = useState({
    totalUser: [0, 0],
    activeUser: [0, 0],
    sold: [0, 0],
    revenue: [0, 0],
  });
  const [results, setResults] = useState({ results: 0, anounced: 0 });
  const [categoryData, setCategorydata] = useState([]);
  const [graphmode, setGraphmode] = useState("sale");
  const [lotData, setLotdata] = useState({ current: {}, previous: {} });
  const [graph, setGraph] = useState({
    series: [],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [4, 2],
        curve: "smooth",
        dashArray: [0, 5],
      },
      legend: {
        position: "top",
        horizontalAlign: "center",
        tooltipHoverFormatter: function (val, opts) {
          return (
            val +
            " - <strong>" +
            opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] +
            "</strong>"
          );
        },
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {},
      tooltip: {
        y: [
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
          {
            title: {
              formatter: function (val) {
                return val;
              },
            },
          },
        ],
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: ["#FFBF0C", "#FF9D0B"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      colors: ["#FF9D0B", "#FFBF0C"],
      grid: {
        borderColor: "#f1f1f1",
      },
    },
  });

  useEffect(() => {
    getLotteryData();
  }, [date]);

  const handleChange = (event) => {
    setDateval(event.target.value);

    if (event.target.value == "7") {
      setShowfilter(true);
    } else {
      let dates = get_all_duration(event.target.value);
      setDate(dates);
      setShowfilter(false);
    }
  };
  var ResultPercentage = Number.isNaN(
    (results.anounced / results.results) * 100
  )
    ? 0
    : (results.anounced / results.results) * 100;
  let options = {
    series: [ResultPercentage],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 0,
            size: "70%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
          },
          track: {
            background: "#F1F1F4",
            strokeWidth: "80%",
            margin: 0, /* margin is in pixels */
          },

          dataLabels: {
            show: true,
            name: {
              offsetY: -10,
              show: true,
              color: "#888",
              fontSize: "17px",
            },
            value: {
              formatter: function (val) {
                return parseInt(val) + "%";
              },
              color: "#111",
              fontSize: "27px",
              fontFamily: "Poppins",
              fontWeight: 600,
              show: true,
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#FFBF0C", "#FF9D0B"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      colors: ["#FFBF0C"],
      stroke: {
        lineCap: "round",
      },
      labels: ["Results"],
    },
  };

  let DonutOPtions = {
    series: Array.isArray(categoryData)
      ? categoryData.map((cat) => cat.count)
      : [],
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },

      labels: Array.isArray(categoryData)
        ? categoryData.map((cat) => cat._id)
        : [],

      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        formatter: function (val, opts) {
          return (
            val +
            '<span style="color:#FF9D0B;float:right;line-height: 18px;margin-left:10px">' +
            opts.w.globals.series[opts.seriesIndex] +
            "</span>"
          );
        },
      },

      responsive: [
        {
          breakpoint: 768, /* for mobile devices */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
        {
          breakpoint: 1024, /*  for tablets and small monitors */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
        {
          breakpoint: 1200, /*  for larger monitors */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
      ],
    },
  };
  let costDonutOPtions = {
    series: Array.isArray(costdata) ? costdata.map((cat) => cat.ticketnos) : [],
    options: {
      chart: {
        width: 380,
        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },

      labels: Array.isArray(costdata) ? costdata.map((cat) => cat._id) : [],
      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        fontSize: "11px",
        formatter: function (val, opts) {
          return (
            /* numberToString(val) */
            " ₹" +
            val +
            '<span style="color:#FF9D0B;margin-left:10px">' +
            opts.w.globals.series[opts.seriesIndex] +
            "</span>"
          );
        },
      },

      responsive: [
        {
          breakpoint: 768, /* for mobile devices */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
        {
          breakpoint: 1024, /*  for tablets and small monitors */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
        {
          breakpoint: 1200, /*  for larger monitors */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
      ],
    },
  };
  console.log({ costDonutOPtions });
  let sessionDonutOPtions = {
    series: Array.isArray(sessiondata)
      ? sessiondata.map((cat) => cat.ticketsold)
      : [],
    options: {
      chart: {
        width: 380,

        type: "donut",
      },
      plotOptions: {
        pie: {
          startAngle: -90,
          endAngle: 270,
        },
      },

      labels: Array.isArray(sessiondata)
        ? sessiondata.map((cat) => cat._id)
        : [],

      dataLabels: {
        enabled: false,
      },
      fill: {
        type: "gradient",
      },
      legend: {
        fontSize: "11px",
        formatter: function (val, opts) {
          val = val ? val : "Others";
          return (
            val +
            '<span style="color:#FF9D0B;margin-left:10px">' +
            opts.w.globals.series[opts.seriesIndex] +
            "</span>"
          );
        },
      },
      responsive: [
        {
          breakpoint: 768, /* for mobile devices  */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
        {
          breakpoint: 1024,/*  for tablets and small monitors  */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
        {
          breakpoint: 1200, /*  for larger monitors */
          options: {
            chart: {
              width: "100%",
              height: "150px",
            },
          },
        },
      ],
    },
  };

  let HalfDonut = {
    series: [participation],
    options: {
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 0,
            size: "65%",
            background: "#fff",
            image: undefined,
            imageOffsetX: 0,
            imageOffsetY: 0,
            position: "front",
          },
          track: {
            background: "#F1F1F4",
            strokeWidth: "85%",
            margin: 5, /* margin is in pixels */
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: -2,
              fontSize: "22px",
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.5,
          gradientToColors: ["#FFBF0C", "#FF9D0B"],
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      colors: ["#FFBF0C"],
      stroke: {
        lineCap: "round",
      },
      labels: ["Average Results"],
    },
    responsive: [
      {
        breakpoint: 768, /* for mobile devices  */
        options: {
          chart: {
            width: "100%",
            height: "150px",
          },
        },
      },
      {
        breakpoint: 1024, /*  for tablets and small monitors */
        options: {
          chart: {
            width: "100%",
            height: "150px",
          },
        },
      },
      {
        breakpoint: 1200, /*  for larger monitors */
        options: {
          chart: {
            width: "100%",
            height: "150px",
          },
        },
      },
    ],
  };

  var cardsLabel = {
    totalUser: "Total Customers",
    activeUser: "Active Customers",
    sold: "Tickets Sold",
    revenue: "Revenue",
  };
  function getdiffernce(from, to) {
    if (from === to) {
      var differnce = 1;
    } else {
      var differnce = differncedate(from, to) + 1;
    }
    return differnce;
  }
  function differncedate(from, to) {
    var to = moment(to, "YYYY-MM-DD");
    var from = moment(from, "YYYY-MM-DD");
    var diff = to.diff(from, "days"); // 1

    return diff;
  }
  function numberToString(num) {
    /* Check if input is a number and within range */
    if (typeof num !== "number" || num < 0 || num > 999) {
      return "Invalid input. Please enter a whole number between 0 and 999.";
    }

    /* Array of words for numbers 0-9 */
    const ones = [
      "Zero",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ];

    /* Array of words for tens (10-19) */
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];

    /* Array of words for tens (20 onwards) */
    const tens = [
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    /*  Handle numbers 0-9 */
    if (num < 10) {
      return ones[num];
    }

    /* Handle numbers 10-19 */
    if (num < 20) {
      return teens[num - 10];
    }

    /* Separate hundreds, tens, and ones digits */
    const hundreds = Math.floor(num / 100);
    const remaining = num % 100;
    const tensDigit = Math.floor(remaining / 10);
    const onesDigit = remaining % 10;

    /* Build the string representation */
    let result = "";

    /* Add hundreds word (if applicable) */
    if (hundreds > 0) {
      result += ones[hundreds] + " Hundred";
    }

    /* Add tens word (if applicable) */
    if (tensDigit > 0) {
      if (result.length > 0) {
        result += " "; /* Add space after hundreds word */
      }
      result += tens[tensDigit - 2]; /* Corrected index to access tens array */
    }

    /*  Add ones word (if applicable) */
    if (onesDigit > 0) {
      if (result.length > 0) {
        result += " ";
      }
      result += ones[onesDigit];
    }

    /* Capitalize the first letter of the final result */
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  const getLotteryData = async (retry = true) => {
    var diffday = getdiffernce(date.fromdates, date.todates);
    var previousdates = getFirstAndLastPreviousDates(date.fromdates, diffday);
    var groupbyflag = 0;
    if (diffday > 31 && diffday <= 365) {
      groupbyflag = "month";
    } else if (diffday > 365) {
      groupbyflag = "year";
    } else {
      groupbyflag = "date";
    }
    try {
      const response = await fetch(ADMINURL + "/admin/dashboard", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          from: date.fromdates,
          to: date.todates,
          groupby: groupbyflag,
          previousdates,
        }),
      });
      const responseData = await response.json();
      if (responseData.sts === "2" && retry) {
        const genToken = await generateToken();
        if (genToken === "Token Generated Successfully") {
          const authToken = localStorage.getItem("token");
          console.log({ authToken });
          await getLotteryData(authToken, false);
          return;
        }
      }
      let lotteryData = responseData.data;
      var sold = 0;
      var revenue = 0;
      var lastsold = 0;
      var lastrevenue = 0;
      var keyCategoryData = {};
      if (lotteryData.categoryData) {
        lotteryData.categoryData.map((cat) => {
          keyCategoryData[cat._id] = cat.count;
        });
      }
      let catData = [];
      lotteryData.categorys.map((category) => {
        var catCount = 0;
        if (keyCategoryData.hasOwnProperty(category._id)) {
          catCount = keyCategoryData[category._id];
        }
        catData.push({ _id: category.categoryname, count: catCount });
      });
      setCategorydata(catData);

      var crTicketsdata = {};
      var prTicketsdata = {};
      var seTicketsdata = {};
      lotteryData.currentticketData.map((d) => {
        crTicketsdata[d.date] = d;
        sold += Number(d.ticketnos);
        revenue += Number(d.Totalcost);
      });
      lotteryData.lastticketData.map((d) => {
        lastsold += Number(d.ticketnos);
        lastrevenue += Number(d.Totalcost);
      });

      lotteryData.previousticketData.map((d) => {
        prTicketsdata[d.date] = d;
      });
      var ticketscount = 0;
      ticketscount = lotteryData.currentLotteryData.reduce(
        (acc, cur) => acc + Number(cur.ticketscount),
        0
      );
      var participation =
        !sold && !ticketscount ? 0 : (sold / ticketscount) * 100;
      setCostdata(lotteryData.costticketData);
      setSessiondata(lotteryData.ticketsDataBySession);
      setParticipation(participation.toFixed(2));
      setCards({
        totalUser: [lotteryData.totalUserCount, lotteryData.prevtotalUserCount],
        activeUser: [lotteryData.ActiveUserCount, 0],
        sold: [sold, lastsold],
        revenue: [revenue, lastrevenue],
      });
      setResults({
        results: lotteryData.lotteryResultToday,
        anounced: lotteryData.lotteryResultTodayAnnounced,
      });
      setLotdata({ current: crTicketsdata, previous: prTicketsdata });
      populateGraph(
        { current: crTicketsdata, previous: prTicketsdata },
        graphmode
      );
    } catch (err) {
      console.log(err);
    }
  };
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    //alert(date);
    return date;
  };
  function getYears(from, to) {
    var d1 = new Date(from),
      d2 = new Date(to),
      yr = [];

    for (var i = d1.getFullYear(); i <= d2.getFullYear(); i++) {
      yr.push(i);
    }

    return yr;
  }

  function getDates(startDate, endDate) {
    // console.log(startDate,endDate);
    var addFn = Date.prototype.addDays;
    var interval = 1;

    var retVal = [];
    var current = new Date(startDate);
    endDate = new Date(endDate);
    while (current <= endDate) {
      var d = new Date(current).toJSON().slice(0, 10).replace(/-/g, "-");
      retVal.push(d);
      current = addFn.call(current, interval);
    }
    return retVal;
  }
  Date.prototype.addMonth = function (days) {
    var date = new Date(this.valueOf());
    date.setMonth(date.getMonth() + days);
    //alert(date);
    return date;
  };

  function getMonth(startDate, endDate) {
    var addFn = Date.prototype.addMonth;
    var interval = 1;

    var retVal = [];
    var current = new Date(startDate);
    endDate = new Date(endDate);
    while (current <= endDate) {
      var d = new Date(current).toJSON().slice(0, 7).replace(/-/g, "-");
      retVal.push(d);
      current = addFn.call(current, interval);
    }
    return retVal;
  }
  function populateGraph(ticketInfo, mode) {
    var diffday = getdiffernce(date.fromdates, date.todates);
    //console.log({ticketInfo})
    var groupbyflag = "date";
    if (diffday > 31 && diffday <= 365) {
      groupbyflag = "month";
    } else if (diffday > 365) {
      groupbyflag = "year";
    } else {
      groupbyflag = "date";
    }
    const fromDate = new Date(date.fromdates);
    fromDate.setFullYear(fromDate.getFullYear() - 1);

    const toDate = new Date(date.todates);
    toDate.setFullYear(toDate.getFullYear() - 1);

    switch (groupbyflag) {
      case "date":
        var datearray = getDates(date.fromdates, date.todates);
        var prevdatearray = getDates(fromDate, toDate);
        break;

      case "month":
        var datearray = getMonth(date.fromdates, date.todates);
        var prevdatearray = getMonth(fromDate, toDate);
        break;
      case "year":
        var datearray = getYears(date.fromdates, date.todates);
        var prevdatearray = getYears(fromDate, toDate);
        break;
    }

    var getField = "";
    if (mode == "sale") {
      getField = "ticketnos";
    } else if (mode == "revenue") {
      getField = "Totalcost";
    }

    var curValues = [];
    var prevValues = [];
    //console.log(datearray,prevdatearray)
    datearray.map((date) => {
      var val = 0;
      if (ticketInfo.current.hasOwnProperty(date)) {
        val = ticketInfo.current[date][getField];
      }
      curValues.push(val);
    });
    prevdatearray.map((date) => {
      var val = ticketInfo.previous.hasOwnProperty(date)
        ? ticketInfo.previous[date][getField]
        : 0;
      prevValues.push(val);
    });
    //console.log({curValues})
    var grapvalues = [
      {
        name: "Current Year",
        data: curValues,
      },
      {
        name: "Last Year",
        data: prevValues,
      },
    ];
    console.log({ datearray, curValues });
    setGraph((prev) => {
      // console.log(prev,"prev")
      return {
        ...prev,
        series: grapvalues,
        options: {
          ...prev.options,
          labels: datearray,
          xaxis: {
            type: "category",
            categories: datearray,
            tickAmount: 10,
            labels: {
              style: {
                colors: [],
                fontSize: "7px",
                fontFamily: "Poppins, Arial, sans-serif",
                fontWeight: 400,
                cssClass: "apexcharts-xaxis-label",
              },
              formatter: function (value, timestamp) {
                //console.log(value)
                if (value) {
                  const [y, m, d] = value.split("-");
                  /* To display month in y-axis */
                  const months = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                  ];
                  var formattedDate = "";
                  if (d) {
                    formattedDate = months[Number(m) - 1] + "-" + d;
                  } else {
                    formattedDate = months[Number(m) - 1];
                  }

                  return formattedDate;
                }
              },
            },
          },
        },
      };
    });
  }

  function get_all_duration(filtervalue) {
    var dates = {};
    var fromdates = "";
    var todates = "";
    if (filtervalue == 1) {
      todates = new Date().toISOString().slice(0, 10) + "";
      fromdates = new Date().toISOString().slice(0, 10) + "";
    }
    if (filtervalue == 2) {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      todates = yesterday.toISOString().slice(0, 10) + "";
      fromdates = yesterday.toISOString().slice(0, 10) + "";
    }

    if (filtervalue == 3) {
      todates = new Date().toISOString().slice(0, 10) + "";
      fromdates = determinedate(6);
    }
    if (filtervalue == 4) {
      todates = new Date().toISOString().slice(0, 10) + "";
      fromdates = determinedate(29);
    }
    if (filtervalue == 5) {
      todates = new Date().toISOString().slice(0, 10) + "";
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      fromdates = year + "-" + "0" + month + "-" + "01";
    }
    if (filtervalue == 6) {
      var now = new Date();
      todates = new Date(now.getFullYear(), now.getMonth(), 0);
      fromdates = new Date(
        now.getFullYear() - (now.getMonth() > 0 ? 0 : 1),
        (now.getMonth() - 1 + 12) % 12,
        1
      );
      var formatDateComponent = function (dateComponent) {
        return (dateComponent < 10 ? "0" : "") + dateComponent;
      };
      var formatDate = function (date) {
        return (
          date.getFullYear() +
          "-" +
          formatDateComponent(date.getMonth() + 1) +
          "-" +
          formatDateComponent(date.getDate())
        );
      };
      fromdates = formatDate(fromdates);
      todates = formatDate(todates);
    }
    dates["fromdates"] = fromdates;
    dates["todates"] = todates;
    return dates;
  }
  //manicam function-2
  function determinedate(value) {
    var date = new Date();
    date.setDate(date.getDate() - value);
    var old = date.toISOString().slice(0, 10) + "";
    return old;
  }
  const changeGraph = (mode) => {
    console.log(mode);
    if (graphmode != mode) {
      setGraphmode(mode);
      populateGraph(lotData, mode);
    }
  };
  function dateHandler(value, mode) {
    console.log(value);
    var originalDateString = new Date(value[0]);

    var isoDateString = originalDateString.toISOString().substring(0, 10);
    console.log(isoDateString);
    var originalDateString1 = new Date(value[1]);

    var isoDateString1 = originalDateString1.toISOString().substring(0, 10);
    setDate({ fromdates: isoDateString, todates: isoDateString1 });
  }
  function getFirstAndLastPreviousDates(startDate, numberOfDays) {
    let date = new Date(startDate);

    // Calculate the last date
    date.setDate(date.getDate() - numberOfDays);
    let firstDate = new Date(date);

    // Calculate the first date (which is just one day before the given startDate)
    date = new Date(startDate);
    date.setDate(date.getDate() - 1);
    let lastDate = new Date(date);

    return {
      firstDate: firstDate.toISOString().slice(0, 10),
      lastDate: lastDate.toISOString().slice(0, 10),
    };
  }
  console.log({ graph });
  console.log(participation);
  return (
    <Box style={{ marginTop: "50px" }} sx={{ flexGrow: 1 }}>
      <Grid container style={{ padding: "0 10px" }}>
        <Grid item xs={9}>
          <Grid container style={{ alignItems: "center" }}>
            <Grid item xs={3}>
              <div>
                <h5>Overview</h5>
              </div>
            </Grid>
            <Grid item xs={6}>
              {showFilter && (
                <DateRangePicker
                  format="yyyy-MM-dd"
                  placeholder="Select Date Range"
                  value={[new Date(date.fromdates), new Date(date.todates)]}
                  style={{ float: "right" }}
                  onChange={dateHandler}
                />
              )}
            </Grid>
            <Grid item xs={12} lg={3}>
              <div style={{ float: "right" }}>
                <FormControl
                  sx={{ m: 1, minWidth: 120, height: "35px" }}
                  size="small"
                >
                  <Select
                    style={{ height: "35px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={dateval}
                    onChange={handleChange}
                  >
                    <MenuItem value={1}>Today</MenuItem>
                    <MenuItem value={2}>Yesterday</MenuItem>
                    <MenuItem value={3}>Last 7 days</MenuItem>
                    <MenuItem value={4}>Last 30 days</MenuItem>
                    <MenuItem value={5}>Current Month</MenuItem>
                    <MenuItem value={6}>Last Month</MenuItem>
                    <MenuItem value={7}>Date Range</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.flexWrap}>
        <Grid item xs={12} lg={9}>
          <Grid container>
            {cards &&
              Object.keys(cards).length &&
              Object.keys(cards).map((cardKey) => {
                return (
                  <Grid
                    item
                    style={{ height: "100px", padding: "0 10px", width: "90%" }}
                    xs={3}
                    className={classes.gridCard}
                  >
                    <div style={{ height: "100px" }}>
                      <DashCard
                        title={cardsLabel[cardKey]}
                        value={cards[cardKey]}
                      />
                    </div>
                  </Grid>
                );
              })}
          </Grid>
          <Grid container className={classes.lotteryGraph}>
            <Grid
              item
              style={{ height: "435px", padding: "0 10px", width: "90%" }}
              xs={12}
            >
              <Grid container>
                <Grid item xs={12} lg={9}>
                  <p className={classes.title}>Sales Details</p>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <div
                    style={{ display: "flex", justifyContent: "flex-start" }}
                  >
                    {/* <Button className={classes.salecard_title_active} variant="text" >Total Lotteries</Button> */}
                    <Button
                      variant="text"
                      className={`${
                        graphmode == "sale"
                          ? classes.salecard_button_active
                          : classes.salecard_button
                      }`}
                      onClick={() => changeGraph("sale")}
                    >
                      Total Ticket Sale
                    </Button>
                    <Button
                      className={`${
                        graphmode == "revenue"
                          ? classes.salecard_button_active
                          : classes.salecard_button
                      }`}
                      variant="text"
                      onClick={() => changeGraph("revenue")}
                    >
                      Total Revenue
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <div
                    style={{
                      height: "50%",
                      marginTop: "10px",
                      borderLeft: "1px solid #EAEAEA",
                      borderRadius: "3px / 100px",
                    }}
                  ></div>
                </Grid>
              </Grid>
              <Grid container style={{ position: "relative", top: "-27px" }}>
                <Grid item xs={12}>
                  <ReactApexChart
                    options={graph.options}
                    series={graph.series}
                    type="line"
                    height={400}
                    width="100%"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} lg={3} style={{ padding: "10px" }}>
          <Grid container>
            <Grid
              item
              style={{ height: "340px", padding: "0 10px", width: "90%" }}
              xs={12}
            >
              <div className={classes.card}>
                <NotifyCard />
                <NotifyCard />
                <NotifyCard />
                <NotifyCard />
                <NotifyCard />
                <NotifyCard />
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginTop: "10px" }}
            className={classes.lotteryResult}
          >
            <Grid item className={classes.resultsChart} xs={12}>
              <p className={classes.title}>Lottery Results</p>
              <ReactApexChart
                options={options.options}
                series={options.series}
                type="radialBar"
                height="100%"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.activitiesGraph}>
        <Grid
          item
          style={{ padding: "0 10px", maxWidth: "20%" }}
          xs={4}
          className={classes.PiechartOverlay}
        >
          <div
            className="Piechart1"
            style={{
              padding: "10px 10px",
              boxShadow: "6px 6px 54px 0px #0000000D",
              borderRadius: "8px",
            }}
          >
            <p className={classes.title}>Lottery participation rates:</p>
            <div className="Piechart" style={{ padding: "10px 0px" }}>
              <ReactApexChart
                options={HalfDonut.options}
                series={HalfDonut.series}
                type="radialBar"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={8}>
          <div className={classes.chartflex}>
            <div className={classes.chartHeight}>
              <p className={classes.title}>Activities by Ticket Price:</p>

              <div style={{ margin: "20px 0" }}>
                <ReactApexChart
                  options={costDonutOPtions.options}
                  series={costDonutOPtions.series}
                  type="donut"
                  height="100%"
                />
              </div>
            </div>

            <div className={classes.chartHeight}>
              <p className={classes.title}>Activities by Session:</p>

              <div style={{ margin: "20px 0" }}>
                <ReactApexChart
                  options={sessionDonutOPtions.options}
                  series={sessionDonutOPtions.series}
                  type="donut"
                  height="100%"
                />
              </div>
            </div>

            <div className={classes.chartHeight}>
              <p className={classes.title}>Activities by Categories</p>

              <div style={{ margin: "20px 0" }}>
                <ReactApexChart
                  options={DonutOPtions.options}
                  series={DonutOPtions.series}
                  type="donut"
                  height="100%"
                  className={classes.chart}
                />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
