/* 
Author: Sreenivassan R
*/
import React, { Fragment } from "react";
import logo from "../../assetes/images/charlesGroupLogo.svg";


import classes from './UserFirstScreen.module.css';
function UserFirstScreen() {
  return (
    <Fragment>
        <div className={classes.posterBg}>
        <div className={classes.logo}>
          <img src={logo} alt="Logo" />
        </div>
        </div>
    
    </Fragment>
  );
}

export default UserFirstScreen;
