/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL ;
export   const fetchCustomerData = async (authToken, setCatagoryData) => {
    try {
      const response = await fetch(
        ADMINURL+"/admin/getUsers",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(
          responseData.message || "Failed to submit data to the server"
        );
      }
      var i = 1;
      console.log("Customer", responseData.data);

      const catagoryData = responseData.data.map((item, index) => {
        const DOJ = new Date(item.doj);
        const buydateOptions = {
          timeZone: "UTC",
          day: "2-digit",
          month: "numeric",
          year: "numeric",
        };

        const formattedBuyDate = DOJ.toLocaleDateString(
          "en-GB",
          buydateOptions
        );

        const balance = parseInt(item.wallet);
        const revenue = parseInt(item.ticketrevenue);

        return {
          uniqueId: `row_${index}`,
          id: item._id,
          rowid: index + 1,
          username: item.username,
          mobileno: item.mobileno,
          email: item.email,
          DOB: item.dob,
          DOJ: `${formattedBuyDate} `,
          userlevel: item.userlevel,
          ticketrevenue: revenue,
          totalDeposit: item.totalDeposit,
          totalWithdraw: item.totalWithdraw,
          balance: balance,
          totalWithdraw: item.totalWithdraw
        };
      });

      setCatagoryData(catagoryData);

      console.log("Data submitted successfully:", catagoryData);
      const catagoryDataWithSelection = catagoryData.map((row) => ({
        ...row,
        isSelected: false,
      }));
      setCatagoryData(catagoryDataWithSelection);
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }
  };