/* 
Author: Sreenivassan R
*/
import React, { useState, useEffect } from "react";
import ModalComponent from "./ModalComponent";
import classes from "./Alphanumeric.module.css";
import Button from "./Button";

function Numericpopup({
  Hide,
  onSave,
  index,
  selectedButtons,
  setSelectedButtons,
}) {
  const [selectAll, setSelectAll] = useState(true);

  /* To Rember the selected buttons */
  useEffect(() => {
    if (selectedButtons.length === 0) {
      setSelectedButtons(
        [...Array(10).keys()].map((num) => num.toString().padStart(2, ""))
      );
    } else {
      setSelectAll(selectedButtons.length === 10);
    }
  }, []);

  /* To Selecte the Number button */
  const handleButtonClick = (buttonValue) => {
    const buttonString = buttonValue.toString().padStart(2, "");
    if (selectedButtons.includes(buttonString)) {
      setSelectedButtons(
        selectedButtons.filter((value) => value !== buttonString)
      );
      setSelectAll(false);
    } else {
      setSelectedButtons([...selectedButtons, buttonString]);
    }
  };

  /* Check box to Selecet or Unselect All */
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAll(isChecked);

    if (isChecked) {
      setSelectedButtons(
        [...Array(10).keys()].map((num) => num.toString().padStart(2, ""))
      );
    } else {
      setSelectedButtons([]);
    }
  };

  /* Only to Show the save button upon Selecting atleast one Number */
  const isSaveDisabled = selectedButtons.length === 0;

  return (
    <ModalComponent
      Hide={Hide}
      modalComponent={classes.ButtonmodalComponent}
    >
      <div className={classes.alphanumericFlex}>
        <h5>Select</h5>{" "}
        <div className={classes.checkAll}>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
          <label>All</label>
        </div>
      </div>
      <div className={classes.popContainerWidth}>
        <div className={classes.buttonCenter}>
          {[...Array(10).keys()].map((buttonIndex) => (
            <button
              key={buttonIndex}
              type="button"
              onClick={() => handleButtonClick(buttonIndex)}
              className={
                selectedButtons.includes(buttonIndex.toString().padStart(2, ""))
                  ? `${classes.buttonPopColorSelected} ${classes.buttonPop}`
                  : `${classes.buttonPopColor} ${classes.buttonPop}`
              }
            >
              {buttonIndex}
            </button>
          ))}
        </div>

        <div className={classes.selectedText}>
          {selectedButtons.length > 0 ? (
            selectedButtons.length === 10 ? (
              <p>Selected Buttons: All</p>
            ) : (
              <p>Selected Buttons: {selectedButtons.join(", ")}</p>
            )
          ) : null}
        </div>
        {!isSaveDisabled && (
          <div className={classes.numericPopSaveWrap}>
            <Button
              onClick={() => onSave(index, selectedButtons)}
              customButton={classes.numericPopSave}
              customButtonWidth={classes.buttonPopup}
            >
              Save
            </Button>
          </div>
        )}
      </div>
    </ModalComponent>
  );
}

export default Numericpopup;
