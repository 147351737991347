/* 
Author: Sreenivassan R
*/
import React from "react";
import { DatePicker } from "rsuite";
import classes from "./DateSelector.module.css";
function SingleDate({ dateHandler, name, setFormData, value, validation }) {
  const isBefore = (date1, date2) => {
    return date1.getTime() < date2.getTime();
  };
  function dateHandler(value) {
    var originalDateString = new Date(value);

    var isoDateString = originalDateString.toISOString();

    setFormData((prevState) => ({
      ...prevState,
      [name]: isoDateString,
    }));
  }
  const today = new Date(); /* Get today's date */
  const eighteenYearsAgo = new Date(today); /* Create a new date object from today */
  eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
  return (
    <DatePicker
      className={`${classes.DateRange} ${validation}`}
      format="dd/MM/yyyy"
      // defaultValue={new Date(eighteenYearsAgo)}
      disabledDate={(date) => !isBefore(date, new Date(eighteenYearsAgo))}
      placeholder="DD/MM/YYYY"
      onChange={dateHandler}
      value={new Date(value ? value : eighteenYearsAgo)}
      name={name}
    />
  );
}

export default SingleDate;
