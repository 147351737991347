/* 
Author: Manickam S
Module: Notify Card
*/
import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import classes from "./dashboard.module.css";
import Icon from "../../assetes/images/Vector@3x.png";
const NotifyCard = ({ title, value }) => {
  return (
    <Box className={classes.notify_card} sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={2}>
            <div className={`${classes.flex_center} ${classes.notify_icon_div}`}><img src={Icon} height="25" width="25" /></div>
    
        </Grid>
        <Grid item xs={10}>
          <Grid container style={{height:"12px"}}>
            <Grid item xs={12}><span className={classes.notify_title1}>Message</span></Grid>
          </Grid>
          <Grid container style={{height:"16px"}}>
            <Grid item xs={12}>
                <div style={{display:"flex"}}>
                    <div style={{width:"30%"}}><span className={classes.notify_title2}>Admin</span></div>
                    <div style={{width:"70%",    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"}}> <span className={classes.notify_text}>Hi sir,GoodMornsdfsdfzxdcsxdfdsfdsfdi</span></div>
                    </div>
                    
                  </Grid>
          </Grid>
          <Grid container style={{height:"12px"}}>
            <Grid item xs={12}><div style={{float:"right",}}><span className={classes.notify_text1}>2 minutes ago</span></div></Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotifyCard;
