/* 
Author: Sreenivassan R
Module: How to Play Details
*/
import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "../ModalDetails.module.css";
import HowToPlayCreateModal from "./HowToPlayCreateModal";
import { fetchhowToplayData, howToPlayDelete } from "../../util/howtoplay";
import DataGridDefault from "../UI/DataGridDefault";

function HowToPlayDetails() {
  const [howToPlayGet, setHowToPlayGet] = useState([]);
  const [howToPlayGetEdit, setHowToPlayGetEdit] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState();

  const authToken = localStorage.getItem("token");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchhowToplayData(authToken, setHowToPlayGet, setLoading);
    }
  }, []);

  let columns = [
    { field: "heading", headerName: "Heading", width: 500 },
    {
      field: "innerHTML",
      headerName: "Description",
      width: 700,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: (val) => {
        var id = val.id;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(val.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  /* Handler function on Edit Icon Click */ 
  const handleEditClick = (row) => {
    console.log(row);
    setId(row._id);
    setHowToPlayGetEdit(row);
    setShow(true);
  };

  /* Handler function on Delete Icon Click */
  function handleDeleteClick(id) {
    howToPlayDelete(id, authToken, notifysuccess, notifyfail);
  }
 
  /* Handler function to show the How To Play Create Modal */
  function faqAddHandler() {
    setHowToPlayGetEdit("");
    setShow(true);
  }

 /* Handler function to hide the How to Play */
  function hideHandler() {
    setShow(false);
  }

  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading="How to play"
        rows={howToPlayGet}
        columns={columns}
        loading={loading}
        permission={permission}
        date={false}
        createFunction={faqAddHandler}
      />
      {show && (
        <HowToPlayCreateModal
          id={id}
          updateHowToPlayDataGet={howToPlayGetEdit}
          notifysuccess={notifysuccess}
          notifyfail={notifyfail}
          Hide={hideHandler}
        />
      )}
      <Toast />
    </div>
  );
}

export default HowToPlayDetails;
