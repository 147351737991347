/* 
Author: Manickam S
Module: Upload Remote Url
*/
import React, { useState, useRef, useEffect } from "react";
import Tesseract from "tesseract.js";
import ModalComponent from "../UI/ModalComponent";
import classes from "./UploadRemortUrl.module.css";
import Button from "../UI/Button";
import LoadingSpinner from "../UI/LoadingSpinner";
import { useNavigate } from "react-router-dom";
const ADMINURL = process.env.REACT_APP_ADMINURL;

const OCRComponent = ({ lotteryId, winningcombination }) => {
  const [stext, setText] = useState(winningcombination);
  const [show, setShow] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resultImg, setResultImg] = useState(false);
  const fileInputRef = useRef(null);
  const [isSubmitting, setIsSubmitting] = useState();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    /* Programmatically trigger the click event on the file input */
    fileInputRef.current.click();
  };

  function hideHandler() {
    setShow(false);
    setResultImg(false);
  }

  useEffect(() => {
    if (winningcombination) {
      setText(winningcombination);
    }
  }, [winningcombination]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const src = URL.createObjectURL(file);
    setImageSrc(src);
    setIsSubmitting(true);
    if (file) {
      const reader = new FileReader();

      reader.onload = async (event) => {
        const imageData = event.target.result;

        /* Perform OCR on the image data */
        try {
          console.log("sdfzasdfsdfdsfsdfds");
          const {
            data: { text },
          } = await Tesseract.recognize(imageData);
          //console.log("sdfsdfds")
          var prizeArray = convertTextToPrizeArray(text);
          // console.log(prizeArray)
          setText(prizeArray);
          setShow(true);
          setIsSubmitting(false);
        } catch (error) {
          console.error("Error during OCR:", error);
        }
      };

      reader.readAsDataURL(file);
    }
  };
  const convertTextToPrizeArray = (text) => {
    // Splitting the text based on the pattern of the prize entries
    const prizeEntries = text.match(
      /(?:Ist Prize|1st Prize|2nd Prize|3rd Prize|4th Prize|5th Prize|Sth Prize|6th Prize).*?(?=(?:Ist Prize|1st Prize|2nd Prize|3rd Prize|4th Prize|5th Prize|Sth Prize|6th Prize|$))/gs
    );

    /* Formatting each prize entry */
    const formattedPrizes = {};
    prizeEntries.map((entry) => {
      // Removing extra whitespaces
      entry = entry.replace(/\n/g, " ").replace(/\s+/g, " ").trim();
      console.log(entry);
      var entryStart = entry.substr(0, 3).replace("I", "1").replace("S", "5");

      // console.log({entryStart})
      var splitedString = entry.split("time")[0];
      // console.log({splitedString})
      var prizeStartArray = extractWinningNumbers(splitedString);
      console.log({ prizeStartArray });
      var length = prizeStartArray.length;
      if (entryStart == "Ist" || entryStart == "1st") {
        if (prizeStartArray.length == 1 || prizeStartArray.length == 2) {
          formattedPrizes["Consolation"] = [
            extractWinningNumbers(entry).at(-1),
          ];
        } else {
          var randomText = extractWinningNumbers(entry);
          var prizes = randomText.splice(length, 2);
          console.log({ prizes });
          if (!prizes.length) {
            prizes = [prizeStartArray.at(-1)];
          }

          formattedPrizes[entryStart.substring(0, 1)] = [prizes.join("")];
        }
      } else {
        var randomText = extractWinningNumbers(entry);
        var prizes = randomText.splice(length, prizeStartArray.at(-1));
        // console.log({prizes})
        formattedPrizes[entryStart.substring(0, 1)] = prizes;
      }
    });
    //console.log({formattedPrizes})
    return formattedPrizes;
  };
  const extractWinningNumbers = (text) => {
    const regex =
      /\b(?:\d*[a-zA-Z][a-zA-Z\d]*\d+[a-zA-Z\d]*|[a-zA-Z]*\d+[a-zA-Z\d]*\d*[a-zA-Z\d]*)\b/g;

    const matches = text.match(regex);

    if (matches) {
      const numbers = matches.map((match) => match.trim());
      return numbers.filter((num) => num !== "");
    }
    return [];
  };
  console.log(stext, "text");

  const Draw = async (id, mode) => {
    //alert("sdfsdfedsf");
    const authToken = localStorage.getItem("token");
    // console.log(authToken)
    try {
      mode === "save" ? setIsLoading(true) : setLoading(true);

      // console.log(stext,mode,id);
      const postdata = JSON.stringify({
        lotteryId: id,
        drawStatus: mode,
        drawsInfo: stext,
      });
      const response = await fetch(ADMINURL + "/admin/alphaDraw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: postdata,
      });
      const responseData = await response.json();
      mode === "save" ? setIsLoading(false) : setLoading(false);
      alert(responseData.msg);
      hideHandler();
    } catch (err) {
      console.log(err, "sdrfsdfds");
    }
  };

  return (
    <div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleFileChange}
      />

      {winningcombination && Object.keys(winningcombination).length ? (
        <Button
          onClick={() => setShow(true)}
          customButtonWidth={classes.resultButton}
        >
          Saved
        </Button>
      ) : (
        <Button
          //onClick={handleButtonClick}
          onClick={() => Draw(lotteryId, "Draw")}
          customButtonWidth={classes.resultButton}
        >
         
          {isSubmitting ? <LoadingSpinner spinnerWidth={classes.loaderWidth}/> : "Draw"}
        </Button>
      )}

      {show && (
        <ModalComponent
          Hide={() => hideHandler()}
          modalComponent={classes.modalWidth}
          modalClose={classes.modalClose}
        >
          <div className={classes.profileFlex}>
            <div style={{ margin: "0", width: "100%" }}>
              <div className={classes.infoFlex}>
                <div>
                  <h4>Draw Information</h4>
                  {imageSrc && (
                    <img
                      className={classes.resultImage}
                      src={imageSrc}
                      alt="image"
                    />
                  )}
                </div>
                <div>
                  {stext &&
                    Object.keys(stext).length > 0 &&
                    Object.keys(stext).map((prize) => {
                      return (
                        <div className={classes.infoFlex}>
                          <div className={classes.flexSpan}>
                            <div className={classes.profileTitle}>
                              <p>{prize}</p>
                            </div>
                            <span>:</span>
                          </div>
                          <div className={classes.profileInfoDetail}>
                            <p style={{ wordBreak: "break-all" }}>
                              {stext[prize] && stext[prize].join(",")}
                            </p>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.infoButtonFlex}>
            <div>
              <Button
                onClick={() => handleButtonClick()}
                customButtonWidth={classes.uploadResult}
              >
                {isSubmitting ? <LoadingSpinner /> : "ReUpload"}
              </Button>
            </div>
            <div>
              <Button
                onClick={() => Draw(lotteryId, "save")}
                disabled={isLoading}
                customButtonWidth={classes.uploadResult}
              >
                {isLoading ? <LoadingSpinner /> : "Save"}
              </Button>
            </div>
            <div>
              <Button
                onClick={() => Draw(lotteryId, "Draw")}
                disabled={loading}
                customButtonWidth={classes.uploadResult}
              >
                {loading ? <LoadingSpinner /> : "Draw"}
              </Button>
            </div>{" "}
          </div>
        </ModalComponent>
      )}
    </div>
  );
};

export default OCRComponent;
