/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";

const ADMINURL = process.env.REACT_APP_ADMINURL;
export const fetchLotteryData = async (
  lotteryId,
  authToken,
  setticketdata,
  setResultData,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/winningList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ lotteryId: lotteryId }),
    });
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchLotteryData(
          lotteryId,
          authToken,
          setticketdata,
          setResultData,
          false
        );
        return;
      }
    }

    console.log(responseData, "asdasds");
    var i = 0;
    var ticketdata = responseData.data[0].ticketsoldlist.map((tic) => {
      const buyDateTime = new Date(tic.buydatetime);

      // buyDateTime
      const buydateOptions = {
        timeZone: "UTC",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const buytimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedBuyDate = buyDateTime.toLocaleDateString(
        "en-US",
        buydateOptions
      );
      const formattedBuyTime = buyDateTime.toLocaleTimeString(
        "en-US",
        buytimeOptions
      );

      return {
        ...tic,
        buyername: tic.buyername ? tic.buyername : "UnSold",
        level: "Beginner",
        id: i++,
        buydatetime: `${formattedBuyDate}  ${formattedBuyTime}`,
      };
    });

    const resultinfo = responseData.data.map((item) => {
      const resultStartTime = new Date(item.resultdatetime);

      // Result Time
      const resultdateOptions = {
        timeZone: "UTC",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const resulttimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedResultDate = resultStartTime.toLocaleDateString(
        "en-US",
        resultdateOptions
      );
      const formattedResultTime = resultStartTime.toLocaleTimeString(
        "en-US",
        resulttimeOptions
      );

      // End Time
      const ticketEndTime = new Date(item.ticketenddatetime);

      const enddateOptions = {
        timeZone: "UTC",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const endtimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedEndDate = ticketEndTime.toLocaleDateString(
        "en-US",
        enddateOptions
      );
      const formattedEndTime = ticketEndTime.toLocaleTimeString(
        "en-US",
        endtimeOptions
      );

      //Start Time

      const ticketStartTime = new Date(item.ticketstartdatetime);

      const startdateOptions = {
        timeZone: "UTC",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const starttimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedStartDate = ticketStartTime.toLocaleDateString(
        "en-US",
        startdateOptions
      );
      const formattedStartTime = ticketStartTime.toLocaleTimeString(
        "en-US",
        starttimeOptions
      );

      return {
        id: item._id,
        resultId: item.id,
        name: item.name,
        totalprize: item.totalprize,
        description: item.description,
        resultdatetime: {
          resultdate: `${formattedResultDate}`,
          resulttime: formattedResultTime,
        },
        ticketenddatetime: {
          enddate: formattedEndDate,
          endtime: formattedEndTime,
        },
        ticketstartdatetime: {
          startdate: formattedStartDate,
          starttime: formattedStartTime,
        },
        ticketscount: item.ticketscount,
        cost: item.cost,
        prizelist: item.prizelist,
        img: item.imgurl,
      };
    });
    console.log(resultinfo, "asdasdsads");
    console.log("asd", ticketdata);
    setResultData(resultinfo);
    setticketdata(ticketdata);
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const fetchLotteryResult = async (
  authToken,
  toast,
  setLotteryData,
  shouldGetPageLength,
  page,
  setPageLength,
  startDate,
  endDate,
  pageLimit,
  searchValue,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getLottery", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        mode: "lottery",
        limit: pageLimit,
        page: page,
        from: startDate,
        to: endDate,
        isPrint: true,
        getTotalRecords: shouldGetPageLength,
        search: searchValue,
      }),
    });
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchLotteryResult(
          authToken,
          toast,
          setLotteryData,
          shouldGetPageLength,
          page,
          setPageLength,
          startDate,
          endDate,
          pageLimit,
          searchValue,
          false
        );
        return;
      }
    }

    console.log(
      "loteryList 0",
      responseData.data.lotterylist.map((item, index) => ({
        ...item,
        id: index + 1,
      }))
    );
    console.log("loteryList 1", responseData);

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    if (responseData.sts === "1") {
      const notifyfail = () =>
        toast.error(responseData.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      notifyfail();
      return;
    }

    const lotteryData = responseData.data.lotterylist.map((item) => {
      let pageLength = responseData.data.Totalcount;
      console.log(pageLength);
      if (shouldGetPageLength) {
        setPageLength(pageLength); // Update pageLength only when shouldGetPageLength is true
      }
      const ticketStartTime = new Date(item.resultdatetime);

      const startdateOptions = {
        timeZone: "UTC",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const starttimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedStartDate = ticketStartTime.toLocaleDateString(
        "en-US",
        startdateOptions
      );
      const formattedStartTime = ticketStartTime.toLocaleTimeString(
        "en-US",
        starttimeOptions
      );

      const lotterycategoryname = Array.isArray(item.lotterycategoryid)
        ? item.lotterycategoryid.map((item) => item.categoryname)
        : [];

      return {
        id: item._id,
        name: item.name,
        lotterycategoryname: lotterycategoryname,
        ticketstartdatetime: `${formattedStartDate}   ${formattedStartTime}`,
        totalprize: item.totalprize,
        cost: item.cost,
        winningcombination: item.winningcombination,
        lotterystatus: item.lotterystatus,
      };
    });

    setLotteryData(lotteryData);
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};
