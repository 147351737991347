import { memo } from 'react';

import resets from '../_resets.module.css';
import classes from './Created.module.css';
import { TickCircle1Icon } from './TickCircle1Icon.js';

const Created = memo(function Created(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.label}>
        <div className={classes.tickCircle1}>
          <TickCircle1Icon className={classes.icon} />
        </div>
        <div className={classes.created}>Created</div>
      </div>
    </div>
  );
});

export { Created };
