/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;


export const fetchUserData = async (
  setUserData,
  authToken,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getAdmins", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();
    console.log(responseData);

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated")
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({authToken})
        await fetchUserData(setUserData, authToken, false); 
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    var i = 1;

    const userData = responseData.data.map((item) => {
      return {
        id: item._id,
        name: item.name,
        empId: item.empId,
        role: item.roleId.role,
        mobileno: item.mobileno,
        email: item.email,
        username: item.username,
        status: true,
      };
    });
    console.log("Data submitted successfully data:", responseData);
    setUserData(userData);
    console.log("Data submitted successfully:", userData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const userDelete = async function handleDeleteClick(
  row,
  authToken,
  notifyfail,
  notifysuccess,
  setRender
) {
  const isConfirmed = window.confirm("Do You Want to Delete this User?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: row.id, mode: "delete" }),
      });
      console.log(response);
      // if (response.sts === "2") {
      //   const genToken = await generateToken();
      //   if (genToken === "Token Generated Successfully") {
      //     userDelete(row, authToken, notifyfail, notifysuccess, setRender);
      //   }
      // }

      if (response.status === "0") {
        notifysuccess("Success");
      } else {
        notifyfail();
      }

      setTimeout(() => {
        setRender((prev) => !prev);
      }, 1000);
    } catch (error) {
      notifyfail(error.message);
    }
  }
};

// Role
export const roleApi = async (authToken) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getRoles", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();
    console.log({ data });
    // if (data.sts === "2") {
    //   const genToken = await generateToken();
    //   if (genToken === "Token Generated Successfully") {
    //     roleApi(authToken);
    //   }
    // }
    return data.data.roles;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

// User Data
export const getAdmin = async (AdminId, authToken) => {
  if (AdminId) {
    try {
      const response = await fetch(ADMINURL + "/admin/getAdmin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ AdminId: AdminId }),
      });
      const data = await response.json();
      console.log("admin", data);
      // if (data.sts === "2") {
      //   const genToken = await generateToken();
      //   if (genToken === "Token Generated Successfully") {
      //     getAdmin(AdminId, authToken);
      //   }
      // }
      return { ...data.data, roleId: data.data.roleId._id };
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
      return [];
    }
  }
};

// Lottery State
export const stateApi = async (authToken) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getStates", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();
    console.log(data.sts);
    // if (data.sts === "2") {
    //   const genToken = await generateToken();
    //   if (genToken === "Token Generated Successfully") {
    //     getAdmin(authToken);
    //   }
    // }
    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};
