/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL
export const fetchhowToplayData = async (
  authToken,
  setHowToPlayGet,
  setLoading
) => {
  try {
    setLoading(true);
    const response = await fetch(ADMINURL + "/admin/instructions", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    var i = 1;
    console.log("Customer", responseData.data);

    const howToPlayGet = responseData.data.map((item, index) => {
    
      return {
        uniqueId: `row_${index}`,
        id: item._id,
        _id: item._id,
        heading  :item.heading,
        innerHTML: item.innerHTML
      };
    });

    setHowToPlayGet(howToPlayGet);
    setLoading(false);
    console.log("faqDataGet", howToPlayGet);
    console.log("Data submitted successfully:", howToPlayGet);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const createHowToPlayData = async function howtoplayHandler(
  authToken,
  sethowToPlayData,
  setIsSubmitting,
  id,
  howToPlayData,
  validateForm,
  setValidationErrors,
  toast,
  notifysuccess,
  Hide,
  setRender
) {
  const errors = validateForm(howToPlayData);
  console.log(errors);

  if (Object.values(errors).some((va) => va !== "")) {
    setValidationErrors(errors);
    console.log(errors);
    console.log("Form Data:", howToPlayData);
    return;
  } else {
    sethowToPlayData((prevFormData) => ({
      ...prevFormData,
    }));
  }

  const innerHTMLValue =
    typeof howToPlayData.innerHTML === "object"
      ? howToPlayData.innerHTML.__html
      : howToPlayData.innerHTML;

  try {
    setIsSubmitting(true);
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };

    // Include innerHTMLValue in the body object
    const response = await fetch(
      ADMINURL + "/admin/instructions",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ ...howToPlayData, ...postdata, innerHTML: innerHTMLValue }), 
      }
    );

    const responseData = await response.json();
    console.log("loteryList", responseData);
    setIsSubmitting(false);
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    if (responseData.sts === "1") {
      const notifyfail = () =>
        toast.error(responseData.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      notifyfail();
      return;
    }
    if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    setRender((prev) => !prev);
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};


export const howToPlayDelete = async function handleDeleteClick(
  id,
  authToken,
  notifysuccess,
  notifyfail
) {
  console.log(id);
  const isConfirmed = window.confirm("Do You Want to Delete this How To Play?");
  if (isConfirmed) {
    try {
      const response = await fetch(
        ADMINURL+"/admin/instructions",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ id: id, mode: "delete" }),
        }
      );
      console.log("response categery", response);

      if (response.status === 200) {
        notifysuccess();
        window.location.reload();
      }
      if (
        response.status === 413 ||
        response.status === 500 ||
        response.status === 404
      ) {
        notifyfail();
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  }
};
