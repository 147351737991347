/* 
Author: Sreenivassan R
Module: Role Management
*/
import React, { useState, useEffect } from "react";
import classes from "./RoleManagement.module.css";
import Toast from "../Toast";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import CreateRoleModal from "./CreateRoleModal";
import { fetchRoleData, roleDelete } from "../../util/role";
import DataGridDefault from "../UI/DataGridDefault";

function RoleManagement() {
  const routerstate = useLocation();
  let permission = routerstate.state;
  const authToken = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [roles, setRoles] = useState([]);
  const [module, setModule] = useState([]);
  const [inroles, setInroles] = useState([]);
  const [id, setId] = useState("");
  const [rolename, setRolename] = useState([]);
  const [render, setRender] = useState(false);
  const Hide = () => {
    setRolename("");
    setId("");
    setInroles([]);
    setShow(false);
  };
  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    setLoading(true);
    fetchRoleData(setRoles, authToken, setModule);
    setLoading(false);
  }, [render, authToken]);

  function roleCatagory() {
    setShow(true);
  }

  let columns = [
    { field: "id", headerName: "S No",flex:1, hidden: true },
    { field: "role", headerName: "Roles", flex:1 },
    { field: "module", headerName: "Modules", flex:2},
    { field: "users", headerName: "No of Users", flex:1 },
  ];
  if (permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        flex:1,
        cellClassName: "actions",
        getActions: (val) => {
          if (val.row.users !== 0) {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className="textPrimary"
                onClick={() => handleEditClick(val.row)}
                color="inherit"
              />,
            ];
          }

          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(val.row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(val.row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission.edit && !permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (val) => {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(val.row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (!permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (val) => {
          //var id = lottId.row.id;

          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(val.row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }

  const handleEditClick = (row) => {
    console.log(row, "1");
    console.log(row.moduleslist);
    setRolename(row.role);
    setId(row._id);
    setInroles(row.moduleslist);
    setShow(true);
  };

  function handleDeleteClick(row) {
    roleDelete(row, notifyfail, notifysuccess, setRender, authToken);
  }

  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading="Role Management List"
        rows={roles}
        columns={columns}
        loading={loading}
        permission={permission}
        date={false}
        createFunction={roleCatagory}
        sortField={"id"}
        searchInput={false}
        lists={roles}
        report="role"
      />
      {show && (
        <CreateRoleModal
          setRender={setRender}
          inroles={inroles}
          id={id}
          rolename={rolename}
          notifysuccess={notifysuccess}
          notifyfail={notifyfail}
          modules={module}
          Hide={Hide}
        />
      )}
      <Toast />
    </div>
  );
}

export default RoleManagement;
