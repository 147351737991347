/* 
Author: Sreenivassan R
*/
import React from 'react';
import { DatePicker } from 'rsuite';
import classes from './DateSelector.module.css';

function DateFilter({ onChange, value, placeholder, label }) {
  return (
   <div className={classes.dateFlex}>
    <label>{label}</label>
    <DatePicker
    className={classes.DateRange}
    placeholder={placeholder}
    format="dd/MM/yyyy"
    onChange={onChange}
    value={value}
    /></div>
  );
}

export default DateFilter;
