/* 
Author: Sreenivassan R
*/
import React, { Fragment } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import classes from './QuillEditor.module.css'

function QuillEditor({value, onChange, name, label}) {

  return (
   <Fragment>
     <label className={classes.title}>{label}</label>
    <ReactQuill theme="snow" name={name} value={value} onChange={onChange} className={classes.quillHeigth}/> 
   </Fragment>
  )
}

export default QuillEditor