/* 
Author: Sreenivassan R
Module: Lottery Management Alpha
*/
import React, { Fragment, useEffect, useRef, useState } from "react";
import classes from "./LotteryManagementAdd.module.css";
import DropDown from "../UI/DropDown";
import CustomInput from "../UI/CustomInput";
import {
  PriceApi,
  SeriesApi,
  catagoryApi,
  getlottery,
  lotteryAlphaAdd,
  stateApi,
} from "../../util/lottery";
import { useLocation, useNavigate } from "react-router-dom";
import SliderToggle from "../UI/SliderToggle";
import SingleDataTime from "../SingleDataTime";
import Alphapopup from "../UI/Alphapopup";
import Numericpopup from "../UI/Numericpopup";
import Button from "../UI/Button";
import Toast from "../Toast";
import { toast } from "react-toastify";
import TextDescription from "../UI/TextDescription";
import AlphaAddWinner from "../UI/AlphaAddWinner";
import FileUploadTwo from "../UI/FileUploadTwo";
import { generateToken } from "../../util/tokenGenerate";
import Confirmpop from "../UI/Confirmpop";
import MultiSelectDropDown from "../UI/MultiSelectDropDown";
import TenativeAddWinner from "../UI/TenativeAddWinner";
import { set } from "rsuite/esm/utils/dateUtils";
const ADMINURL = process.env.REACT_APP_ADMINURL;
function LotteryManagementAlpha() {
  const data = useLocation();
  let lotteryEditData = data.state?.id;
  let lotteryPermission = data.state.permission;
  let action = data.state.action;
  let stateCatagory = data.state.catagory;
  var id = localStorage.getItem("userId");

  const [catagory, setCatagory] = useState([]);
  const [stateProvince, setstateProvince] = useState([]);
  const [price, setPrice] = useState([]);
  const [ticketSeriesGet, setTicketSeriesGet] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [totalPosition, setTotalPosition] = useState(null);
  const [totalPrizeValue, setTotalPrizeValue] = useState();
  const [totalwinnings, setTotalWinnings] = useState(null);
  const [cartShow, setCartShow] = useState(false);
  const [cartShowTenative, setCartShowTenative] = useState(false);
  const [hotSale, setHotSale] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState();
  const [initialPrizelist, setInitialPrizelist] = useState([]);
  const [tentativePrizelist, setTentativePrizelist] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [isSaving, setIsSaving] = useState();
  const [boxCount, setBoxCount] = useState([]);
  const [boxLength, setBoxLength] = useState(null);
  const [typeInputs, setTypeInputs] = useState(null);
  const [selectedOption, setSelectedOption] = useState("alphanumeric");
  const [currentIndex, setCurrentIndex] = useState(null);
  const [sessionOne, setSession] = useState();
  const [error, setError] = useState(false);
  const [sequence, setSequence] = useState();
  const [groupIndex, setGroupIndex] = useState([]);
  const [totalSize, setTotalSize] = useState();
  const [notify, setNotify] = useState(false);
  const [selectedLetters, setSelectedLetters] = useState([]);
  const [selectedButtons, setSelectedButtons] = useState([]);
  const [currentBoxIndex, setCurrentBoxIndex] = useState(null);
  const [selectedLettersByBox, setSelectedLettersByBox] = useState({});
  const [selectedNumbersByBox, setSelectedNumbersByBox] = useState({});
  const [rangePop, setRangePop] = useState([]);
  const [optionState, setOptionState] = useState([]);
  const [optionStateName, setOptionStateName] = useState([]);
  const [digitslength, setDigitsLength] = useState([]);
  const [conse, setConse] = useState(false);
  const [show, setShow] = useState(false);
  const [saveShow, setSaveShow] = useState(false);
  const [formData, setFormData] = useState({
    mode: "save",
    name: "",
    combinationmatrics: [],
    lotterycategoryname: "",
    description: "",
    lotterycategoryid: "",
    stateProvince: "",
    state: "",
    totalprize: "",
    cost: "",
    ticketstartdatetime: new Date().toISOString(),
    ticketenddatetime: new Date().toISOString(),
    resultdatetime: new Date().toISOString(),
    ticketscount: "",
    hotsale: false,
    hotsalestartdatetime: new Date().toISOString(),
    hotsaleenddatetime: new Date().toISOString(),
    hotSaleAmount: "",
    money: "₹",
    prizelist: [
      {
        id: Date.now(),
        position: "",
        prize: "",
        digits: "",
        drawcount: "",
        order: "",
        noOfPrizes: "",
        totalPrizeAmount: "",
        prizeName: "",
      },
    ],
    img: "",
    logo: "",
    lotterystatus: "",
    countrycode: "IND",
    lotterymode: "alphanumeric",
    ticketSold: 0,
    lotterytype: "online",
    availableInfo: [],
    winningcombination: {},
    noOfDraw: "",
    signature: "",
    session: "",
    sendNotification: false,
    ticketSeries: [],
  });
  const [editData, setEditData] = useState("");
  const [err, setErr] = useState({
    name: false,
    description: false,
    lotterycategoryid: false,
    session: false,
    state: false,
    cost: false,
    noOfDraw: false,
    logo: false,
    img: false,
    signature: false,
    ticketSeries: false,
    ticketstartdatetime: false,
    ticketenddatetime: false,
    resultdatetime: false,
    hotSale: false,
    hotsalestartdatetime: false,
    hotsaleenddatetime: false,
    totalprize: false,
  });

  const navigate = useNavigate();
  const authToken = localStorage.getItem("token");

  const inputRef = useRef(Array.from({ length: boxCount }, () => null));

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const groupTypeOne = [{ _id: "numeric", type: "Number" }];

  const groupTypeTwo = [
    { _id: "numeric", type: "Number" },
    { _id: "alphanumeric", type: "Alpha" },
  ];

  const sessionDrop = [
    { _id: "mrg", session: "Morning" },
    { _id: "aff", session: "After Noon" },
    { _id: "eve", session: "Evening" },
  ];

  const groupType = [];
  for (let i = 1; i <= boxLength; i++) {
    groupType.push({ _id: `Group ${i}`, groupType: `Group ${i}` });
  }

  const toggleHandler = () => {
    setHotSale(!hotSale);
  };

  const handleToggleChange = (isChecked) => {
    setHotSale(isChecked);
  };

  const handleNotifyChange = (event) => {
    setNotify(event.target.checked);
  };

  const showHandler = () => {
    /* Validate boxCount array for required properties */
    const allTypesHaveValues = boxCount.every(
      (item) => item.hasOwnProperty("type") && item.type !== ""
    );
    const Range = boxCount.every(
      (item) => item.hasOwnProperty("range") && item.range !== ""
    );

    const inputObject = {
      boxLength: boxLength !== null,
      allTypesHaveValues: allTypesHaveValues,
      sequence: sequence !== false,
      Range: Range,
    };

    const errorMessages = {
      boxLength: "Enter Number of Boxes",
      allTypesHaveValues: "All types must have values.",
      sequence: "Group must be in order.",
      Range: "All groups must have range.",
    };

    for (const [key, value] of Object.entries(inputObject)) {
      if (!value) {
        setError(errorMessages[key]);
        setCartShow(false);
        return;
      }
    }

    /* All validations passed */
    setCartShow(true);
    setError("");
  };

  const hideHandler = () => {
    setCartShow(false);
    setTypeInputs(false);
    setCartShowTenative(false);
  };
  const hidepopHandler = () => {
    setSaveShow(false);
    setShow(false);
  };

  function handleFormDataChange(newFormData) {
    setFormData(newFormData);
  }

  /* Number of Box Add */
  const handleIncrement = () => {
    setBoxLength((prevState) => {
      const newLength = prevState + 1;
      if (newLength > 8) {
        return prevState;
      }
      return newLength;
    });

    setBoxCount((prevBoxCount) => {
      const updatedBoxCount = [...prevBoxCount];
      if (updatedBoxCount.length < 8) {
        updatedBoxCount.push({
          initial: "",
          type: "",
          range: "",
          group: `Group ${updatedBoxCount.length + 1}`,
          ranges: "",
        });
      }
      setFormData((prevState) => ({
        ...prevState,
        ticketscount: "",
      }));
      return updatedBoxCount;
    });
  };

  /* Number of Box Delete */
  const handleDecrement = (index) => {
    setBoxLength((prevState) => {
      if (prevState > 1) {
        return prevState - 1;
      }
      return prevState;
    });
    setBoxCount((prevBoxCount) => {
      const updatedBoxCount = [...prevBoxCount];
      if (updatedBoxCount.length > 1) {
        updatedBoxCount.pop();
      }
      setFormData((prevState) => ({
        ...prevState,
        ticketscount: "",
      }));
      return updatedBoxCount;
    });
  };

  /* Number of Box Value Enter */
  const handleInputChange = (event) => {
    let { value } = event.target;
    let inputValue = parseInt(value);

    if (value === "" || isNaN(inputValue) || inputValue <= 0) {
      setBoxCount([]);
      setBoxLength(0);
    } else {
      if (inputValue > 8) {
        inputValue = 8;
        value = "8";
      }

      const updatedBoxCount = [];
      for (let i = 0; i < inputValue; i++) {
        updatedBoxCount.push({
          initial: "",
          type: "",
          range: "",
          group: `Group ${i + 1}`,
          ranges: "",
        });
      }
      setBoxCount(updatedBoxCount);
      setBoxLength(inputValue); /* Ensure boxLength is a number */
    }
    setFormData((prevState) => ({
      ...prevState,
      ticketscount: "",
    }));
  };

  const ticketSeriesGroup = () => {
    // Check if ticketSeries is empty
    if (!formData.ticketSeries || formData.ticketSeries.length === 0) {
      setErr((prevErr) => ({
        ...prevErr,
        ticketSeries: true, // Set error if empty
      }));
    } else {
      setErr((prevErr) => ({
        ...prevErr,
        ticketSeries: false, // Clear error if not empty
      }));
    }
  };

  const validateForm = (formData) => {
    let today = new Date();
    let isValid = true; // Track if the form is valid

    // Check if all dates are the same
    if (
      formData.ticketstartdatetime === formData.ticketenddatetime &&
      formData.ticketenddatetime === formData.resultdatetime
    ) {
      setErr((prevErr) => ({
        ...prevErr,
        ticketstartdatetime: formData.ticketstartdatetime
          ? "Ticket Date and Time cannot be the same"
          : "",
        ticketenddatetime: formData.ticketenddatetime
          ? "Ticket Date and Time cannot be the same"
          : "",
        resultdatetime: formData.resultdatetime
          ? "Ticket Date and Time cannot be the same"
          : "",
      }));
      isValid = false;
    }
    // Check if end date is less than start date
    else if (formData.ticketenddatetime < formData.ticketstartdatetime) {
      setErr((prevErr) => ({
        ...prevErr,
        ticketenddatetime: "Ticket End Date cannot be greater than Start Date",
      }));
      isValid = false;
    }

    // Check if start date is in the past
    else if (formData.ticketstartdatetime < today.toISOString()) {
      setErr((prevErr) => ({
        ...prevErr,
        ticketstartdatetime:
          "Ticket Start Date and Time cannot be less than Current Date and Time",
      }));
      isValid = false;
    }

    // Check if result date is less than end date
    else if (formData.ticketenddatetime > formData.resultdatetime) {
      setErr((prevErr) => ({
        ...prevErr,
        resultdatetime: "Ticket Result Date cannot be less than End Date",
      }));
      isValid = false;
    } else if (formData.prizelist[0].prize) {
      setErr((prevErr) => ({
        ...prevErr,
        totalprize: "Winner field is required.",
      }));
      isValid = false;
    }

    if (formData.lotterycategoryname !== "Recurrence") {
      console.log("formData.lotterycategoryname", formData.lotterycategoryname);
      setErr((prevErr) => ({
        ...prevErr,
        session: false, // Sets session to false when the category is not 'Recurrence'
      }));
      isValid = false;
    }

    // Validation for Hot Sale
    if (hotSale === true) {
      if (!formData.hotsalestartdatetime) {
        setErr((prevErr) => ({
          ...prevErr,
          hotsalestartdatetime: "Please Select Hot Sale Start Date and Time",
        }));
        isValid = false;
      }

      if (!formData.hotsaleenddatetime) {
        setErr((prevErr) => ({
          ...prevErr,
          hotsaleenddatetime: "Please Select Hot Sale End Date and Time",
        }));
        isValid = false;
      }

      if (hotSale === true && formData.hotSaleAmount === "") {
        setErr((prevErr) => ({
          ...prevErr,
          hotSaleAmount: "Please Enter Hot Sale Amount",
        }));
        isValid = false;
      }

      if (formData.hotsaleenddatetime < formData.hotsalestartdatetime) {
        setErr((prevErr) => ({
          ...prevErr,
          hotsaleenddatetime:
            "Hot Sale End Date cannot be less than Start Date",
        }));
        isValid = false;
      }

      if (formData.cost < formData.hotSaleAmount) {
        setErr((prevErr) => ({
          ...prevErr,
          hotSaleAmount: "Hot Sale Amount should be less than Ticket Price",
        }));
        isValid = false;
      }
    }
    setValidationErrors(formData)
    return isValid; // Return whether the form is valid or not
  };

  /* Group Type DropDown Handler */
  const handleDropChange = (index, typeInputs) => {
    setTypeInputs(typeInputs, index);
    setCurrentIndex(index);
    setCurrentBoxIndex(index);
    const updatedBoxCountCopy = [...boxCount];

    updatedBoxCountCopy[index] = {
      ...updatedBoxCountCopy[index],
      type: typeInputs,
    };

    setBoxCount(updatedBoxCountCopy);
  };

  /*  Number and Alpha  pop value on Save 
   Calculating Total tickets */
  const handleSave = (index, selectedValues) => {
    const updatedBoxCountCopy = [...boxCount];
    updatedBoxCountCopy[currentIndex] = {
      ...updatedBoxCountCopy[currentIndex],
      range: selectedValues,
      initial: selectedValues[0],
    };
    setBoxCount(updatedBoxCountCopy);

    const range = updatedBoxCountCopy.map((item) => item.range);
    setFormData((prevFormData) => ({
      ...prevFormData,
      combinationmatrics: range,
    }));
    console.log({ selectedLetters });
    setRangePop(selectedValues);
    console.log(range);

    setSelectedLettersByBox((prevState) => ({
      ...prevState,
      [index]: selectedLetters,
    }));
    setSelectedNumbersByBox((prevState) => ({
      ...prevState,
      [index]: selectedButtons,
    }));

    var already = [];
    var totalTickets = 1;

    // Ensure formData and its properties are defined
    if (
      formData &&
      Array.isArray(range) &&
      Array.isArray(formData.availableInfo)
    ) {
      range.map((matric, index) => {
        var checkinsideavailable = false;

        formData.availableInfo.map(({ digits, range }) => {
          var [start, much] = digits.split("-");
          var [from, to] = range.split("-");

          var indexs = Array.from(
            { length: +much },
            (_, inx) => Number(start) + Number(inx)
          );

          if (indexs.includes(index)) {
            if (
              already.some((al) => {
                return indexs.includes(al);
              })
            ) {
              checkinsideavailable = true;
            } else {
              totalTickets *= +to - Number(from) + 1;
              checkinsideavailable = true;
              already.push(...indexs);
            }
          }
        });

        if (!checkinsideavailable) {
          totalTickets *= matric.length;
        }
      });
      setFormData((prevState) => ({
        ...prevState,
        ticketscount: totalTickets,
      }));
    }

    setCartShow(false);
    setTypeInputs(false);
  };

  const focusLastInput = () => {
    if (inputRef.current.length > 0) {
      inputRef.current[inputRef.current.length - 1].focus();
    }
  };

  /* Submit Function to get the lottery details */
  async function submitFormData(e, lotterystatus, retry = true) {
    e.preventDefault();
    console.log("before", formData);

    // Destructure formData excluding mode and lotteryId
    const { mode, lotteryId, ...filteredWithoutModeAndLotteryId } = formData;

    // Check if there is any difference between editData and formData
    if (
      JSON.stringify(editData) ===
      JSON.stringify(filteredWithoutModeAndLotteryId)
    ) {
      alert("No Difference");
      return;
    }

    var check = true; // Start with true, set to false on error

    // Check for errors in formData fields based on err keys
    Object.keys(err).map((errname) => {
      // Only exclude "session" when the lotterycategoryname is "Recurrence"
      if (
        (formData.lotterycategoryname === "Recurrence" ||
          errname !== "session") &&
        formData.hasOwnProperty(errname) &&
        ((typeof formData[errname] === "string" &&
          formData[errname].trim() === "") ||
          formData[errname] === "0")
      ) {
        setErr((prev) => ({ ...prev, [errname]: true }));
        console.log({ errname });
        check = false; // Set check to false if any error is found
      }
    });

    // Validate formData with ticketSeriesGroup and validateForm
    ticketSeriesGroup();
    console.log({ err });
    validateForm(formData);

    console.log("check", formData, check, err);
    // If check is false, stop form submission
    if (!check) {
      return; // No need for `return false`, just `return` will suffice
    }
    const isValid = validateForm(formData);
    console.log({ isValid });
    console.log("checkOTe", formData, isValid, err);

    console.log("checkONe", formData, check, err);
    try {
      // Proceed with submission if no errors
      const { type, position, groupType, _id, color, ...formDataedit } =
        formData;

      // Fetch request to submit form data
      const response = await fetch(ADMINURL + "/admin/lottery", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include token in headers
        },
        body: JSON.stringify({
          ...formDataedit,
          hotsale: hotSale,
          lotterystatus: lotterystatus,
          lotterymode: selectedOption,
          sendNotification: notify,
        }),
      });

      const responseData = await response.json();

      // If token expired, regenerate token and retry the submission
      if (responseData.sts === "2" && retry) {
        const genToken = await generateToken();
        if (genToken === "Token Generated Successfully") {
          authToken = localStorage.getItem("token");
          // Retry submission without retry flag
          await submitFormData(e, lotterystatus, false);
          return;
        }
      }

      console.log("loteryList", responseData);

      if (!response.ok || responseData.sts === "1") {
        // Handle errors
        const notifyFail = () =>
          toast.error(responseData.msg, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        notifyFail();
        return;
      }

      // On successful submission, notify and navigate
      notifysuccess();
      setTimeout(() => {
        navigate("/Root/LotteryManagement", { state: lotteryPermission });
      }, 3000);

      console.log("Data submitted successfully:", responseData);
    } catch (error) {
      console.error("Error submitting data:", error.message);
    }

    console.log("after", formData);
  }

  /* Getting data from the Lottery Input */
  const dataHandler = (e) => {
    const { name, value } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }
  };

  // function submitFormData(e) {
  //   e.preventDefault();
  //   lotteryAlphaAdd(
  //     formData,
  //     validateForm,
  //     setValidationErrors,
  //     authToken,
  //     totalPrizeValue,
  //     hotSale,
  //     totalwinnings,
  //     toast,
  //     notifysuccess,
  //     navigate,
  //     setFormData,
  //     lotteryPermission,
  //     selectedOption
  //   );
  // }

  /* Getting Data of the Lottery for Edit */
  const fetchEditData = async (index) => {
    try {
      if (lotteryEditData) {
        // Fetch lottery data
        const edit = await getlottery(lotteryEditData, authToken);
        const allowedKeys = Object.keys(formData);

        // Filter the edit object based on allowed keys
        const filterEditData = (edit, allowedKeys) => {
          return Object.keys(edit)
            .filter((key) => allowedKeys.includes(key))
            .reduce((obj, key) => {
              obj[key] = edit[key];
              return obj;
            }, {});
        };

        // Filter the edit object
        const filteredEditData = filterEditData(edit, allowedKeys);

        // Remove specific keys
        const { mode, lotteryId, ...filteredWithoutModeAndLotteryId } =
          filteredEditData;

        // Set the filtered data to both editData and formData
        setEditData(filteredEditData);
        setFormData(filteredWithoutModeAndLotteryId);

        console.log({ edit });
        // Set edit mode to update
        // Getting action from useLocation
        if (action === "copy") {
          edit.mode = "save";
          edit._id = "";
          edit.lotteryId = "";
          edit.winningcombination = {};
          edit.ticketSold = 0;
          edit.seriescombinationmatrics = [];
          // Get today's date
          const today = new Date();
          edit.ticketstartdatetime = today.toISOString();
        } else if (action === "edit") {
          edit.mode = "update";
        }

        // Destructure properties to exclude from form data
        const {
          ticketrevenue,
          prizedistribute,
          createdat,
          __v,
          ticketsoldlist,
          id,
          ...editWithoutUnneeded
        } = edit;

        // Update form data state
        setFormData({ ...editWithoutUnneeded, lotteryId: lotteryEditData });

        // Process and update prizelist, removing noOfDraw, description, name
        const sanitizedPrizelist = edit.prizelist.map((prize) => {
          const { noOfDraw, name, ...rest } = prize;
          return rest;
        });
        setInitialPrizelist(sanitizedPrizelist);
        setTentativePrizelist(edit.tentativePrizelist);

        // Process and update box count
        const updatedBoxCount = edit.combinationmatrics.map((item, i) => {
          const sortedItem = item.slice().sort((a, b) => a.localeCompare(b));

          const initial = sortedItem[0];
          const type = isNaN(initial) ? "Alpha" : "Number";
          if (type === "Alpha") {
            setSelectedLettersByBox((prevState) => ({
              ...prevState,
              [i]: sortedItem,
            }));
          }
          if (type === "Number") {
            setSelectedNumbersByBox((prevState) => ({
              ...prevState,
              [i]: sortedItem,
            }));
          }
          let group = `Group ${i + 1}`;
          let ranges = {};
          let conditionSatisfied = false;

          // Determine the group and ranges based on available info
          edit.availableInfo.forEach((info) => {
            const digits = info.digits.split("-");
            const fromIndex = parseInt(digits[0]);
            const toIndex = parseInt(digits[1]);
            const range = info.range.split("-");
            const fromRanges = parseInt(range[0]);
            const toRanges = parseInt(range[1]);

            const nextGroup = toIndex - 1;
            const nextGroupIndex = fromIndex + nextGroup;

            if (i === fromIndex) {
              group = `Group ${fromIndex + 1}`;
              ranges = { from: fromRanges };

              conditionSatisfied = true;
            } else if (i >= fromIndex && i <= nextGroupIndex) {
              group = `Group ${fromIndex + 1}`;
              ranges = { to: toRanges };

              conditionSatisfied = true;
            } else if (!conditionSatisfied) {
              group = `Group ${i + 1}`;
            }

            if (conditionSatisfied) {
              return;
            }
          });

          return {
            initial,
            type,
            range: sortedItem,
            group,
            ranges,
          };
        });

        // Update state with the processed data
        setBoxCount(updatedBoxCount);

        // Set other states
        setBoxLength(edit.combinationmatrics.length);
        setTotalPrizeValue(edit.totalprize);
        setHotSale(edit.hotsale);
        setNotify(edit.sendNotification);
        if (stateCatagory === "Recurrence") {
          setSession(true);
        }
      }
    } catch (error) {
      console.error("Failed to fetch or process edit data:", error);
      // Handle error (e.g., show notification to user)
    }
  };

  const fetchData = async () => {
    if (!authToken) {
      navigate("/");
    } else {
      try {
        const options = await catagoryApi(authToken, id);
        const stateoption = await stateApi(authToken, id);
        const priceoption = await PriceApi(authToken, id);
        const ticketSeries = await SeriesApi(authToken, id);
        setCatagory(options);
        setstateProvince(stateoption);
        setPrice(priceoption.sort((a, b) => a.cost - b.cost));
        setTicketSeriesGet(ticketSeries.sort((a, b) => a.series - b.series));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData();
    focusLastInput();
    fetchEditData(authToken);
  }, [authToken]);

  /* If Category is Recurrence */
  function catagoryHandler(id, lotterycategoryname) {
    if (lotterycategoryname === "Recurrence") {
      setSession(true);
    } else {
      setSession(false);
      setFormData((prevFormData) => ({
        ...prevFormData,
        session: "",
      }));
    }
  }

  /* Popup on Clicking Yes Button when Publish Button is clicked */
  async function yesHandler(e) {
    setIsSubmitting(true);
    await submitFormData(e, "Pending");

    setIsSubmitting(false);
    setShow(false);
  }

  /* Popup on Clicking Yes Button when Save Button is clicked */
  async function saveYesHandler(e) {
    setIsSaving(true);
    await submitFormData(e, "Saved");

    setIsSaving(false);
    setSaveShow(false);
  }

  /* Handler on Clicking No Button when Publish Button is clicked */
  const noHandler = () => {
    setShow(false);
  };

  /* Handler on Clicking No Button when Save Button is clicked */
  const saveNoHandler = () => {
    setSaveShow(false);
  };

  /* Handler on Clicking  Publish Button is clicked */
  function handleSubmit() {
    setShow(true);
  }

  /* Handler on Clicking  Save Button is clicked */
  function handleSaveNum(e) {
    setSaveShow(true);
  }

  /* Handler Function on Saving the Winner List */
  const handleSaveWinner = (prizeListData, name, value) => {
    console.log(prizeListData, "jfshf");
    const totalPrize = prizeListData.reduce((total, prizeItem) => {
      return total + prizeItem.totalPrizeAmount;
    }, 0);

    let totalposition = 0;

    prizeListData.forEach((item) => {
      const newPosition = item.noOfPrizes;
      totalposition += newPosition;
    });

    setFormData((prevState) => ({
      ...prevState,
      prizelist: prizeListData, // Update with the modified prizeListData
      totalprize: totalPrize.toString(),
    }));

    setTotalPrizeValue(totalPrize.toString());
    setTotalPosition(totalposition);
    setInitialPrizelist(prizeListData); // Update with the modified prizeListData
    console.log(prizeListData);
    setCartShow(false);
    if (prizeListData.length > 0) {
      {
        setErr &&
          setErr((prev) => ({
            ...prev,
            winner: false,
          }));
      }
    }
  };

  /* To Get the Number or Alphabet to be shown in combinationmatrix */
  function changeLocakedCombination() {
    var changeCombination = [];

    formData.availableInfo.forEach((avail, availIndex) => {
      var [start, much] = avail.digits.split("-");
      var [from, to] = avail.range.split("-");
      var indexs = Array.from(
        { length: +much },
        (_, inx) => Number(start) + Number(inx)
      );

      for (var c = 0; c < indexs.length; c++) {
        var matrics = [];
        for (
          var d = +from.substring(0, c + 1);
          d <= +to.substring(0, c + 1);
          d++
        ) {
          if (matrics.length > 10) {
            break;
          }

          if (!matrics.includes(d.toFixed(0).slice(-1))) {
            matrics.push(d.toFixed(0).slice(-1));
          }
        }

        changeCombination[indexs[c]] = matrics.sort();
        console.log(changeCombination[indexs[c]]);
      }
    });
    return changeCombination;
  }

  /* To updated the values in combinationmatrix */
  function updateCombinationMatrics() {
    var changeCombination = changeLocakedCombination();

    /* Loop through the changeCombination array and update formData.combinationmatrics */
    changeCombination.forEach((combo, index) => {
      if (combo) {
        formData.combinationmatrics.splice(index, 1, combo);
      }
    });

    /* Return the updated combination matrix */
    return formData.combinationmatrics;
  }

  /* Part of the handleRangeChange function */
  const updateRangeAndDigits = (
    newBoxCount,
    formData,
    setTotalCount,
    setFormData
  ) => {
    newBoxCount.forEach((box, index) => {
      const group = box.group;

      const groupBoxes = newBoxCount.filter((item) => item.group === group);
      const from = groupBoxes[0].ranges.from;
      const to = groupBoxes[groupBoxes.length - 1].ranges.to;

      if (from && to && from !== "undefined" && to !== "undefined") {
        const updatedRange = from + "-" + to;
        const startingIndex = newBoxCount.findIndex(
          (box) => box.group === group
        );

        const groupCount = groupBoxes.length;
        const updatedDigits = startingIndex + "-" + groupCount;
        let totalTicketCount = to - from + 1;
        setTotalCount(totalTicketCount);
        const endIndex = startingIndex + groupCount - 1;

        if (groupCount > 1) {
          const existingEntryIndex = formData.availableInfo.findIndex(
            (entry) => entry && entry.digits === updatedDigits
          );

          const isOnlyBoxInGroup =
            newBoxCount.filter((item) => item.group === group).length === 1;

          if (existingEntryIndex !== -1 && !isOnlyBoxInGroup) {
            formData.availableInfo[existingEntryIndex].range = updatedRange;
            formData.availableInfo[existingEntryIndex].digits = updatedDigits;
            const updatedCombination = updateCombinationMatrics();

            if (updatedCombination) {
              setFormData((prevFormData) => {
                const newCombinationMatrics = [
                  ...prevFormData.combinationmatrics,
                ];
                const combinationSegment = updatedCombination.slice(
                  parseInt(startingIndex),
                  parseInt(endIndex) + 1
                );

                newCombinationMatrics.splice(
                  parseInt(startingIndex),
                  groupCount,
                  ...combinationSegment
                );

                return {
                  ...prevFormData,
                  combinationmatrics: newCombinationMatrics,
                };
              });
            }

            newBoxCount[startingIndex].range = Array.from(
              { length: to - from + 1 },
              (_, i) => (parseInt(from) + i).toString()
            );

            for (let i = startingIndex + 1; i <= endIndex; i++) {
              newBoxCount[i].range = [
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
              ];
            }
          } else {
            const existingDigitIndex = formData.availableInfo.findIndex(
              (entry) =>
                entry &&
                entry.digits.split("-")[0] === updatedDigits.split("-")[0]
            );

            if (existingDigitIndex !== -1) {
              formData.availableInfo[existingDigitIndex].range = updatedRange;
              formData.availableInfo[existingDigitIndex].digits = updatedDigits;
            } else {
              formData.availableInfo[index] = {
                range: updatedRange,
                digits: updatedDigits,
              };
            }
          }

          const filteredAvailableInfo = formData.availableInfo.filter(
            (entry) => entry !== null && entry !== undefined
          );

          setFormData((prevFormData) => ({
            ...prevFormData,
            availableInfo: filteredAvailableInfo,
          }));

          let already = [];
          let totalTickets = 1;

          if (formData && Array.isArray(formData.combinationmatrics)) {
            formData.combinationmatrics.forEach((matric, idx) => {
              let checkinsideavailable = false;
              formData.availableInfo.forEach(({ digits, range }) => {
                const [start, much] = digits.split("-");
                const [fromRange, toRange] = range.split("-");

                const indices = Array.from(
                  { length: +much },
                  (_, i) => Number(start) + Number(i)
                );

                if (indices.includes(idx)) {
                  if (
                    already.some((al) => {
                      return indices.includes(al);
                    })
                  ) {
                    checkinsideavailable = true;
                  } else {
                    totalTickets *= +toRange - Number(fromRange) + 1;
                    checkinsideavailable = true;
                    already.push(...indices);
                  }
                }
              });

              if (!checkinsideavailable) {
                totalTickets *= matric.length;
              }
            });
          }

          setFormData((prevState) => ({
            ...prevState,
            ticketscount: totalTickets,
          }));

          if (to < from) {
            setError("To value cannot be larger than From value");
            return;
          } else {
            setError("");
          }
        }
      }
    });
  };

  /* Function to Handle upon Entering the From and To Range of the ticket number */
  const handleRangeChange = (index, type, value) => {
    const newBoxCount = [...boxCount];
    if (
      typeof newBoxCount[index].ranges !== "object" ||
      newBoxCount[index].ranges === null
    ) {
      newBoxCount[index].ranges = {};
    }
    newBoxCount[index].ranges[type] = value;

    if (action === "copy") {
      const range = newBoxCount.map((item) => item.range);
      setFormData((prevFormData) => ({
        ...prevFormData,
        combinationmatrics: range,
      }));
    }
    setBoxCount(newBoxCount);

    updateRangeAndDigits(newBoxCount, formData, setTotalCount, setFormData);
  };

  /* To enter only number in From  and To Range Input */
  const handleKeyPress = (event) => {
    const charCode = event.charCode;
    // Allow only numbers (0-9)
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };

  /* Group Change Handler */
  const handleGroupChange = (index, group) => {
    const newBoxCount = [...boxCount];
    console.log({ newBoxCount });
    // const groupList = newBoxCount.map(item => item.group);
    // const groupListLengths = groupList.map(group => groupList.filter(item => item === group).length);

    newBoxCount[index].group = group;
    const groupIndexes = newBoxCount
      .map((item, idx) => (item.group === group ? idx : -1))
      .filter((idx) => idx !== -1);
    console.log({ groupIndexes });

    // setDigitsLength(groupListLengths[index]);
    newBoxCount[index].range = "";

    const isSequential = groupIndexes.every(
      (val, i, arr) => i === 0 || arr[i - 1] === val - 1
    );
    setSequence(isSequential);
    console.log({ isSequential });

    if (groupIndexes.length > 1) {
      setGroupIndex(groupIndexes);
      groupIndexes.forEach((groupIndex) => {
        newBoxCount[groupIndex] = {
          ...newBoxCount[groupIndex],
          type: "numeric",
          initial: "0",
        };
      });

      setGroupIndex((prevState) => [...prevState, ...groupIndexes]);
    } else {
      setGroupIndex([]);
    }

    // Reset the ranges object for the current box
    newBoxCount[index].ranges = "";
    newBoxCount[index].range = "";

    setFormData((prevFormData) => ({ ...prevFormData, availableInfo: [] }));
    setBoxCount(newBoxCount);
    console.log({ newBoxCount });
  };

  /* To Get and Update Ticket Series */
  const ticketBulk = (value) => {
    setFormData({
      ...formData,
      ticketSeries: value,
    });
    setErr((prevErr) => ({
      ...prevErr,
      ticketSeries: false, // Clear error if not empty
    }));
  };
  const title = lotteryEditData ? "Edit" : "Create";
  return (
    <Fragment>
      <div className={classes.AddLotteryWrapper}>
        <h4>{title} Lottery</h4>
        <div className={classes.AddLotteryContainer}>
          <div className={classes.AddLotteryFlex}>
            <div className={classes.dropDownWidth}>
              <div className={classes.AddLotteryImgFlex}>
                <div className={classes.AddLotteryImgFlexFirstDiv}>
                  <div className={classes.AddLotterydropDownFlex}>
                    <div className={classes.dropDownWidth}>
                      <CustomInput
                        label="Lottery Name"
                        type="text"
                        name="name"
                        placeholder="Lottery Name"
                        customInput={
                          err.name ? classes.errorInput : classes.inputBorder
                        }
                        value={formData.name}
                        onChange={dataHandler}
                        err={err.name}
                        disabled={action === "view"}
                        asterisk={true}
                        maxLength={35}
                      />
                    </div>
                  </div>
                  <div className={classes.AddLotterydropDownFlex}>
                    <div className={classes.AddLotteryWidth}>
                      <FileUploadTwo
                        label="Lottery Logo"
                        name="logo"
                        value={formData.logo}
                        dataHandler={dataHandler}
                        setFormData={setFormData}
                        logourl={formData.logourl}
                        err={err.logo}
                        disabled={action === "view"}
                        imageType="logo"
                        asterisk={true}
                        setErr={setErr}
                      />
                    </div>
                    <div className={classes.AddLotteryWidth}>
                      <FileUploadTwo
                        label="State Logo"
                        name="img"
                        value={formData.img}
                        dataHandler={dataHandler}
                        setFormData={setFormData}
                        imgurl={formData.imgurl}
                        err={err.img}
                        disabled={action === "view"}
                        imageType="profile"
                        asterisk={true}
                        setErr={setErr}
                      />
                    </div>
                    <div className={classes.AddLotteryWidth}>
                      <FileUploadTwo
                        label="Signature"
                        asterisk={true}
                        name="signature"
                        value={formData.signature}
                        signurl={formData.signurl}
                        err={err.signature}
                        dataHandler={dataHandler}
                        setFormData={setFormData}
                        disabled={action === "view"}
                        imageType="signature"
                        totalSize={totalSize}
                        setTotalSize={setTotalSize}
                        setErr={setErr}
                      />
                    </div>
                  </div>
                  <div className={classes.AddLotterydropDownFlex}>
                    <DropDown
                      label="Category"
                      placeholder="Select Category"
                      name={{
                        name: "lotterycategoryname",
                        id: "lotterycategoryid",
                      }}
                      options={catagory}
                      value={formData.lotterycategoryid}
                      onChange={(id, lotterycategoryname) =>
                        catagoryHandler(id, lotterycategoryname)
                      }
                      setFormData={setFormData}
                      field="categoryname"
                      validation={
                        err.lotterycategoryid ? classes.errorInput : ""
                      }
                      err={err.lotterycategoryid}
                      disabled={action === "view"}
                      asterisk={true}
                      setErr={setErr}
                    />
                    <DropDown
                      label="State/Province"
                      placeholder="State/Province"
                      options={stateProvince}
                      name={{ name: "stateProvince", id: "state" }}
                      value={formData.state}
                      dataHandler={dataHandler}
                      setFormData={setFormData}
                      field="statename"
                      validation={err.state ? classes.errorInput : ""}
                      err={err.state}
                      disabled={action === "view"}
                      asterisk={true}
                      setErr={setErr}
                    />

                    <div className={classes.AddLotteryWidth}>
                      <MultiSelectDropDown
                        label="Ticket Series"
                        placeholder="Select Series"
                        options={ticketSeriesGet}
                        name={{ name: "ticketSeries", id: "id" }}
                        value={formData.ticketSeries}
                        onChange={(selectedValue, value) => ticketBulk(value)}
                        setFormData={setFormData}
                        field="series"
                        customInput={err.ticketSeries ? classes.errorInput : ""}
                        err={err.ticketSeries}
                        asterisk={true}
                        disabled={action === "view"}
                        setErr={setErr}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.AddLotteryImgFlex}>
                <div className={classes.AddLotteryImgFlexSecondDiv}>
                  <TextDescription
                    lable="Description"
                    name="description"
                    value={formData.description}
                    onChange={dataHandler}
                    customInput={
                      err.description ? classes.errorInput : classes.inputBorder
                    }
                    err={err.description}
                    disabled={action === "view"}
                    asterisk={true}
                    maxLength="10500"
                    placeholder="Description"
                  />
                </div>
              </div>

              <div className={`${classes.AddLotterydropDownFlex}`}>
                <div className={classes.dropDownWidth}>
                  <CustomInput
                    label="Total Number of Tickets"
                    type="number"
                    placeholder="00"
                    value={formData.ticketscount}
                    name="ticketscount"
                    onChange={dataHandler}
                    totalPosition={totalPosition}
                    disabled
                    comma={true}
                  />
                </div>
                <div className={classes.dropDownWidth}>
                  <DropDown
                    label="Ticket Price"
                    placeholder="00.00"
                    options={price}
                    name={{
                      name: "cost",
                      id: "color",
                    }}
                    value={formData.cost}
                    dataHandler={dataHandler}
                    setFormData={setFormData}
                    field="cost"
                    validation={err.cost ? classes.errorInput : ""}
                    err={err.cost}
                    disabled={action === "view"}
                    asterisk={true}
                    setErr={setErr}
                  />
                </div>
                <div className={classes.AddLotteryWidth}>
                  <div className={classes.customInputFlex}>
                    <label>Number of box</label>
                    <span>*</span>
                  </div>
                  <div
                    className={`${classes.common} ${classes.AddLotteryBoxFlex}`}
                  >
                    <div className={classes.boxButton}>
                      {" "}
                      <button
                        type="button"
                        onClick={handleDecrement}
                        disabled={action === "view"}
                      >
                        -
                      </button>
                    </div>
                    <div className={classes.inputLength}>
                      <input
                        name="boxNumber"
                        type="number"
                        placeholder="0"
                        value={boxLength}
                        min={1}
                        max={8}
                        onChange={handleInputChange}
                        disabled={action === "view"}
                      />
                    </div>

                    <div className={classes.boxButton}>
                      <button
                        type="button"
                        onClick={handleIncrement}
                        disabled={action === "view"}
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Box Mapping Group */}
              <div className={classes.AlphaNumeericSequence}>
                {Array.from({ length: boxLength }, (_, index) => (
                  <div key={index} className={classes.BoxCount}>
                    <div className={classes.ticketBoxWrap}>
                      <div className={classes.ticketBox}>
                        {boxCount[index]?.initial}
                      </div>
                    </div>
                    <div key={index} className={classes.dropContainer}>
                      <DropDown
                        label="Group"
                        placeholder="Select"
                        value={boxCount[index].group}
                        options={groupType}
                        onChange={(group) => handleGroupChange(index, group)}
                        setFormData={setFormData}
                        name="groupType"
                        field="groupType"
                        disabled={action === "view"}
                      />
                      <DropDown
                        label="Type"
                        placeholder="Select"
                        onChange={(type) => handleDropChange(index, type)}
                        options={
                          groupIndex && groupIndex.includes(index)
                            ? groupTypeOne
                            : groupTypeTwo
                        }
                        value={boxCount[index].type}
                        setFormData={setFormData}
                        field="type"
                        name="type"
                        disabled={action === "view"}
                      />

                      {boxCount.filter(
                        (item, idx) => item.group === boxCount[index].group
                      ).length === 1 ? (
                        <>
                          <div
                            className={classes.rangeButton}
                            name="combinationmatrics"
                            value={formData.combinationmatrics}
                          >
                            {boxCount[index].range
                              ? boxCount[index].range
                              : "Range"}
                          </div>
                        </>
                      ) : (
                        <>
                          {boxCount.filter(
                            (item, idx) =>
                              item.group === boxCount[index].group &&
                              idx < index
                          ).length === 0 && (
                            <input
                              className={
                                error ? classes.rangeError : classes.rangeButton
                              }
                              type="text"
                              value={boxCount[index].ranges.from}
                              placeholder="From Range"
                              disabled={action === "view"}
                              onChange={(e) =>
                                handleRangeChange(index, "from", e.target.value)
                              }
                              maxLength={digitslength}
                              onKeyPress={handleKeyPress}
                            />
                          )}

                          {boxCount.filter(
                            (item, idx) =>
                              item.group === boxCount[index].group &&
                              idx === index + 1
                          ).length === 0 && (
                            <input
                              className={
                                error ? classes.rangeError : classes.rangeButton
                              }
                              type="text"
                              value={boxCount[index].ranges.to}
                              placeholder="To Range"
                              disabled={action === "view"}
                              maxLength={digitslength}
                              onKeyPress={handleKeyPress}
                              onChange={(e) =>
                                handleRangeChange(index, "to", e.target.value)
                              }
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {typeInputs === "numeric" && (
                <Numericpopup
                  Hide={hideHandler}
                  modalComponent={classes.ModalWidth}
                  onSave={handleSave}
                  selectedButtons={selectedNumbersByBox[currentBoxIndex] || []}
                  setSelectedButtons={(buttonValue) =>
                    setSelectedNumbersByBox((prevState) => ({
                      ...prevState,
                      [currentBoxIndex]: buttonValue,
                    }))
                  }
                />
              )}

              {typeInputs === "alphanumeric" && (
                <Alphapopup
                  Hide={hideHandler}
                  modalComponent={classes.ModalWidth}
                  onSave={handleSave}
                  selectedLetters={selectedLettersByBox[currentBoxIndex] || []}
                  setSelectedLetters={(letters) =>
                    setSelectedLettersByBox((prevState) => ({
                      ...prevState,
                      [currentBoxIndex]: letters,
                    }))
                  }
                />
              )}

              {show && (
                <Confirmpop
                  Hide={hidepopHandler}
                  yesHandler={yesHandler}
                  noHandler={noHandler}
                  title={"Are you sure you want to"}
                  text={"Publish"}
                  disabled={isSaving || isSubmitting}
                />
              )}

              {saveShow && (
                <Confirmpop
                  Hide={hidepopHandler}
                  yesHandler={saveYesHandler}
                  noHandler={saveNoHandler}
                  title={"Are you sure you want to"}
                  text={"Save"}
                  disabled={isSaving || isSubmitting}
                />
              )}

              <div
                className={`${classes.AddLotterydropDownFlex} ${classes.AddLotteryImgFlexThreeDiv}`}
              >
                <div className={classes.dropDownWidth}>
                  <CustomInput
                    label="Total Prize Amount"
                    placeholder="00.00"
                    type="number"
                    name="totalprize"
                    dataHandler={dataHandler}
                    value={formData.totalprize}
                    setFormData={setFormData}
                    disabled
                    comma={true}
                  />
                </div>

                <CustomInput
                  label="Draw Number"
                  type="number"
                  placeholder="Draw Number"
                  value={formData.noOfDraw}
                  name="noOfDraw"
                  onChange={dataHandler}
                  customInput={
                    err.noOfDraw ? classes.errorInput : classes.inputBorder
                  }
                  err={err.noOfDraw}
                  disabled={action === "view"}
                  asterisk={true}
                  onKeyPress={handleKeyPress}
                />

                {sessionOne && (
                  <DropDown
                    label="Session"
                    placeholder="Session"
                    options={sessionDrop}
                    name={{ name: "session", id: "id" }}
                    value={formData.session}
                    dataHandler={dataHandler}
                    setFormData={setFormData}
                    field="session"
                    err={err.session}
                    validation={err.session ? classes.errorInput : ""}
                    asterisk={true}
                    disabled={action === "view"}
                    setErr={setErr}
                  />
                )}
              </div>

              <div className={classes.dropDownWidth}>
                <div
                  className={`${classes.winnerPrize} ${classes.AddLotteryWidth}`}
                >
                  <div className={classes.customInputFlex}>
                    <label>Winner Prize</label>
                    <span>*</span>
                  </div>
                  <div
                    className={`${classes.common} ${
                      err.totalprize ? classes.errorInput : ""
                    }`}
                    onClick={showHandler}
                    setFormData={handleFormDataChange}
                  >
                    {formData.prizelist.map((winner, index) => (
                      <div key={index} className={classes.AddLotterydropFlex}>
                        <p>
                          {winner.prizeName}-{winner.noOfPrizes}
                        </p>
                        <p> {winner.prize}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {err.totalprize ? (
                <span className={classes.errorMsg}>
                  Winner Prize is Requried
                </span>
              ) : (
                ""
              )}
              {error && <span className={classes.errorMsg}>{error}</span>}
              {/* <div className={classes.dropDownWidth}>
                <div
                  className={`${classes.winnerPrize} ${classes.AddLotteryWidth}`}
                >
                  <div className={classes.customInputFlex}>
                    <label>Tenative Prize Payout</label>
                    <span>*</span>
                  </div>
                  <div
                    className={`${classes.common} ${
                      validationErrors.winner ? classes.errorInput : ""
                    }`}
                    onClick={showHandlerTenative}
                    setFormData={handleFormDataChange}
                  >
                   Tenative Prize Payout
                  </div>

                  {error && <span className={classes.errorMsg}>{error}</span>}
                </div>
              </div> */}

              {/* Winner Ticket Popup */}
              {cartShow && (
                <AlphaAddWinner
                  Hide={hideHandler}
                  formData={formData}
                  setFormData={setFormData}
                  dataHandler={dataHandler}
                  boxLength={boxLength}
                  boxCount={boxCount}
                  onSaveWinner={handleSaveWinner}
                  setConse={setConse}
                  conse={conse}
                  initialPrizelist={initialPrizelist}
                  disabled={action === "view"}
                />
              )}

              {/* Tenative Popup
              {cartShowTenative && (
                <TenativeAddWinner
                  Hide={hideHandler}
                  formData={formData}
                  setFormData={setFormData}
                  dataHandler={dataHandler}
                  disabled={action === "view"}
                  tentativePrizelist={tentativePrizelist}
                  onSaveTenative={handleSaveTenative}
                />
              )} */}

              <div
                className={`${classes.AddLotterydropDownFlex} ${classes.AddLotteryImgFlexThreeDiv}`}
              >
                <div
                  className={`${classes.dropDownWidth} ${classes.AddLotteryWidth}`}
                >
                  <label>Ticket Start Date & Time</label>

                  <SingleDataTime
                    name="ticketstartdatetime"
                    value={formData.ticketstartdatetime}
                    dateHandler={dataHandler}
                    setFormData={setFormData}
                    validation={
                      err.ticketstartdatetime ? classes.errorInput : ""
                    }
                    err={err.ticketstartdatetime}
                    disabled={action === "view"}
                    setErr={setErr}
                  />
                </div>
                <div
                  className={`${classes.dropDownWidth} ${classes.AddLotteryWidth}`}
                >
                  <label>Ticket End Date & Time</label>
                  <SingleDataTime
                    name="ticketenddatetime"
                    // placeholder="DD MM YYYY HH:MM"
                    value={formData.ticketenddatetime}
                    dateHandler={dataHandler}
                    setFormData={setFormData}
                    validation={err.ticketenddatetime ? classes.errorInput : ""}
                    err={err.ticketenddatetime}
                    disabled={action === "view"}
                    setErr={setErr}
                  />
                </div>

                <div
                  className={`${classes.dropDownWidth} ${classes.AddLotteryWidth}`}
                >
                  <label>Result Date and Time</label>
                  <SingleDataTime
                    name="resultdatetime"
                    value={formData.resultdatetime}
                    dateHandler={dataHandler}
                    setFormData={setFormData}
                    validation={err.resultdatetime ? classes.errorInput : ""}
                    err={err.resultdatetime}
                    disabled={action === "view"}
                    setErr={setErr}
                  />
                </div>
              </div>

              <Toast />

              <div className={classes.ToggleWrap}>
                <p>Hot Sales</p>

                <SliderToggle
                  onClick={toggleHandler}
                  name="hotsale"
                  checked={hotSale}
                  onChange={handleToggleChange}
                  value={formData.hotsale}
                  disabled={action === "view"}
                />

                <div className={classes.checkedBox}>
                  <input
                    type="checkbox"
                    name="sendNotification"
                    onChange={handleNotifyChange}
                    value={formData.sendNotification}
                    checked={notify}
                    disabled={action === "view"}
                  />
                  <p>Send Notification</p>
                </div>
              </div>

              {hotSale && (
                <div
                  className={`${classes.AddLotterydropDownFlex} ${classes.AddLotteryImgFlexThreeDiv}`}
                >
                  <div
                    className={`${classes.dropDownWidth} ${classes.AddLotteryWidth}`}
                  >
                    <label>Tickets Start Date & Time</label>
                    <SingleDataTime
                      name="hotsalestartdatetime"
                      value={formData.hotsalestartdatetime}
                      dateHandler={dataHandler}
                      setFormData={setFormData}
                      validation={
                        validationErrors.hotsalestartdatetime
                          ? classes.errorInput
                          : ""
                      }
                      disabled={action === "view"}
                    />
                    {validationErrors.hotsalestartdatetime && (
                      <span className={classes.errorMsg}>
                        {validationErrors.hotsalestartdatetime}
                      </span>
                    )}
                  </div>

                  <div
                    className={`${classes.dropDownWidth} ${classes.AddLotteryWidth}`}
                  >
                    <label>Tickets End Date & Time</label>
                    <SingleDataTime
                      name="hotsaleenddatetime"
                      value={formData.hotsaleenddatetime}
                      dateHandler={dataHandler}
                      setFormData={setFormData}
                      validation={
                        validationErrors.hotsaleenddatetime
                          ? classes.errorInput
                          : ""
                      }
                      disabled={action === "view"}
                    />
                    {validationErrors.hotsaleenddatetime && (
                      <span className={classes.errorMsg}>
                        {validationErrors.hotsaleenddatetime}
                      </span>
                    )}
                  </div>

                  <div className={classes.AddLotteryWidth}>
                    <CustomInput
                      label="Hot Sale Amount"
                      type="number"
                      placeholder="00"
                      value={formData.hotSaleAmount}
                      name="hotSaleAmount"
                      onChange={dataHandler}
                      customInput={
                        err.hotSaleAmount
                          ? classes.errorInput
                          : classes.inputBorder
                      }
                      err={err.hotSaleAmount}
                      disabled={action === "view"}
                      onKeyPress={handleKeyPress}
                    />
                  </div>
                </div>
              )}
              {action !== "view" && (
                <div className={classes.AddLotteryButtonFlex}>
                  <div className={classes.AddLotteryButton}>
                    <Button
                      type="button"
                      customButton={classes.buttonOutline}
                      customButtonWidth={classes.buttonWidth}
                      onClick={handleSaveNum}
                      //disabled={isSaving || isSubmitting}
                    >
                      {isSaving ? "Saving..." : "Save"}
                    </Button>
                    <Button
                      type="button"
                      customButtonWidth={classes.buttonWidth}
                      // disabled={isSaving || isSubmitting}
                      onClick={handleSubmit}
                    >
                      {isSubmitting ? "Publishing..." : "Publish"}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default LotteryManagementAlpha;
