/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";

const ADMINURL = process.env.REACT_APP_ADMINURL

// Offer Types
export const offerApi = async (authToken, retry=true) => {
  try {
    const response = await fetch(ADMINURL + "/admin/offertypes", {
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const data = await response.json();

    if (data.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await offerApi(authToken ,false);
        return;
      }
    }

    console.log(data.data);
    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};

export const fetchPromotionData = async (
  setPromotionDataGet,
  authToken,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/offers", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchPromotionData(setPromotionDataGet,
          authToken ,false);
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    var i = 1;
    console.log("Customer", responseData.data);

    const promotionDataGet = responseData.data.map((item, index) => {
      const startDatetime = new Date(item.start);
      const endDatetime = new Date(item.end);

      const startdateOptions = {
        timeZone: "UTC",
        day: "2-digit",
        month: "numeric",
        year: "numeric",
      };

      const enddateOptions = {
        timeZone: "UTC",
        day: "2-digit",
        month: "numeric",
        year: "numeric",
      };

      const formattedEndDate = endDatetime.toLocaleDateString(
        "en-GB",
        enddateOptions
      );

      return {
        uniqueId: `row_${index}`,
        id: item._id,
        code: item.code,
        discount: item.discount,
        termsandconditions: item.termsandconditions,
        name:item.name,
        description: item.description,
        start:startDatetime,
        end:endDatetime,
        //start: `${formattedStartDate}`,
        endOffer: `${formattedEndDate}`,
        type: item.type._id,
        label: item.type.label,
        targetuser: item.targetuser,
        nooftickets: item.nooftickets,
        noofdays: item.noofdays,
        valuetype:item.valuetype
      };
    });

    setPromotionDataGet(promotionDataGet);
    console.log("promotionDataGet", promotionDataGet);
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const createOfferData = async function OfferHandler(
  setPromotionData,
  authToken,
  id,
  notifysuccess,
  Hide,
  toast,
  promotionData,
  err,
  setErr,
  retry =true
) {
  console.log("promotion Data", promotionData);

  var check = 1;
  Object.keys(err).map((errname) => {
    if (
      typeof promotionData[errname] !== "undefined" &&
      typeof promotionData[errname].trim === "function" &&
      (promotionData[errname].trim() === "" || promotionData[errname] === "0")
    ) {
      check = 0;
      setErr((prev) => ({ ...prev, [errname]: true }));
      console.log(errname, "err");
      console.log({err})
    }
  });
  if (!check) {
    return false;
  }
  setPromotionData((prevFormData) => ({
    ...prevFormData,
  }));

  try {
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };
    // Log the mode value
    const response = await fetch(ADMINURL + "/admin/save-offer", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ ...promotionData, ...postdata }),
    });
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await createOfferData(setPromotionData,
          authToken,
          id,
          notifysuccess,
          Hide,
          toast,
          promotionData,
          err,
          setErr,false);
        return;
      }
    }

    console.log("loteryList", responseData);

    const notifyfail = () =>
      toast.error(responseData.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    if (responseData.sts === "1") {
      notifyfail();
      return;
    } else if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    const notifyfail = () =>
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    notifyfail();
    console.error("Error submitting data:", error.message);
  } 
};

export const promoDelete = async function handleDeleteClick(
  id,
  authToken,
  notifysuccess,
  notifyfail
) {
  console.log(id);
  const isConfirmed = window.confirm("Do You Want to Delete this Promotion?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/save-offer", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: id, mode: "delete" }),
      });
      console.log("response categery", response);

      if (response.status === 200) {
        notifysuccess();
        window.location.reload();
      }
      if (
        response.status === 413 ||
        response.status === 500 ||
        response.status === 404
      ) {
        notifyfail();
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  }
};