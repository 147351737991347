/* 
Author: Sreenivassan R
Module: Create How to Play
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import classes from "../CreateModal.module.css";
import { useNavigate } from "react-router-dom";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { createHowToPlayData } from "../../util/howtoplay";
import TextDescription from "../UI/TextDescription";

function HowToPlayCreateModal({
  Hide,
  updateHowToPlayDataGet,
  id,
  notifysuccess,
  notifyfail,
  setRender,
}) {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [validationErrors, setValidationErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [howToPlayData, sethowToPlayData] = useState({
    innerHTML: "",
    heading: "",
  });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      if (id) {
        console.log(id);
        sethowToPlayData(updateHowToPlayDataGet);
        console.log(updateHowToPlayDataGet);
      }
    }
  }, [updateHowToPlayDataGet]);

  /* Getting data from the How to Play Input */
  const dataHandler = (e) => {
    const { name, value } = e.target;

    sethowToPlayData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  function howtoplayHandler() {
    createHowToPlayData(
      authToken,
      sethowToPlayData,
      setIsSubmitting,
      id,
      howToPlayData,
      validateForm,
      setValidationErrors,
      toast,
      notifysuccess,
      Hide,
      setRender
    );
  }

  const validateForm = (howToPlayData) => {
    const errors = {};

    errors.heading = !howToPlayData.heading ? "Please enter Banner Name" : "";
    errors.innerHTML = !howToPlayData.innerHTML
      ? "Please enter a Description"
      : "";

    return errors;
  };

  /* Getting Description Input */
  const handleContentChange = (event) => {
    /* Replace all "/" characters with "\/" */
    const updatedContent = event.target.value;

    /* Update the state with the modified content */
    sethowToPlayData((prevState) => ({
      ...prevState,
      innerHTML: updatedContent,
    }));
  };

  const title = id ? "Edit" : "Create";

  return (
    <ModalComponent Hide={Hide}>
      <h4 className={classes.h4Editor}>{title} How to play</h4>
      <CustomInput
        label="Heading"
        type="text"
        placeholder="Heading"
        name="heading"
        value={howToPlayData.heading}
        customInput={
          validationErrors.heading ? classes.errorInput : classes.inputBorder
        }
        onChange={dataHandler}
        autoComplete="off"
        validationErrors={validationErrors.heading}
      />

      <div className={classes.editorStyle}>
        <TextDescription
          lable="Description"
          name="innerHTML"
          onChange={handleContentChange}
          value={howToPlayData.innerHTML}
        />
      </div>
      <div className={classes.buttonWrap}>
        <Button
          type="button"
          customButtonWidth={classes.button}
          disabled={isSubmitting}
          onClick={howtoplayHandler}
        >
          {isSubmitting ? "Submitting..." : "Save"}
        </Button>
      </div>
    </ModalComponent>
  );
}

export default HowToPlayCreateModal;
