/* 
Author: Sreenivassan R
*/
export const stateDropDown = [
    {
    _id: "AN",
    state: "Andaman and Nicobar Islands"
    },
    {
    _id: "AP",
    state: "Andhra Pradesh"
    },
    {
    _id: "AR",
    state: "Arunachal Pradesh"
    },
    {
    _id: "AS",
    state: "Assam"
    },
    {
    _id: "BR",
    state: "Bihar"
    },
    {
    _id: "CG",
    state: "Chandigarh"
    },
    {
    _id: "CH",
    state: "Chhattisgarh"
    },
    {
    _id: "DH",
    state: "Dadra and Nagar Haveli"
    },
    {
    _id: "DD",
    state: "Daman and Diu"
    },
    {
    _id: "DL",
    state: "Delhi"
    },
    {
    _id: "GA",
    state: "Goa"
    },
    {
    _id: "GJ",
    state: "Gujarat"
    },
    {
    _id: "HR",
    state: "Haryana"
    },
    {
    _id: "HP",
    state: "Himachal Pradesh"
    },
    {
    _id: "JK",
    state: "Jammu and Kashmir"
    },
    {
    _id: "JH",
    state: "Jharkhand"
    },
    {
    _id: "KA",
    state: "Karnataka"
    },
    {
    _id: "KL",
    state: "Kerala"
    },
    {
    _id: "LD",
    state: "Lakshadweep"
    },
    {
    _id: "MP",
    state: "Madhya Pradesh"
    },
    {
    _id: "MH",
    state: "Maharashtra"
    },
    {
    _id: "MN",
    state: "Manipur"
    },
    {
    _id: "ML",
    state: "Meghalaya"
    },
    {
    _id: "MZ",
    state: "Mizoram"
    },
    {
    _id: "NL",
    state: "Nagaland"
    },
    {
    _id: "OR",
    state: "Odisha"
    },
    {
    _id: "PY",
    state: "Puducherry"
    },
    {
    _id: "PB",
    state: "Punjab"
    },
    {
    _id: "RJ",
    state: "Rajasthan"
    },
    {
    _id: "SK",
    state: "Sikkim"
    },
    {
    _id: "TN",
    state: "Tamil Nadu"
    },
    {
    _id: "TS",
    state: "Telangana"
    },
    {
    _id: "TR",
    state: "Tripura"
    },
    {
    _id: "UK",
    state: "Uttar Pradesh"
    },
    {
    _id: "UP",
    state: "Uttarakhand"
    },
    {
    _id: "WB",
    state: "West Bengal"
    }
    ]