/* 
Author: Sreenivassan R
*/
import React from "react";
import classes from "./Button.module.css";

function Button(props) {
  return (
    <button
      className={`${classes.buttonContainer} 
      ${props.customButton} ${props.customButtonWidth}` }
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {props.children}
    </button>
  );
}

export default Button;

/* Created as the Same Style Button to be used through out the Project 
customButtonWidth can use it to change the width of the button */