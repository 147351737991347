/* 
Author: Sreenivassan R
Module: Result List
*/
import React, { useEffect, useRef, useState } from "react";
import classes from "./ResultList.module.css";
import Button from "../UI/Button";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Toast from "../Toast";
import OCRComponent from "./Uploadremoteurl";
import { fetchLotteryResult } from "../../util/result";
import DataGridSetup from "../UI/DataGridSetup";

function ResultList() {
  const navigate = useNavigate();

  const [lotteryData, setLotteryData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState();
  const [loading, setLoading] = useState(false);
  const [shouldGetPageLength, setShouldGetPageLength] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const [searchBool, setSearchBool] = useState(false);
  const [searchData, setSearchData] = useState(false);
  const searchValueRef = useRef(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [pageLimit, setPageLimit] = useState(10);
  const [pageLength, setPageLength] = useState(0);
  const authToken = localStorage.getItem("token");
  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const currentDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const formatToISO = (date) => {
    if (!date) {
      return null; // Return null if date is null or undefined
    }
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}T00:00:00`;
  };

  const startDateISO = formatToISO(firstDayOfCurrentMonth);
  const endDateISO = formatToISO(currentDayOfCurrentMonth);

  const [startDate, setStartDate] = useState(startDateISO);
  const [endDate, setEndDate] = useState(endDateISO);
  const [formateStartDate, setFormateStartDate] = useState();
  const [formateEndDate, setFormateEndDate] = useState();

  const handleStartDateChange = (date) => {
    const formattedDate = formatToISO(date);
    setStartDate(formattedDate ?? startDateISO);
    setFormateStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = formatToISO(date);
    setEndDate(formattedDate ?? endDateISO);
    setFormateEndDate(date);
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      setIsSubmitting(true)
      fetchLotteryResult(
        authToken,
        toast,
        setLotteryData,
        shouldGetPageLength,
        paginationModel.page,
        setPageLength,
        startDate,
        endDate,
        pageLimit,
        searchValue
      );
      setIsSubmitting(false)
    }
  }, [paginationModel, startDate, endDate, searchBool]);

  const columns = [
    { field: "name", headerName: "Name", flex: 1 },
    { field: "lotterycategoryname", headerName: "Category", flex: 1 },
    {
      field: "ticketstartdatetime",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.DateTimeFlex}>
          <span className={classes.dateText}>
            {params.value.split("  ")[0]}
          </span>
          <span className={classes.timeText}>
            {params.value.split("  ")[1]}
          </span>
        </div>
      ),
    },
    { field: "totalprize", headerName: "Prize Amount", flex: 1 },
    { field: "cost", headerName: "Ticket Price",flex: 1 },
    { field: "lotterystatus", headerName: "Lottery Status", flex: 1 },
    {
      field: "button",
      headerName: "Result",
      flex: 1,

      renderCell: (params) => {
        var lotteryId = params.row.id;
        var winningcombination = params.row.winningcombination;
        if (
          params.row.lotterystatus == "Pending" &&
          winningcombination &&
          Object.keys(winningcombination).length
        ) {
          return (
            <OCRComponent
              lotteryId={lotteryId}
              winningcombination={winningcombination}
            />
          );
        } else if (
          params.row.lotterystatus == "Pending" &&
          winningcombination &&
          !Object.keys(winningcombination).length
        ) {
          return <OCRComponent lotteryId={lotteryId} />;
        } else {
          return (
            <Button
              onClick={async () => {
                navigate("/Root/ResultDetail", {
                  state: { id: params.row.id },
                });
              }}
              disabled={isSubmitting}
              customButtonWidth={classes.drawResult}
            >
              Results
            </Button>
          );
        }
      },
    },
  ];

  const handlePaginationModelChange = (newPaginationModel) => {
    console.log({ newPaginationModel });
    const { pageSize } = newPaginationModel;
    setPageLimit(pageSize);
    setPaginationModel(newPaginationModel);
    setShouldGetPageLength(false);
  };


  const handleSearch = (data) => {
    setTimeout(() => {
      setSearchData(true)
      setSearchValue(searchValueRef.current.value);
      setSearchBool(true);
    }, 5000);
  };

  const handleClear = () =>{
    setTimeout(() => {
      setSearchData(false)
      setSearchValue("")
      searchValueRef.current.value = "";
      console.log({searchValue})
      setSearchBool(false);
    }, 500);
  }


  return (
    <div className={classes.GridWrapper}>
      <DataGridSetup
        heading="Results"
        rows={lotteryData}
        columns={columns}
        paginationModel={paginationModel}
        handlePaginationModelChange={handlePaginationModelChange}
        loading={loading}
        pageLength={pageLength}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        formateStartDate={formateStartDate}
        formateEndDate={formateEndDate}
        startdate={startDate}
        enddate={endDate}
        date={true}
        handleSearch={handleSearch}
        searchData={searchData}
        handleClear={handleClear}
        value={searchValue}
        searchValueRef={searchValueRef}
        sortField={"ticketstartdatetime"}
        fetchData={lotteryData}
        report="result"
      />
      <Toast />
    </div>
  );
}

export default ResultList;
