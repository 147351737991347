/* 
Author: Sreenivassan R
Module: Create Promotions & Offers
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import { toast } from "react-toastify";
import classes from "../CreateModal.module.css";
import Button from "../UI/Button";
import SingleDataTime from "../SingleDataTime";
import { useNavigate } from "react-router-dom";
import DropDown from "../UI/DropDown";
import { createOfferData, offerApi } from "../../util/promotions";
import TextDescription from "../UI/TextDescription";

function PromotionsCreateModal({
  Hide,
  id,
  notifysuccess,
  notifyfail,
  updatePromotionDataGet,
  setRender,
}) {
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState();
  const [offerType, setOfferType] = useState([]);
  const [lottType, setLottType] = useState();
  const [label, setLabel] = useState("");
  const [promotionData, setPromotionData] = useState({
    code: "",
    name:"",
    description: "",
    termsandconditions:"",
    type: "",
    start: new Date().toISOString(),
    end: new Date().toISOString(),
    discount: "",
    nooftickets: "",
    noofdays: 0,
    targetuser: "",
    valuetype: "",
    minamount: "",
  });

  const [err, setErr] = useState({
    code: false,
    description: false,
    name:false,
    termsandconditions:false,
    type: false,
    start: false,
    end: false,
    discount: false,
    nooftickets: false,
    noofdays: false,
    targetuser: false,
    valuetype: false,
    minamount: false,
  });

  const dropOptionsOne = [
    { _id: "everyone", targetuser: "Everyone" },
    { _id: "beginner", targetuser: "Beginner" },
    { _id: "intermediate", targetuser: "Intermediate" },
    { _id: "expert", targetuser: "Expert" },
  ];

  const dropOptionsTwo = [
    { _id: "amount", valuetype: "Amount" },
    { _id: "percentage", valuetype: "Percentage" },
  ];

  useEffect(() => {
    const fetchData = async () =>{
      if (!authToken) {
        navigate("/");
      } else {
        const offers = await offerApi(authToken);
        setOfferType(offers);
        console.log(updatePromotionDataGet.id)
        console.log(id)
        if (id) {
          console.log(id);
          setPromotionData(updatePromotionDataGet);
          console.log(updatePromotionDataGet);
         
        }
      }
    }
    fetchData();
  }, [updatePromotionDataGet])

  const dataHandler = (e) => {
    const { name, value } = e.target;

    setPromotionData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }

  };

  function OfferHandler(promotionData) {
    createOfferData(
      setPromotionData,
      authToken,
      id,
      notifysuccess,
      Hide,
      toast,
      promotionData,
      err,
      setErr
    );
  }

  const handleContentChange = (event) => {
    // Replace all "/" characters with "\/"
    const updatedContent = event.target.value

    // Update the state with the modified contentprops.dangerouslySetInnerHTML
    setPromotionData((prevState) => ({
      ...prevState,
      termsandconditions: updatedContent,
    }));
  };
  const handleDescription = (event) => {
    const updatedDescription = event.target.value
    setPromotionData((prevState) => ({
      ...prevState,
      description: updatedDescription,
    }));
  };

  const offerTypeHandler = (selectedType, type, offerType) => {
    setPromotionData((prevState) => ({
      ...prevState,
      type: selectedType,
    }));

    console.log(offerType);
    const selectedTypeData = offerType.find((data) => data.type === type);
    setLabel(selectedTypeData.label);
    setLottType(selectedTypeData.type);
    console.log({ lottType });

    if (
      selectedTypeData.type === "Welcome Offer" ||
      selectedTypeData.type === "Comeback Offer"
    ) {
      setPromotionData((prevData) => ({
        ...prevData,
        targetuser: "Everyone",
      }));
    }
    if (
      selectedTypeData.type === "Bonus" 
    ) {
      setPromotionData((prevData) => ({
        ...prevData,
        nooftickets: 0,
      }));
    }
  };

  const getNameAndError = (label) => {
    switch (label) {
      case "days":
        return {
          name: "noofdays",
          error: err.noofdays,
          value: (promotionData.nooftickets = 0),
        };
      case "tickets":
        return {
          name: "nooftickets",
          error: err.nooftickets,
          value: (promotionData.noofdays = 0),
        };
      default:
        return { name: "", error: "", value: {} };
    }
  };

  const { name, error, value } = getNameAndError(label);
  const title = id ? "Edit" : "Create";
  return (
    <ModalComponent Hide={Hide}>
       <div className={classes.modalPadd}>
       <h3>{title} Promotions & Offers</h3>
       </div>
      
      <div className={classes.bannerModalHeight}>
        <div className={classes.modalFlex}>
          <DropDown
            label="Type"
            placeholder="Select Offer Type"
            name={{
              name: "type",

            }}
            options={offerType}
            value={promotionData.type}
            setFormData={setPromotionData}
            dataHandler={dataHandler}
            onChange={(selectedType, type) =>
              offerTypeHandler(selectedType, type, offerType)
            }
            field="type"
            validation={err.type ? classes.errorInput : ""}
            err={err.type}
          />
        </div>
        <div className={classes.modalFlex}>
          <CustomInput
            label="Code"
            type="text"
            placeholder="Offer ID"
            name="code"
            value={promotionData.code}
            customInput={err.code ? classes.errorInput : classes.inputBorder}
            onChange={dataHandler}
            autoComplete="off"
            err={err.code}
          />

          <CustomInput
            label="Name"
            type="text"
            placeholder="Offer Name"
            name="name"
            value={promotionData.name}
            customInput={
              err.name ? classes.errorInput : classes.inputBorder
            }
            onChange={dataHandler}
            autoComplete="off"
            err={err.name}
          />
        </div>

        <div className={classes.modalFlex}>
          <DropDown
            label="Value Type"
            placeholder="Select Value Type"
            name={{
              name: "valuetype",

            }}
            options={dropOptionsTwo}
            value={promotionData.valuetype}
            dataHandler={dataHandler}
            setFormData={setPromotionData}
            field="valuetype"
            validation={err.valuetype ? classes.errorInput : ""}
            err={err.valuetype}
          />

          <CustomInput
            label="Discount Value"
            type="text"
            placeholder="00"
            name="discount"
            value={promotionData.discount}
            customInput={
              err.discount ? classes.errorInput : classes.inputBorder
            }
            onChange={dataHandler}
            autoComplete="off"
            err={err.discount}
          />
        </div>

        <div className={classes.modalFlex}>
          {lottType !== "Discount" && lottType !== "Bonus" && (
            <CustomInput
              label={"Days"}
              type="text"
              placeholder="Offer Name"
              value={promotionData.noofdays}
              name={"noofdays"}
              customInput={
                err.noofdays ? classes.errorInput : classes.inputBorder
              }
              onChange={dataHandler}
              autoComplete="off"
              err={err.noofdays}
            />
          )}
          {lottType !== "Bonus" && (
            <CustomInput
              label={"Tickets"}
              type="text"
              placeholder="Tickets"
              value={promotionData.nooftickets}
              name={"nooftickets"}
              customInput={
                err.nooftickets ? classes.errorInput : classes.inputBorder
              }
              onChange={dataHandler}
              autoComplete="off"
              err={err.nooftickets}
            />
          )}
        </div>

        <div className={classes.modalFlex}>
          <CustomInput
            label="Min Amount"
            type="text"
            placeholder="00"
            name="minamount"
            value={promotionData.minamount}
            customInput={
              err.minamount ? classes.errorInput : classes.inputBorder
            }
            onChange={dataHandler}
            autoComplete="off"
            err={err.minamount}
          />
          {lottType !== "Welcome Offer" && lottType !== "Comback Offer" && (
            <DropDown
              label="Target Audience"
              placeholder="Select Target Audience"
              name={{
                name: "targetuser",
              }}
              options={dropOptionsOne}
              value={promotionData.targetuser}
              dataHandler={dataHandler}
              setFormData={setPromotionData}
              field="targetuser"
              validation={err.targetuser ? classes.errorInput : ""}
              err={err.targetuser}
            />
          )}
        </div>

        <TextDescription
         lable="Terms & Condition"
          name="termsandconditions"
          onChange={handleContentChange}
          value={promotionData.termsandconditions}
          customInput={
            err.termsandconditions ? classes.errorInput : classes.inputBorder
          }
          err={err.termsandconditions}
        />
         <TextDescription
          lable="Description"
          name="description"
          onChange={handleDescription}
          value={promotionData.description}
          customInput={
            err.description ? classes.errorInput : classes.inputBorder
          }
          err={err.description}
        />
        <div className={classes.modalFlex}>
          <div className={classes.timeDate}>
            <label>Start Date & Time</label>
            <SingleDataTime
              placeholder="DD/MM/YYYY"
              dateHandler={dataHandler}
              value={promotionData.start}
              name="start"
              setFormData={setPromotionData}
            />
          </div>
          <div className={classes.timeDate}>
            <label>End Date & Time</label>
            <SingleDataTime
              placeholder="DD/MM/YYYY"
              dateHandler={dataHandler}
              value={promotionData.end}
              name="end"
              setFormData={setPromotionData}
            />
          </div>
        </div>

        <div className={classes.buttonWrap}>
          <Button
            type="button"
            customButtonWidth={classes.button}
            disabled={isSubmitting}
            onClick={() => OfferHandler(promotionData)}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default PromotionsCreateModal;
