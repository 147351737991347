/* 
Author: Sreenivassan R
Module: FAQ Deatail
*/
import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "../ModalDetails.module.css";
import FaqCreateModal from "./FaqCreateModal";
import { faqDelete, fetchFaqData } from "../../util/faq";
import DataGridDefault from "../UI/DataGridDefault";

function FaqDetail() {
  const [faqDataGet, setFaqDataGet] = useState([]);
  const [faqDataGetEdit, setFaqDataGetEdit] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState();

  const authToken = localStorage.getItem("token");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchFaqData(authToken, setFaqDataGet, setLoading, notifyfail);
    }
  }, []);

  let columns = [
    { field: "question", headerName: "Heading", width: 500 },
    {
      field: "answer",
      headerName: "Description",
      width: 700,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: (val) => {
        var id = val.id;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(val.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  /* Handler function on Edit Icon Click */
  const handleEditClick = (row) => {
    console.log(row);
    setId(row._id);
    setFaqDataGetEdit(row);
    setShow(true);
  };

  /* Handler function on Delete Icon Click */
  function handleDeleteClick(id) {
    faqDelete(id, authToken, notifysuccess, notifyfail);
  }

  /* Handler function to show the FAQ Create Modal */
  function faqAddHandler() {
    setId("");
    setFaqDataGetEdit("");
    setShow(true);
  }

  /* Handler function to hide the FAQ Modal */
  function hideHandler() {
    setShow(false);
  }

  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading="FAQ List"
        rows={faqDataGet}
        columns={columns}
        loading={loading}
        permission={permission}
        date={false}
        createFunction={faqAddHandler}
      />
      {show && (
        <FaqCreateModal
          id={id}
          updateFaqDataGet={faqDataGetEdit}
          notifysuccess={notifysuccess}
          notifyfail={notifyfail}
          Hide={hideHandler}
        />
      )}
      <Toast />
    </div>
  );
}

export default FaqDetail;
