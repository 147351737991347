/* 
Author: Sreenivassan R
*/
import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DropDown.module.css";

const MultiSelectDropDown = ({
  label,
  placeholder,
  options,
  setFormData,
  name,
  field,
  value,
  err,
  setErr,
  customInput,
  validationErrors,
  dropDown,
  disabled,
  onChange,
  index,
}) => {
  if (value && options) {
    var selval = options.find((v) => v._id === value);
    value = selval && selval.hasOwnProperty(field) ? selval[field] : value;
  } else if (!selval) {
    value = value;
  }

  const [drop, setDrop] = useState(false);
  const dropDownRef = useRef(null);
  const [optionSelect, setOptionSelect] = useState(
    Array.isArray(value) ? value : []
  );

  const dropDownHandler = () => {
    if (!disabled) {
      setDrop(!drop);
    }
  };

  /* To Close the Drop Down*/
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDrop(false);
    }
  };

  useEffect(() => {
    setOptionSelect(Array.isArray(value) ? value : []);
    if (disabled) {
      setDrop(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    /* Cleanup the event listener on component unmount */
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value, disabled]);

  const handleCheckboxChange = (optionid, optionname) => {
    const selectedValues = optionSelect.includes(optionname)
      ? optionSelect.filter((val) => val !== optionname)
      : [...optionSelect, optionname];

    setOptionSelect(selectedValues);

    /* Update formData */
    setFormData((prevState) => ({
      ...prevState,
      [name]: selectedValues,
    }));

    if (err && optionid) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }

    if (typeof onChange === "function") {
      onChange(optionid, selectedValues, index);
    }
  };

  return (
    <Fragment>
      <div className={classes.lotteryDropDown} ref={dropDownRef}>
        <label>{label}</label>
        <div
          onClick={dropDownHandler}
          className={`${classes.DropdownMain} ${customInput}`}
          disabled={disabled}
        >
          <div>{optionSelect.join(", ") || placeholder}</div>
          <div>
            <FontAwesomeIcon icon={drop ? faChevronUp : faChevronDown} />
          </div>
        </div>

        {drop && (
          <div className={`${classes.dropDownMenu} ${dropDown}`}>
            <div>
              {Array.isArray(options) && options.length > 0 && (
                <div>
                  {options.map((option) => (
                    <div className={classes.dropMenuItem} key={option._id}>
                      <input
                        type="checkbox"
                        checked={optionSelect.includes(option[field])}
                        onChange={() =>
                          handleCheckboxChange(option._id, option[field])
                        }
                      />
                      {option[field]}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {validationErrors && (
          <span className={classes.errorMsg}>{validationErrors}</span>
        )}
        {err ? (
          <span className={classes.errorMsg}>
            This {label} field is required.
          </span>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default MultiSelectDropDown;
