/* 
Author: Sreenivassan R
*/
import React from "react";
import classes from './SliderToggle.module.css';

function SliderToggle({ onClick, name, checked, onChange, value, disabled }) {
  const handleChange = (event) => {
    if (onChange) {
      onChange(event.target.checked);
    }
  };

  return (
    <div className={`${classes.SliderAlign}`}>
      <label className={classes.toggleSwitch}>
        <input
          type="checkbox"
          onClick={onClick}
          name={name}
          checked={checked}
          onChange={handleChange}
          value={value}
          disabled={disabled}
        />
        <span className={classes.slider}></span>
      </label>
    </div>
  );
}

export default SliderToggle;
