/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";

const ADMINURL = process.env.REACT_APP_ADMINURL ;
export const fetchNotifyData = async (
  setnotifyDataGet,
  authToken,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getNotifications", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();


    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchNotifyData(  setnotifyDataGet,
          authToken, false);
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    var i = 1;
    console.log("Notification", responseData.data);

    const notifyDataGet = responseData.data.map((item, index) => {
      const scheduledTime = new Date(item.scheduledTime);

      const scheduledTimeOptions = {
        timeZone: "Asia/Kolkata",
        month: "short",
        day: "2-digit",
        year: "numeric",
      };

      const scheduledTimetimeOptions = {
        timeZone: "Asia/Kolkata",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      };

      const formattedscheduledTime = scheduledTime.toLocaleDateString(
        "en-GB",
        scheduledTimeOptions
      );
      const formattedTime = scheduledTime.toLocaleTimeString(
        "en-US",
        scheduledTimetimeOptions
      );

      return {
        uniqueId: `row_${index}`,
        id: item._id,
        _id: item._id,
        title: item.title,
        body: item.body,
        scheduledTime: `${formattedscheduledTime}  ${formattedTime}`,
        group: item.group,
        notificationType: item.notificationType,
        value: item.value,
      };
    });

    setnotifyDataGet(notifyDataGet);
    console.log("notifyDataGet", notifyDataGet);
    console.log("Data submitted successfully:", notifyDataGet);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const notificationAdd = async function bannerHandler(
  setIsSubmitting,
  id,
  notificationData,
  authToken,
  toast,
  notifysuccess,
  Hide,
  setRender,
  validateForm,
  setValidationErrors,
  setNotificationData
) {
  const errors = validateForm(notificationData);
  console.log(errors);

  if (Object.values(errors).some((va) => va !== "")) {
    setValidationErrors(errors);
    console.log(errors);

    return;
  } else {
    setNotificationData((prevFormData) => ({
      ...prevFormData,
    }));
  }
  try {
    setIsSubmitting(true);
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };
    // Log the mode value
    const { notificationTypeid, groupid, subGroupid, ...notifyedit } =
    notificationData;
    const response = await fetch(
      ADMINURL+"/admin/saveNotifications",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ ...notifyedit, ...postdata }),
      }
    );
    const responseData = await response.json();
    console.log("loteryList", responseData);
    setIsSubmitting(false);
    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    if (responseData.sts === "1") {
      const notifyfail = () =>
        toast.error(responseData.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      notifyfail();
      return;
    }
    if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    setRender((prev) => !prev);
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const notificationDelete  = async function handleDeleteClick (id, authToken, notifysuccess, notifyfail) {
  console.log(id);
  const isConfirmed = window.confirm(
    "Do You Want to Delete this Notification?"
  );
  if (isConfirmed) {
    try {
      const response = await fetch(
        ADMINURL+"/admin/saveNotifications",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ id: id, mode: "delete" }),
        }
      );
      console.log("response categery", response);
      if (response.status === 200) {
        notifysuccess();
        window.location.reload();
      }
      if (
        response.status === 413 ||
        response.status === 500 ||
        response.status === 404
      ) {
        notifyfail();
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  }
};

export const notifyTypes = async (authToken) => {
  try {
    const response = await fetch(
      ADMINURL+"/admin/getNotifyGroupAndTypes",
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const data = await response.json();

    return data.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};