/* 
Author: Manickam S
Module: Dashboard Card
*/
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import classes from './dashboard.module.css';
import Icon from "../../assetes/images/Icon@3x.png";

const DashCard = ({ title, value}) => {
    let [newValue,oldValue] =value;
    let change =0;
    
    change= ((newValue - oldValue) / oldValue) * 100;
    if (oldValue === 0) {
        change= newValue === 0 ? 0 : 999; // Using 999% as an indicator of a large increase
    }
    change=change.toFixed(2); 
    return (
        <Box className={classes.card} sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid item xs={10}>
                    <span className={classes.card_title}>{title}</span>
                </Grid>
                <Grid item xs={2}>
                    <img src={Icon} height="25" width='25' />
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={10}>
                    <span className={classes.card_value}>{value[0].toLocaleString('en-IN')}</span>
                </Grid>
                <Grid item xs={2}>

                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={12}>
                    {change==0 ? <><span style={{ color: "#00B69B",fontSize:"11px" }}>{change}%</span>  <span style={{ fontSize:"11px" }}>No change, steady</span></> : (change >0 ? <><span style={{ color: "#00B69B",fontSize:"11px" }}>{change}%</span>  <span style={{ fontSize:"11px" }}>significant rise</span></> :<><span style={{ color: "red",fontSize:"11px" }}>{change}%</span>  <span style={{ fontSize:"11px" }}>significant decrease</span></>)}
               
                </Grid>

            </Grid>
        </Box>
    )
}

export default DashCard;
