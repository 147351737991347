/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL
export const resetPasswordHandler = async function storePassword(
  setError,
  setIsLoading,
  params,
  passval,
  setPasswordSet,
  notifyfail,
  navigate,
  notifysuccess
) {
  if (passval.password !== passval.confirmpassword) {
    setError("Password should be same.");
    return;
  }

  try {
    setIsLoading(true);
    const response = await fetch(
      ADMINURL+"/admin/storePassword",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: params.id, password: passval.password }),
      }
    );
    setIsLoading(false);
    const responseData = await response.json();
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    if (responseData.sts === "0") {
      setPasswordSet("Password Changed Successfully.");
      if (response.status === 200) {
        notifysuccess();
      } else if (response.status === 413) {
        notifyfail();
      } else if (response.status === 500) {
        notifyfail();
      }
      setTimeout(() => {
        navigate("/");
      }, 3000);
    } else {
      alert(responseData.msg);
    }
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};
