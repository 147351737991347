/* 
Author: Sreenivassan R
*/
import React, { Fragment, useEffect, useRef, useState } from "react";
import classes from "./HeaderRoot.module.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleChevronDown,
  faCircleChevronUp,
  faCircleUser,
  faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import { fetchProfileInfo } from "../../util/profile";

function HeaderRoot() {
  const navigate = useNavigate();
  function logoutHandler() {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("modCurrent")
    localStorage.removeItem('location')
    navigate("/");
  }
  const [profileDrop, setProfileDrop] = useState();
  const [profile, setProfile] = useState([]);
  const dropDownRef = useRef(null);
  function profileDropHandler() {
    setProfileDrop(!profileDrop);
  }
  function profileHandler(){
    navigate("/Root/Profile")
    setProfileDrop(false)
  }
  const authToken = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) {
        navigate("/");
      } else {
        try {
          fetchProfileInfo(authToken,setProfile);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
    document.addEventListener("mousedown", handleClickOutside);
    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [authToken]);

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setProfileDrop(false);
    }
  };

  return (
    <Fragment>
      <div className={classes.headerStyle} ref={dropDownRef}>
        <div className={classes.profileFlex} onClick={profileDropHandler} >
         
         {profile.map((data)=>(
          <>
          {console.log(data.imgurl)}
           <div className={classes.profileImage}><img src={data.imgurl}/></div>
                <div className={classes.profileName} key={data.empId}>
                <p>{data.username}</p>  
                <span>{data.role}</span>
              </div>
              </>
         ))}
          <div>
            <FontAwesomeIcon
              icon={profileDrop ? faCircleChevronUp : faCircleChevronDown}
              className={classes.fontSize}
              color="#718096"
            />
          </div>
        </div>
        {profileDrop && (
          <div className={classes.profileDrop}>
            <button type="button" className={classes.iconFlex} onClick={profileHandler}>
              <FontAwesomeIcon
                icon={faCircleUser}
                className={classes.fontSize}
                color="#718096"
              />
              <span className={classes.buttonFlexD}>
                Profile
                <span>View Profile</span>
              </span>
            </button>
            <button
              type="button"
              onClick={logoutHandler}
              className={classes.iconFlex}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                className={classes.fontSize}
                color="#718096"
              />
              <span className={classes.buttonFlexD}>
                {" "}
                Log Out
                <span>Log Out your account</span>
              </span>
            </button>
          </div>
        )}
      </div>
    </Fragment>
  );
}

export default HeaderRoot;
