/* 
Author: Sreenivassan R
Module: Create Catagory
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import classes from "../CatagoryManagement/CreateCatagory.module.css";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { feedBackCreate } from "../../util/feedbackType";

function CreateFeedBack({ Hide, id, updateCatagoryDataGet }) {
  const [catagoryData, setCatagoryData] = useState({
    option: "",
    status: true,
    mediaEnable: true,
  });
  const [err, setErr] = useState({
    option: false,
  });
  const authToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (id) {
      setCatagoryData(updateCatagoryDataGet);
    }
  }, [updateCatagoryDataGet]);

  /* Getting data from the Lottery Input */
  const dataHandler = (e) => {
    const { name, value, type } = e.target;

    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }

    if (type === "radio" && name === "radio") {
      setCatagoryData((prevState) => ({
        ...prevState,
        status: value === "enable" ? true : false,
      }));
    } else if (type === "radio" && name === "radio1") {
      setCatagoryData((prevState) => ({
        ...prevState,
        mediaEnable: value === "true" ? true : false,
      }));
    } else {
      setCatagoryData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  console.log(catagoryData);

  /* To Close the Modal popup */
  const cancelHandler = () => {
    Hide();
  };

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function catagoryHandler() {
    feedBackCreate(
      id,
      authToken,
      setCatagoryData,
      setIsSubmitting,
      notifysuccess,
      Hide,
      toast,
      catagoryData,
      err,
      setErr
    );
  }

  const title = id ? "Edit" : "Create";
  return (
    <ModalComponent Hide={Hide}>
      <div className={classes.modalPadd}>
        <h3>{title} Category</h3>
        <CustomInput
          label="Category"
          placeholder="Category"
          customInput={err.option ? classes.errorInput : classes.addCatagory}
          name="option"
          value={catagoryData.option}
          onChange={dataHandler}
          err={err.option}
        />

        <div className={classes.checkBoxFunction}>
          <p>Status</p>
          <input
            type="radio"
            id="option1"
            name="radio"
            value="enable"
            checked={catagoryData.status === true}
            onChange={dataHandler}
          />
          <label htmlFor="option1">Enable</label>

          <input
            type="radio"
            id="option2"
            name="radio"
            value="disable"
            checked={!catagoryData.status}
            onChange={dataHandler}
          />
          <label htmlFor="option2">Disable</label>
        </div>

        <div className={classes.checkBoxFunction}>
          <p>Enable Media</p>
          <input
            type="radio"
            id="option3"
            name="radio1"
            value="true"
            checked={catagoryData.mediaEnable === true}
            onChange={dataHandler}
          />
          <label htmlFor="option3">True</label>

          <input
            type="radio"
            id="option4"
            name="radio1"
            value="false"
            checked={!catagoryData.mediaEnable}
            onChange={dataHandler}
          />
          <label htmlFor="option4">False</label>
        </div>

        <div className={classes.functionButton}>
          <Button
            customButton={classes.resetButton}
            customButtonWidth={classes.button}
            Hide={Hide}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            customButtonWidth={classes.button}
            disabled={isSubmitting}
            onClick={catagoryHandler}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default CreateFeedBack;
