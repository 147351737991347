/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL;

export const generateToken = async () => {
  let id = localStorage.getItem("userId");
console.log({id})
  console.log({ id });
  const url = ADMINURL + "/admin/genToken";
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        adminId: id,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to generate token");
    }

    const responseData = await response.json();
    console.log(responseData.token);
    if (responseData.sts === "1") {
      throw new Error("Failed to generate token");
    }
    const token = responseData.token;
    localStorage.setItem("token", token);
    console.log("Token Generated Successfully");
    return "Token Generated Successfully";
  } catch (error) {
    console.error("Error generating token:", error);
    throw error;
  }
};
