/* 
Author: Sreenivassan R
*/
import React, { Fragment } from 'react'
import classes from './UserDetail.module.css';

function UserDetailScreen(props) {
  return (
   <Fragment>
    <div className={classes.wrapper}>
      {props.children}
    </div>
   </Fragment>
  )
}

export default UserDetailScreen