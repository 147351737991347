/* 
Author: Sreenivassan R
Module: KYC Detail
*/
import React, { useState } from "react";
import classes from "./KycDeatail.module.css";
import ModalComponent from "../UI/ModalComponent";
import scan from "../../assetes/images/Scan.png";
import Button from "../UI/Button";

function KycDetail({ Hide }) {
  const [sliderWidth, setSliderWidth] = useState("50%");
  return (
    <ModalComponent Hide={Hide} modalComponent={classes.modalSlide}>
      <div className={classes.kycWrap}>
        <h5>Profile Detail</h5>

        <div className={classes.profileDetail}>
          <div className={classes.kycUserImage}></div>
          <div className={classes.kycFlex}>
            <div className={classes.kycUserDetail}>Uer Detail</div>
            <div>
              <p>ID : 000000</p>
              <p>dd/mm/yyyy -hh:mm</p>
            </div>
          </div>
        </div>
        <h5>Kyc Detail</h5>
        <div className={classes.scanDetail}>
          <div>
            <img src={scan} className={classes.scanBg} />
          </div>
          <div>
            <h6>ID Proofs</h6>
            <div className={classes.idproof}>
              <h6>Aadhar Card Number</h6>
              <p>0000 0000 0000 0000</p>
            </div>
            <div className={classes.idproof}>
              {" "}
              <h6>Pan Card Number ID</h6>
              <p>0000 0000 0000 0000</p>
            </div>
          </div>
        </div>
        <div className={classes.matchFlex}>
          <h6>Match</h6>
          <p>{sliderWidth}completed</p>
        </div>
        <div className={classes.kycProgress}>
          <div className={classes.slider} style={{ width: sliderWidth }}></div>
        </div>
      </div>
      <Button type="button" customButtonWidth={classes.buttonWidth}>Verify</Button>
    </ModalComponent>
  );
}

export default KycDetail;
