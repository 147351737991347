import { memo } from 'react';

const UserScan1Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M7.23547 13.3971C7.23547 12.56 7.83867 11.8469 8.65848 11.715C9.6895 11.5491 10.7401 11.5491 11.7711 11.715C12.591 11.8469 13.1941 12.56 13.1941 13.3971V13.5824C13.1941 14.0721 12.8005 14.4689 12.3151 14.4689H8.11453C7.62904 14.4689 7.23547 14.0721 7.23547 13.5824V13.3971Z'
      stroke='#7F7A3A'
      strokeWidth={0.85124}
    />
    <path
      d='M11.9527 7.71029C11.9527 8.67822 11.1746 9.46281 10.2147 9.46281C9.25488 9.46281 8.47679 8.67822 8.47679 7.71029C8.47679 6.74239 9.25488 5.95774 10.2147 5.95774C11.1746 5.95774 11.9527 6.74239 11.9527 7.71029Z'
      stroke='#7F7A3A'
      strokeWidth={0.85124}
    />
    <path
      d='M17.876 5.95774C17.876 4.07723 15.9704 2.55278 13.6198 2.55278M2.55365 13.6189C2.55365 15.9695 4.0781 17.8751 5.95861 17.8751M5.95861 2.55278C4.0781 2.55278 2.55365 4.07723 2.55365 5.95774M13.6198 17.8751C15.9704 17.8751 17.876 15.9695 17.876 13.6189'
      stroke='#7F7A3A'
      strokeWidth={0.85124}
      strokeLinecap='round'
    />
  </svg>
);

const Memo = memo(UserScan1Icon);
export { Memo as UserScan1Icon };
