import { memo } from 'react';

import resets from '../_resets.module.css';
import { Edit11Icon } from './Edit11Icon.js';
import classes from './Edited.module.css';

const Edited = memo(function Edited(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.label}>
        <div className={classes.edit11}>
          <Edit11Icon className={classes.icon} />
        </div>
        <div className={classes.edited}>Edited</div>
      </div>
    </div>
  );
});

export { Edited };
