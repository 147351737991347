/* 
Author: Sreenivassan R
Module: Create Price
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import classes from "../CatagoryManagement/CreateCatagory.module.css";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { priceCreate } from "../../util/price";

function CreatePrice({ Hide, id, updateCatagoryDataGet }) {
    

const [catagoryData, setCatagoryData] = useState({
    cost: "",
    color: "",
    status: true,
    RadialColor:["#000","#000"],
    img: "",
    cardimg:""
});
  const [err, setErr] = useState({
    cost: false,
    color: false,
    img: false,
    cardimg:false,
    RadialColor:false
  });
  const authToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subcatagoy, setSubCatoagory] = useState(true);
  
  const [lotteryCardImage, setlotteryCardImage] = useState({img:"",cardimg:""});
  const colorOptions = [
    { id: "brown", colorCode: "Brown" },
    { id: "blue", colorCode: "Blue" },
    { id: "cyan", colorCode: "Cyan" },
    { id: "green", colorCode: "Green" },
    { id: "pink", colorCode: "Pink" },
  ];

  useEffect(() => {
    if (id) {
      setCatagoryData(updateCatagoryDataGet);
      setlotteryCardImage({img:updateCatagoryDataGet.imgurl,cardimg:updateCatagoryDataGet.cardimgurl});
     
    }
  }, [updateCatagoryDataGet]);

 /* Getting data from the Lottery Input */
const dataHandler = (e) => {
    const { name, value, type } = e.target;

    if (err[name] && value) {
        setErr((prev) => ({ ...prev, [name]: false }));
    }

    if (type === "radio" && name === "radio") {
        setCatagoryData((prevState) => ({
            ...prevState,
            status: value === "enable" ? true : false,
        }));
    } else {
        const updatedValue = name === "color" ? value.trim().toUpperCase().replace(/\s/g, "") : value;
        setCatagoryData((prevState) => ({
            ...prevState,
            [name]: updatedValue,
        }));
    }
};

  /* To Close the Modal popup */
  const cancelHandler = () => {
    Hide();
  };
  const changeColor=(colorType)=>{

  }

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function priceHandler() {
    priceCreate(
      id,
      authToken,
      setCatagoryData,
      setIsSubmitting,
      notifysuccess,
      Hide,
      toast,
      catagoryData,
      err,
      setErr
    );
  }

 /* Handler Function to convert image to base64String */
  function imageHandler(e,imgType) {
    const selectedImage = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setlotteryCardImage((prev)=>({...prev,[imgType]:base64String}))
      setCatagoryData((prevState) => ({
        ...prevState,
        [imgType]: base64String,
      }));
    };
    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    } else {
      
      setlotteryCardImage((prev)=>({...prev,[imgType]:""}))
      setCatagoryData((prevState) => ({
        ...prevState,
        [imgType]: "",
      }));
    }
  }

  /* To Remove Image */
  function handleRemoveImage(imgType) {
 

    setlotteryCardImage((prevState) => ({...prevState,[imgType]:""}));
    setCatagoryData((prevState) => ({
      ...prevState,
      [imgType]: "",
    }));
  }

 

  const handleHexColorChange = (event,index) => {
    setCatagoryData((prevState) => {

      var prevRadialcolors=prevState.RadialColor;
      prevRadialcolors[index]=event.target.value;
     return {
      ...prevState,
      RadialColor:prevRadialcolors
     }
    });
  };
  const title = id ? "Edit" : "Create";
  var images={"img":"Image",cardimg:"Lottery Card Image"};
  console.log({lotteryCardImage})
  return (
    <ModalComponent Hide={Hide}>
      <div className={classes.modalPadd}>
        <h3>{title} Price</h3>
        <CustomInput
          label="Cost"
          placeholder="Cost"
          customInput={
            err.cost ? classes.errorInput : classes.addCatagory
          }
          name="cost"
          value={catagoryData.cost}
          onChange={dataHandler}
          err={err.cost}
        />
        <CustomInput
          label="Colour"
          placeholder="Colour"
          customInput={
            err.color ? classes.errorInput : classes.addCatagory
          }
          name="color"
          value={catagoryData.color}
          onChange={dataHandler}
          err={err.color}
        />
        {Object.keys(images).map((imgType,key) => {
          console.log("sgfsdfgdsffdf")
           return (
            <>
             <div key={key}>
          <div className={classes.imageContainer}>
            <label>{images[imgType]}</label>
            <div
              className={err[imgType] ? classes.errorInputImg : classes.imagePreview}
            >
              {lotteryCardImage[imgType] && (
                <div className={classes.imageDisplayPreview}>
                  <img
                    src={lotteryCardImage[imgType] }
                    className={classes.UserprofieImage}
                    alt="Profile"
                  />
                  <button
                    onClick={()=>handleRemoveImage(imgType)}
                    className={classes.removeButton}
                  >
                    <FontAwesomeIcon icon={faTimes} className={classes.icon} />
                  </button>
                </div>
              )}
            </div>
            <div className={classes.fileButton}>
              <input
                type="file"
                onChange={(e)=>imageHandler(e,imgType)}
                accept="image/png"
                name={imgType}
              />
            </div>
          </div>
          {err[imgType] && (
            <span className={classes.errorMsg}>
              This Image field is required.
            </span>
          )}
        </div>
            </>
           )

        })}
        <div className={classes.imageContainer} >
        <label htmlFor="colors">Colors</label>
        <div style={{display:"flex",justifyContent:"start",gap:"10px",}}>
   {catagoryData.RadialColor.map((color,index)=>{
 return <div>
 <input
   type="color"
   id="colorpicker"
   name="color"
   value={color}
   pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
   style={{ display: 'block', width: '25%', float: 'left', height: '43px',borderRadius: "10px 0px 0px 10px",   border: "1px solid var(--color-inputBorder)",padding:"5px",outline: !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color) ? '2px solid red' : 'none',}}
   onChange={(e)=>handleHexColorChange(e,index)}
 />
 <input
   type="text"
   id="hexcolor"
   value={color}
   pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
   style={{
     display: 'block',
     width: '50%',
     float: 'left',
     height: '43px',
     padding: '1rem',
     fontSize:"14px",
     border: "1px solid var(--color-inputBorder)",
     borderRadius: "0px 10px 10px 0", 
     outline: !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color) ? '2px solid red' : 'none',
   }}
   onChange={(e)=>handleHexColorChange(e,index)}
 />
</div>
   })}
         
    
         </div>
         {err["RadialColor"] && (
            <span className={classes.errorMsg}>
              This Colors field is required.
            </span>
          )}
        </div>
       

        <div className={classes.checkBoxFunction}>
          <input
            type="radio"
            id="option1"
            name="radio"
            value="enable"
            checked={catagoryData.status === true}
            onChange={dataHandler}
          />
          <label htmlFor="option1">Enable</label>

          <input
            type="radio"
            id="option2"
            name="radio"
            value="disable"
            checked={!catagoryData.status}
            onChange={dataHandler}
          />
          <label htmlFor="option2">Disable</label>
        </div>

        <div className={classes.functionButton}>
          <Button
            customButton={classes.resetButton}
            customButtonWidth={classes.button}
            Hide={Hide}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            customButtonWidth={classes.button}
            disabled={isSubmitting}
            onClick={priceHandler}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default CreatePrice;
