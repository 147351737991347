/* 
Author: Sreenivassan R
*/
import React from 'react'
import classes from './LoadingSpiner.module.css'
function LoadingSpinner({spinnerWidth}) {
  return (
    <div className={`${classes.loadSpinner} ${spinnerWidth}`}></div>
  )
}

export default LoadingSpinner