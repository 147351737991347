import { memo } from 'react';

const TickCircle1Icon = (props) => (
  <svg preserveAspectRatio='none' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path
      d='M10.0001 16.7864C13.7473 16.7864 16.7852 13.7486 16.7852 10.0013C16.7852 6.25401 13.7473 3.21623 10.0001 3.21623C6.25279 3.21623 3.21501 6.25401 3.21501 10.0013C3.21501 13.7486 6.25279 16.7864 10.0001 16.7864Z'
      stroke='#34A853'
    />
    <path
      d='M9.03656 12.4304C8.90086 12.4304 8.77194 12.3761 8.67695 12.2811L6.75678 10.3609C6.56001 10.1641 6.56001 9.83845 6.75678 9.64169C6.95354 9.44492 7.27923 9.44492 7.47599 9.64169L9.03656 11.2023L12.5241 7.71473C12.7209 7.51796 13.0465 7.51796 13.2433 7.71473C13.4401 7.9115 13.4401 8.23718 13.2433 8.43395L9.39617 12.2811C9.30118 12.3761 9.17226 12.4304 9.03656 12.4304Z'
      fill='#34A853'
    />
  </svg>
);

const Memo = memo(TickCircle1Icon);
export { Memo as TickCircle1Icon };
