/* 
Author: Sreenivassan R
*/
import React, { Fragment } from "react";
import classes from "./TextDescription.module.css";

const TextDescription = ({
  onChange,
  name,
  onFocus,
  customInput,
  validationErrors,
  value,
  placeholder,
  disabled,
  err,
  asterisk,
  lable,
  maxLength
}) => {
  return (
    <Fragment>
      <div className={classes.customInputFlex}>
      <label className={classes.Textlabel}>{lable}</label>
      {asterisk && <span>*</span>} 
      </div>
      <textarea
        name={name}
        value={value}
        className={`${classes.textArea} ${customInput}`}
        rows={3}
        onChange={onChange}
        onFocus={onFocus}
        placeholder={placeholder}
        disabled={disabled}
        maxLength={maxLength}
      ></textarea>
      {validationErrors && (
        <span className={classes.errorMsg}>{validationErrors}</span>
      )}
       {err ? (
        <span className={classes.errorMsg}>
          This {lable} field is required.
        </span>
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default TextDescription;
