/* 
Author: Manickam S, Sreenivssan R
Module: Upload Result
*/
import React, { useEffect, useRef, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import classes from "./CreateRoleModal.module.css";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { roleName } from "../../util/role";
function CreateRoleModal({
  modules,
  setRender,
  Hide,
  rolename,
  id,
  inroles,
  notifysuccess,
  notifyfail,
}) {
  const [err, setErr] = useState({
    role: false,
  });
  const [additionalCheckboxes, setAdditionalCheckboxes] = useState(inroles);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [name, setName] = useState(rolename);
  const dropDownRef = useRef(null);
  const cancelHandler = () => {
    Hide();
  };
  const authToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  /* Create moduleOrder map */
  let moduleOrder = {};
  modules.forEach((mod, index) => {
    moduleOrder[mod.module] = index;
  });

  useEffect(() => {
    setAdditionalCheckboxes((prev) => {
      const sortedCheckboxes = [...prev].sort(
        (a, b) => moduleOrder[a.module] - moduleOrder[b.module]
      );
      return sortedCheckboxes;
    });
  }, []);

  function catagoryHandler() {
    if (!err.role) {
      roleName(
        setIsSubmitting,
        notifyfail,
        additionalCheckboxes,
        id,
        name,
        toast,
        notifysuccess,
        Hide,
        setRender,
        authToken,
        err,
        setErr
      );
    }
  }

  /* Handler Function of checkbox  of the Module*/
  const handleCheckboxChange = (option) => {
    if (additionalCheckboxes.some((va) => option === va.module)) {
      const updatedCheckboxes = additionalCheckboxes.filter(
        (check) => check.module !== option
      );
      setAdditionalCheckboxes(updatedCheckboxes);
    } else {
      setAdditionalCheckboxes([
        ...additionalCheckboxes,
        { module: option, view: false, add: false, edit: false, delete: false },
      ]);
    }
  };

  /* Handler Checkbox of view, add, edit, delete */
  const handleAdditionalCheckboxChange = (option, name, index) => {
    console.log({ option });
    console.log({ name });
    console.log({ index });
    const updatedCheckboxes = [...additionalCheckboxes];
    if (
      name === "edit" ||
      name === "add" ||
      (name === "delete" && !updatedCheckboxes[index][name])
    ) {
      updatedCheckboxes[index]["view"] = true;
    }
    updatedCheckboxes[index][name] = !updatedCheckboxes[index][name];
    setAdditionalCheckboxes(updatedCheckboxes);
  };

  /* To Close the drop Down */
  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  /* To Declare Role Name */
  function nameHandler(e) {
    const newValue = e.target.value;
    console.log(newValue);

    if (newValue.trim() === "" || newValue.trim() === "0") {
      setErr((prevErr) => ({ ...prevErr, role: true }));
    } else {
      setErr((prevErr) => ({ ...prevErr, role: false }));
    }
    setName(newValue);
  }
  const title = id ? "Edit" : "Create";
  return (
    <ModalComponent Hide={Hide}>
      <div className={classes.modalPadd}>
        <h3>{title} Role </h3>
        <CustomInput
          label="Role Name"
          placeholder="Role Name"
          customInput={err.role ? classes.errorInput : classes.addCatagory}
          name="role"
          value={name}
          onChange={nameHandler}
          err={err.role}
        />

        <div className={classes.DropWrap} ref={dropDownRef}>
          <div onClick={toggleDropdown} className={classes.DropdownMain}>
            <div>Select Permission</div>
            <div>
              <FontAwesomeIcon
                icon={dropdownOpen ? faChevronUp : faChevronDown}
              />
            </div>
          </div>
          {dropdownOpen && (
            <div className={classes.dropDownMenu}>
              {modules.map((option, index, order) => (
                <div key={index} className={classes.dropMenuItem}>
                  <input
                    type="checkbox"
                    checked={additionalCheckboxes.some((val) => {
                      return val.module === option.module;
                    })}
                    onChange={() => handleCheckboxChange(option.module)}
                  />
                  <p className={classes.optionName}>{option.module}</p>
                </div>
              ))}
            </div>
          )}
          <div className={classes.checkBoxWrap}>
            {additionalCheckboxes.length
              ? additionalCheckboxes.map((options, index) => (
                  <div key={options.module} className={classes.checkedBoxWrap}>
                    <p>{options.module}</p>
                    <div className={classes.checkedBoxAdd}>
                      <div className={classes.checkedBoxAddOption}>
                        <input
                          type="checkbox"
                          className={classes.customChecked}
                          checked={options.view}
                          onChange={() =>
                            handleAdditionalCheckboxChange(
                              options.module,
                              "view",
                              index
                            )
                          }
                        />
                        <p>View</p>
                      </div>
                      <div className={classes.checkedBoxAddOption}>
                        <input
                          type="checkbox"
                          className={classes.customChecked}
                          checked={options.add}
                          onChange={() =>
                            handleAdditionalCheckboxChange(
                              options.module,
                              "add",
                              index
                            )
                          }
                        />
                        <p>Add</p>
                      </div>
                      <div className={classes.checkedBoxAddOption}>
                        <input
                          type="checkbox"
                          className={classes.customChecked}
                          checked={options.edit}
                          onChange={() =>
                            handleAdditionalCheckboxChange(
                              options.module,
                              "edit",
                              index
                            )
                          }
                        />
                        <p>Edit</p>
                      </div>
                   
                      <div className={classes.checkedBoxAddOption}>
                        <input
                          type="checkbox"
                          className={classes.customChecked}
                          checked={options.delete}
                          onChange={() =>
                            handleAdditionalCheckboxChange(
                              options.module,
                              "delete",
                              index
                            )
                          }
                        />
                        <p>Delete</p>
                      </div>
                    </div>
                  </div>
                ))
              : ""}
          </div>
        </div>

        <div className={classes.functionButton}>
          <Button
            customButton={classes.resetButton}
            customButtonWidth={classes.button}
            Hide={Hide}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            customButtonWidth={classes.button}
            disabled={isSubmitting}
            onClick={catagoryHandler}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default CreateRoleModal;
