/* 
Author: Manickam S
*/
import React, { useState, useRef, useEffect } from "react";
import classes from "./fileUpload.module.css";
import { deleteImage } from "../../util/fileUpload";
const FileUpload = ({
  name,
  setFormData,
  imgurl,
  imgname,
  AdminId,
  fileUploadroot,
  imageArea,
  profileImageWidth,
  label,
  discritionFileText,
  paddingfileUploadroot,
}) => {
  const [profileImage, setProfileImage] = useState(imgurl);
  const [imgError, setImgError] = useState("");
  const fileInputRef = useRef(null);
  function handleDateChange(value) {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  /* To Remove Image*/
  function handleDeleteFile() {
    deleteImage(
      profileImage,
      imgname,
      AdminId,
      setProfileImage,
      setFormData,
      fileInputRef,
      name
    );
    setImgError(false)
  }

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    const reader = new FileReader();

    const sizeInMB = (selectedImage.size / (1024 * 1024)).toFixed(2);
 
    reader.onload = () => {
      const base64String = reader.result;
      setProfileImage(base64String);

      handleDateChange(base64String);
      console.log(profileImage);
    };
    if (sizeInMB > 2) {
      setImgError("File size must be less than 2MB");
      return;
    }
    /* Read the file as a base64 encoded string */
    reader.readAsDataURL(selectedImage);
  };
  useEffect(() => {
    setProfileImage(imgurl);
  }, [imgurl]);
  return (
    <div className={`${classes.root} ${fileUploadroot}`}>
      <div>
        <label>{label}</label>
        <div className={`${classes.frame19} ${paddingfileUploadroot}`}>
          {!profileImage ? (
            <>
              <div className={classes.frame17}>
                <div className={`${classes.frame16} ${discritionFileText}`}>
                  <div className={classes.chooseAFileOrDragDropItHere}>
                    Choose a file
                  </div>
                  <div className={classes.jPEGPNGFormatsUpTo5MB}>
                  {!imgError ? <p>Drag Files here <span>Upload upto 2Mb</span></p> : <p className={classes.errorImage}>{imgError}</p>}
                 
                  </div>
                </div>
              </div>
              <div className={classes.frame15}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                  accept="image/png"
                />
                <div className={classes.browseFile} onClick={handleClick}>
                  Browse File
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {profileImage && (
            <div className={`${classes.image_area} ${imageArea}`}>
              <img
                src={profileImage}
                className={`${classes.UserprofieImage} ${profileImageWidth}`}
                alt="Profile"
              />
              <button
                onClick={() => handleDeleteFile()}
                type="button"
                className={classes.remove_image}
              >
                &#215;
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FileUpload;