/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL ;
export const loginHandler = async function submitHandler(event, setIsLoading, loginData, setError, navigate) {
    setIsLoading(true);
    try {
      const response = await fetch(
        ADMINURL+"/admin/login",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(loginData),
        }
      );
      const responseData = await response.json();
      setIsLoading(false);
      console.log(responseData);
      setError(responseData.msg);
      if (!response.ok) {
        throw new Error(
          responseData.message || "Failed to submit data to the server"
        );
      }
      console.log("Login successful");
      
      if (responseData.sts === "1") {
      } else {
        const token = responseData.data.token;
        const id = responseData.data.id;
        localStorage.setItem("token", token);
        localStorage.setItem("userId", id);
        console.log(responseData.data.token);
        console.log(responseData.data.id);
        navigate("/Root/LotteryManagement" );
        // navigate(`/Root/${firstlink}`, { state: firststate });
      }
    } catch (error) {
      if (
        error.message === "Password Incorrect" ||
        error.message === "User Not Found."
      ) {
        navigate("/");
      }
     
      console.error("Error submitting data:", error.message);
      console.error("Error setting userId in localStorage:", error);
    }
  }