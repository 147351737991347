/* 
Author: Sreenivasan R
Module: Dashboard
*/
import React, { useEffect, useState } from "react";
import classes from "./ResultDetails.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchLotteryData } from "../../util/result";
import DataGridSetup from "../UI/DataGridSetup";
function ResultDetails() {
  const locationval = useLocation();
  const lotteryId = locationval.state.id;
  console.log(lotteryId);
  const [resultData, setResultData] = useState([]);
  const [ticketdata, setticketdata] = useState([]);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [loading, setLoading] = useState(false);
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchLotteryData(lotteryId, authToken, setticketdata, setResultData);
    }
  }, [lotteryId, authToken]);

  const columns = [
    { field: "buyername", headerName: "User Name", width: 300 },
    { field: "ticketno", headerName: "Number", width: 200 },
    { field: "winningposition", headerName: "Standings", width: 300 },
    { field: "prize", headerName: "Winnings", width: 300 },
    { field: "level", headerName: "Level", width: 300 },
    {
      field: "buydatetime",
      headerName: "DOJ",
      width: 200,
      renderCell: (params) => (
        <div className={classes.DateTimeFlex}>
          <span className={classes.dateText}>
            {params.value.split("  ")[0]}
          </span>
          <span className={classes.timeText}>
            {params.value.split("  ")[1]}
          </span>
        </div>
      ),
    },
  ];

  return (
    <div className={classes.ResultWrapper}>
      {resultData.map((lottery, index) => (
        <>
          <div className={classes.resultFlex}>
            <div className={`${classes.whiteDrop} ${classes.catagoryImage}`}>
              <img src={lottery.img} alt="lottery Logo" />
            </div>

            <div className={`${classes.whiteDrop} ${classes.catagorydInfo}`}>
              <h3>{lottery.name}</h3>
              <p>{lottery.resultId}</p>
              <p>{lottery.description}</p>
            </div>
          </div>

          <div className={`${classes.resultFlex} ${classes.resultMargin}`}>
            <div className={`${classes.whiteDrop} ${classes.ticketDetails}`}>
              <h4>Ticket Details</h4>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p>Prize amount</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>{lottery.totalprize}</p>
                </div>
              </div>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p>Ticket Price Amount</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>{lottery.cost}</p>
                </div>
              </div>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p>Ticket Start Date & Time</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>
                    {lottery.ticketstartdatetime.startdate},{" "}
                    <span>{lottery.ticketstartdatetime.starttime}</span>
                  </p>
                </div>
              </div>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p>Ticket End Date & Time</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>
                    {lottery.ticketenddatetime.enddate},{" "}
                    <span>{lottery.ticketenddatetime.endtime}</span>
                  </p>
                </div>
              </div>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p> Result Date and Time</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>
                    {lottery.resultdatetime.resultdate},{" "}
                    <span> {lottery.resultdatetime.resulttime}</span>
                  </p>
                </div>
              </div>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p> Total Number of Tickets</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>{lottery.ticketscount}</p>
                </div>
              </div>
              <div className={classes.detailsFlex}>
                <div className={classes.detailsTitle}>
                  <p> Total Winning Percentage</p>
                </div>
                <div className={classes.detailsFont}>
                  <p>{lottery.winperc}</p>
                </div>
              </div>
            </div>
            <div className={`${classes.whiteDrop} ${classes.prizeDetails}`}>
              <h4>Prize Details</h4>
              <div className={classes.prizeDetailsFlex}>
                <p>Standings</p>
                <p>Winnings</p>
              </div>
              {resultData[0] &&
                resultData[0].prizelist &&
                resultData[0].prizelist.map((prizelst) => (
                  <div className={classes.detailsFlex}>
                    <div className={classes.detailsTitle}>
                      <p>
                        {prizelst.position.includes("-")
                          ? prizelst.position.replace("-", "*")
                          : prizelst.position}
                      </p>
                    </div>
                    <div className={classes.detailsFont}>
                      <p>{prizelst.prize}</p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </>
      ))}

      <div className={classes.ResultGridWrapper}>
        <DataGridSetup
          heading="Results"
          rows={ticketdata}
          columns={columns}
          paginationModel={paginationModel}
          loading={loading}
        />
      </div>
    </div>
  );
}

export default ResultDetails;
