import { memo } from 'react';

import resets from '../_resets.module.css';
import classes from './DateTime.module.css';

const DateTime = memo(function DateTime({datetime}) {
  const ticketStartTime = new Date(datetime);
  const startdateOptions = {
    timeZone: "Asia/Kolkata",
    month: "short",
    day: "2-digit",
    year: "numeric",
  };

  const starttimeOptions = {
    timeZone: "Asia/Kolkata",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = ticketStartTime.toLocaleDateString(
    "en-US",
    startdateOptions
  );
  const formattedTime = ticketStartTime.toLocaleTimeString(
    "en-US",
    starttimeOptions
  );
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes._12May1030Am}>{formattedDate}- {formattedTime}</div>
    </div>
  );
});

export { DateTime };
