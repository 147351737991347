/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;

export const fetchFeedBackData = async (
  setCatagoryData,
  authToken,
  retry = true
) => {
  try {
    const response = await fetch(
      ADMINURL + "/admin/getfeedbackTypes",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        
      }
    );
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchFeedBackData(setCatagoryData, authToken, false);
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    const catagoryData = responseData.data.map((item, index) => {
      return {
        _id: item._id,
        id: index + 1,
        option: item.option,
        mediaEnable: item.mediaEnable,
        status: item.status,
      };
    });
    console.log("catagoryData", responseData);
    setCatagoryData(catagoryData);
    console.log("Data submitted successfully:", catagoryData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const feedBackDelete = async function handleDeleteClick(
  row,
  authToken,
  notifysuccess,
  notifyfail,
  setRender
) {
  console.log(row);
  const isConfirmed = window.confirm("Do You Want to Delete this FeedBack?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/saveFeedbackTypes", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: row, mode: "delete" }),
      });

      const data = await response.json();
      if (data.sts === "1") {
        notifyfail(data.msg);
      } else if (data.sts === "0") {
        notifysuccess("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        notifyfail("Something Went Wrong");
      }
      setRender((prev) => !prev);
    } catch (error) {
      console.error("Error:", error);
    }
  }
};

export const feedBackCreate = async function catagoryHandler(
  id,
  authToken,
  setCatagoryData,
  setIsSubmitting,
  notifysuccess,
  Hide,
  toast,
  catagoryData,
  err,
  setErr,
  retry = true
) {
  console.log("catagoryData", catagoryData);

  var check = 1;
  Object.keys(err).map((errname) => {
    if (
      typeof catagoryData[errname] !== "undefined" &&
      (catagoryData[errname].trim() === "" || catagoryData[errname] === "0")
    ) {
      check = 0;
      setErr((prev) => ({ ...prev, [errname]: true }));
      console.log(errname, "err");
      console.log(err);
    }
  });
  if (!check) {
    return false;
  }
 else {
    setCatagoryData((prevFormData) => {
      const { _id, ...rest } = prevFormData;
      console.log(rest, "rest");
      return {
        ...rest,
      };
    });
  }
  setIsSubmitting(true);
  try {
    const { _id, ...catagoryDataedit } = catagoryData;
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };
    // Log the mode value
    const response = await fetch(ADMINURL + "/admin/saveFeedbackTypes", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({ ...catagoryDataedit, ...postdata }),
    });

    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await feedBackCreate(
          id,
          authToken,
          setCatagoryData,
          setIsSubmitting,
          notifysuccess,
          Hide,
          toast,
          catagoryData,
          err,
          setErr,
          false
        );
        return;
      }
    }

    console.log("catagoryLIst", responseData);

    const notifyfail = () =>
      toast.error(responseData.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    if (responseData.sts === "1") {
      notifyfail();
      return;
    } else if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  } finally {
    setIsSubmitting(false);
  }
};
