/* 
Author: Sreenivassan R
*/
import React, { useState, Fragment, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DropDown.module.css";
const DropDown = ({
  label,
  placeholder,
  options,
  setFormData,
  name,
  field,
  value,
  err,
  setErr,
  validation,
  validationErrors,
  dropDown,
  disabled,
  onChange,
  index,
  asterisk,
}) => {
  if (value && options) {
    var selval = options.find((v) => v._id === value);
    value = selval && selval.hasOwnProperty(field) ? selval[field] : value;
  } else if (!selval) {
    value = value;
  }

  const [drop, setDrop] = useState(false);
  const [optionSelect, setOptionSelect] = useState(value);
  const dropDownRef = useRef(null);

  const dropDownHandler = () => {
    if (!disabled) {
      setDrop(!drop);
    }
  };

  const handleClickOutside = (event) => {
    if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setDrop(false);
    }
  };

  useEffect(() => {
    setOptionSelect(value);
    if (disabled) {
      setDrop(false);
    }
    document.addEventListener("mousedown", handleClickOutside);
    /* Cleanup the event listener on component unmount */
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [value, disabled]);

  function handleDateChange(id, nameval) {
    if (typeof name == "string") {
      setFormData((prevState) => ({
        ...prevState,
        [name]: id,
      }));
      if (err && id) {
        setErr((prev) => ({ ...prev, [name]: false }));
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name.id]: id,
        [name.name]: nameval,
      }));
    }
  }
  const dropOptionHandler = (optionid, optionname) => {
    setOptionSelect(optionname);
    setDrop(false);

    if (optionid) {
      {
        setErr &&
          setErr((prev) => ({
            ...prev,
            [name.id]: false, 
            [name.name]: false, 
          }));
      }
    } 

    // Update formData
    handleDateChange(optionid, optionname);

    // Trigger onChange if it's provided
    if (typeof onChange === "function") {
      onChange(optionid, optionname, index);
    }
  };

  return (
    <Fragment>
      <div className={classes.lotteryDropDown} ref={dropDownRef}>
        <div className={classes.dropDownFlex}>
          <label>{label}</label>
          {asterisk && <span>*</span>}
        </div>
        <div
          onClick={dropDownHandler}
          className={`${classes.DropdownMain} ${validation}`}
          disabled={disabled}
        >
          <div>{optionSelect ? optionSelect : placeholder}</div>
          <div>
            <FontAwesomeIcon icon={drop ? faChevronUp : faChevronDown} />
          </div>
        </div>

        {drop && (
          <div className={`${classes.dropDownMenu} ${dropDown}`}>
            <div>
              {Array.isArray(options) && options.length > 0 && (
                <div>
                  <div>
                    {options.map((option) => (
                      <Fragment key={option._id}>
                        {Array.isArray(option[field]) &&
                          option[field].length > 0 &&
                          option[field].map((subOption, index) => (
                            <div
                              className={classes.dropMenuItem}
                              key={index}
                              onClick={() =>
                                dropOptionHandler(option._id, subOption)
                              }
                            >
                              {subOption}
                            </div>
                          ))}
                        {!Array.isArray(
                          option[field]
                        ) /* If option[field] is not an array */ && (
                          <div
                            className={classes.dropMenuItem}
                            onClick={() =>
                              dropOptionHandler(option._id, option[field])
                            }
                          >
                            {option[field]}
                          </div>
                        )}
                      </Fragment>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {validationErrors && (
          <span className={classes.errorMsg}>{validationErrors}</span>
        )}
        {err ? (
          <span className={classes.errorMsg}>
            This {label} field is required.
          </span>
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default DropDown;
