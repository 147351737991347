/* 
Author: Sreenivassan R
*/
import React, { useState } from "react";
import classes from "./Password.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Password({
  customPassword,
  customMargin,
  label,
  placeholder,
  customPasswordEye,
  name,
  onChange,
  onKeyUp,
  err,
  validation,
  value,
}) {
  const [passVisible, setPassVisible] = useState(false);

  const visibleHandler = () => {
    setPassVisible(!passVisible);
  };
  return (
    <div
      className={`${classes.inputContainer} ${customPassword} ${customMargin}`}
    >
      <label>{label}</label>
      <div
        className={`${classes.passwordFlex} ${classes.passwordBorder} ${validation}`}
      >
        <input
          type={passVisible ? "text" : "password"}
          placeholder={placeholder}
          name={name}
          onChange={onChange}
          onKeyUp={onKeyUp}
          value={value}
        />
        <button
          type="button"
          className={`${classes.customPasswordEye}`}
          onClick={visibleHandler}
        >
          <FontAwesomeIcon icon={passVisible ? faEye : faEyeSlash} />
        </button>
      </div>
      {err ? (
        <span className={classes.errorMsg}>
          This {label} field is required.
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default Password;
