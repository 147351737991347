/* 
Author: Sreenivassan R
*/
import React from "react";
import Button from "./Button";
import ModalComponent from "./ModalComponent";
import classes from "./Confirmpop.module.css";
import LoadingSpinner from "./LoadingSpinner";

function Confirmpop({ yesHandler, noHandler, text, Hide, disabled,title }) {
  return (
    <ModalComponent Hide={Hide} modalComponent={classes.confirmWidth} title={`${title} ${text} ?`}>
      <div className={classes.confirmWrap}>
        <div className={classes.flex}>
          {disabled ? (
            <LoadingSpinner spinnerWidth={classes.spinnerWidth}/>
          ) : (
            <>
              <Button
                onClick={yesHandler}
                customButtonWidth={classes.buttonWidth}
                disabled={disabled}
              >
                Yes
              </Button>
              <Button
                onClick={noHandler}
                customButtonWidth={classes.buttonWidth}
                disabled={disabled}
              >
                No
              </Button>
            </>
          )}
    
        </div>
      </div>
    </ModalComponent>
  );
}

export default Confirmpop;
