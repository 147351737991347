/* 
Author: Sreenivassan R
Module: Customer List
*/
import React, { useEffect, useMemo, useState } from "react";
import classes from "./CustomerList.module.css";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";

import { fetchCustomerData } from "../../util/customer";
import DataGridDefault from "../UI/DataGridDefault";

function CustomerList() {
  const [catagoryData, setCatagoryData] = useState([]);
  const [loading, setLoading] = useState();
  const authToken = localStorage.getItem("token");

  const navigate = useNavigate();

  let columns = [
    { field: "username", headerName: "Name",flex: 1},
    { field: "mobileno", headerName: "Mobile Number",flex: 1},
    { field: "email", headerName: "Email ID" ,flex: 1},
    { field: "DOB", headerName: "DOB", flex: 1},
    { field: "DOJ", headerName: "DOJ",flex: 1},
    { field: "userlevel", headerName: "Level" ,flex: 1},
    { field: "ticketrevenue", headerName: "Ticket Revenue" ,flex: 1},
    { field: "totalDeposit", headerName: "Total Deposit",flex: 1 },
    { field: "totalWithdraw", headerName: "Total Withdraw", flex: 1},
    { field: "balance", headerName: "Balance", flex: 1},
  ];

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchCustomerData(authToken, setCatagoryData);
    }
  }, []);

  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading="Customer List"
        rows={catagoryData}
        columns={columns}
        loading={loading}
        date={false}
      />
      <Toast />
    </div>
  );
}

export default CustomerList;
