/* 
Author: Sreenivassan R
Module: Lottery Management
*/
import React, { useEffect, useRef, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import classes from "./LotteryManagement.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { fetchLotteryData, lotteryDelete } from "../../util/lottery";
import { CopyAll } from "@mui/icons-material";
import DataGridSetup from "../UI/DataGridSetup";

function LotteryManagement() {
  const [lotteryData, setLotteryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shouldGetPageLength, setShouldGetPageLength] = useState(true);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [pageLimit, setPageLimit] = useState(10);
  const [pageLength, setPageLength] = useState(0);
  const [searchValue, setSearchValue] = useState();
  const [searchBool, setSearchBool] = useState(false);
  const [searchData, setSearchData] = useState(false);

  const searchValueRef = useRef(null);
  const routerstate = useLocation();
  let permission = routerstate.state;
  const authToken = localStorage.getItem("token");
  const navigate = useNavigate();

  const notifysuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const today = new Date();
  const firstDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    1
  );
  const currentDayOfCurrentMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  const formatToISO = (date) => {
    if (!date) {
      return null; /* Return null if date is null or undefined */
    }
    return `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date
      .getDate()
      .toString()
      .padStart(2, "0")}T00:00:00`;
  };

  const startDateISO = formatToISO(firstDayOfCurrentMonth);
  const endDateISO = formatToISO(currentDayOfCurrentMonth);
  const [startDate, setStartDate] = useState(startDateISO);
  const [endDate, setEndDate] = useState(endDateISO);
  const [formateStartDate, setFormateStartDate] = useState();
  const [formateEndDate, setFormateEndDate] = useState();

  const handleStartDateChange = (date) => {
    console.log({ date });
    const formattedDate = formatToISO(date);
    setStartDate(formattedDate ?? startDateISO);
    setFormateStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = formatToISO(date);
    setEndDate(formattedDate ?? endDateISO);
    setFormateEndDate(date);
  };

  /* To Search the value from the table */
  const handleSearch = (e) => {
    setSearchValue(searchValueRef.current.value);
    setTimeout(() => {
      console.log("run");
      setSearchData(true);
      setSearchBool(true);
    }, 3000);
    setSearchBool(false);
  };

 /* To Clear the search value */
  const handleClear = () => {
    console.log({ searchValue });
    setTimeout(() => {
      setSearchData(false);
      setSearchValue(undefined);
      setSearchBool(false);
    }, 500);
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      setLoading(true);
      fetchLotteryData(
        setLotteryData,
        authToken,
        shouldGetPageLength,
        paginationModel.page,
        setPageLength,
        startDate,
        endDate,
        pageLimit,
        searchValue
      );
      setLoading(false);
    }
  }, [authToken, paginationModel, startDate, endDate, searchBool]);

 /* Handler function on Edit Icon Click */
  const handleEditClick = async (row) => {
    console.log(row);
    navigate("/Root/LotteryAddList", {
      state: { id: row.id, permission, action: "edit" },
    });
  };

  /* Handler function on Delete Icon Click */
  function handleDeleteClick(lotteryId) {
    lotteryDelete(lotteryId, authToken, notifysuccess, notifyfail);
  }

 /* Handler function on Copy Icon Click */
  function handleCopyClick(row) {
    console.log(row);
    navigate("/Root/LotteryAddList", {
      state: {
        id: row.id,
        permission,
        action: "copy",
        catagory: row.lotterycategoryname[0],
      },
    });
  }

  /* Handler function to show the contents of the Lottery Ticket by not allowing any edit Functionalities */
  const handleRowClick = async (params) => {
    console.log(params.row);
    navigate("/Root/LotteryAddList", {
      state: {
        id: params.row.id,
        permission,
        action: "view",
        catagory: params.row.lotterycategoryname[0],
      },
    });
  };

  let columns = [
    {
      field: "name",
      headerName: "Lottery Name",
      flex: 2,
      renderCell: (params, name) => (
        <div className={classes.Flex}>
          <span className={classes.lottText}>{params.row.name}</span>
          <span className={classes.idText}>{params.row.LottId}</span>
        </div>
      ),
    },
    { field: "lotterycategoryname", headerName: "Category", flex: 1 },
    {
      field: "ticketstartdatetime",
      headerName: "Date",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.DateTimeFlex}>
          <span className={classes.dateText}>
            {params.value.split("  ")[0]}
          </span>
          <span className={classes.timeText}>
            {params.value.split("  ")[1]}
          </span>
        </div>
      ),
    },
    { field: "totalprize", headerName: "Prize Amount", flex: 1 },
    { field: "cost", headerName: "Ticket Price", flex: 1 },
    { field: "lotterystatus", headerName: "Status", flex: 1 },
    {
      field: "resultdatetime",
      headerName: "Results",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.DateTimeFlex}>
          <span className={classes.dateText}>
            {params.value.split("  ")[0]}
          </span>
          <span className={classes.timeText}>
            {params.value.split("  ")[1]}
          </span>
        </div>
      ),
    },
  ];

  /* To Show the Button Icons depending upon the User Permison */
  if (permission && permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (val) => {
          var id = val.row.id;
          var row = val.row;
          let currentDateTime = new Date();
          let getDateTime = row.ticketstartdatetime;
          let formatGetDateTime = new Date(getDateTime);

          if (val.row.lotterystatus === "Completed")
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<CopyAll />}
                label="Copy"
                onClick={() => handleCopyClick(row)}
                color="inherit"
              />,
            ];
            if (val.row.lotterystatus === "Saved")
            return [
              <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => handleEditClick(row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<CopyAll />}
              label="Copy"
              onClick={() => handleCopyClick(row)}
              color="inherit"
            />,
            ];
          if (formatGetDateTime < currentDateTime) {
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                onClick={() => handleDeleteClick(id)}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<CopyAll />}
                label="Copy"
                onClick={() => handleCopyClick(row)}
                color="inherit"
              />,
            ];
          }
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => handleEditClick(row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<CopyAll />}
              label="Copy"
              onClick={() => handleCopyClick(row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission && permission.edit && !permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (lottId) => {
          var id = lottId.row.id;
          var row = lottId.row;
          console.log(row);
          let currentDateTime = new Date();
          let getDateTime = row.ticketstartdatetime;
          let formatGetDateTime = new Date(getDateTime);
          if (lottId.row.lotterystatus === "Completed")
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<CopyAll />}
                label="Copy"
                onClick={() => handleCopyClick(row)}
                color="inherit"
              />,
            ];
          if (formatGetDateTime < currentDateTime)
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<CopyAll />}
                label="Copy"
                onClick={() => handleCopyClick(row)}
                color="inherit"
              />,
            ];
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<CopyAll />}
              label="Copy"
              onClick={() => handleCopyClick(row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission && !permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (lottId) => {
          var id = lottId.row.id;
          var row = lottId.row;
          let currentDateTime = new Date();
          let getDateTime = row.ticketstartdatetime;
          let formatGetDateTime = new Date(getDateTime);
          if (lottId.row.lotterystatus === "Completed")
            return [
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<DeleteIcon />}
                label="Delete"
                className={classes.textPrimary}
                color="inherit"
              />,
              <GridActionsCellItem
                icon={<CopyAll />}
                label="Copy"
                onClick={() => handleCopyClick(row)}
                color="inherit"
              />,
            ];

          if (formatGetDateTime < currentDateTime) return [];
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className={classes.textPrimary}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<CopyAll />}
              className={classes.textPrimary}
              label="Copy"
              onClick={() => handleCopyClick(row)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }

  /* Handler Function On Create lottery Button */
  function createLottery() {
    if (permission) {
      console.log({ permission });
      navigate("/Root/LotteryAddList", { state: { permission } });
    }
  }

  /* Handler Function for Pagination */
  const handlePaginationModelChange = (newPaginationModel) => {
    const { pageSize } = newPaginationModel;
    setPageLimit(pageSize);
    setPaginationModel(newPaginationModel);
    setShouldGetPageLength(false);
  };

  return (
    <div className={classes.GridWrapper}>
      <DataGridSetup
        heading="Lottery List"
        rows={lotteryData}
        columns={columns}
        paginationModel={paginationModel}
        handlePaginationModelChange={handlePaginationModelChange}
        handleRowClick={handleRowClick}
        loading={loading}
        pageLength={pageLength}
        permission={permission}
        handleStartDateChange={handleStartDateChange}
        handleEndDateChange={handleEndDateChange}
        formateStartDate={formateStartDate}
        formateEndDate={formateEndDate}
        startdate={startDate}
        enddate={endDate}
        createFunction={createLottery}
        date={true}
        handleSearch={handleSearch}
        searchData={searchData}
        handleClear={handleClear}
        value={searchValue}
        searchValueRef={searchValueRef}
        sortField={"ticketstartdatetime"}
        fetchData={lotteryData}
        report="lottery"
      />
      <Toast />
    </div>
  );
}

export default LotteryManagement;
