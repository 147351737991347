/* 
Author: Sreenivassan R
Module: KYC List
*/
import React, { useEffect, useState } from "react";
import {GridActionsCellItem,} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import classes from "./KycList.module.css";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";

import KycDetail from "./KycDetail";
import DataGridSetup from "../UI/DataGridSetup";

function KycList() {
  const [KycData, setKycData] = useState([]);
  const [loading, setLoading] = useState();
  const [id, setId] = useState();
  const [show, setShow] = useState(false);
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  const authToken = localStorage.getItem("token");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function hideHandler() {
    setShow(false);
  }

  let columns = [
    { field: "username", headerName: "Name", width: 200 },
    { field: "aadhar", headerName: "Aadhar Card", width: 200 },
    { field: "pan", headerName: "Pan Card", width: 300 },
    { field: "image", headerName: "Selfie", width: 200 },
    { field: "updatetime", headerName: "Upload Date & Time", width: 200 },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderCell: (params) => {
        const status = params.row.status;
        let backgroundColor = "";
        let color = "";
        if (status === true) {
          backgroundColor = "#ade2ad";
          color = "#34A853";
        } else if (status === false) {
          backgroundColor = "#f4de9e";
          color = "#FFC107";
        }
        return (
          <div
            style={{
              backgroundColor,
              color,
              width: "93px",
              height: "27px",
              textAlign: "center",
              padding: "2px",
              fontFamily: "Poppins, sans-serif",
              fontSize: "12px",
              lineHeight: "23px",
              borderRadius: "4px",
            }}
          >
            {status ? "Enable" : "Disable"}
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: (val) => {
        var id = val.id;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(val.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const rows = [
    {
      id: 1,
      username: "John Doe",
      aadhar: "123456789012",
      pan: "ABCDE1234F",
      image: "selfie.jpg",
      updatetime: "2024-03-07 10:30:00",
    },
    {
      id: 2,
      username: "Jane Smith",
      aadhar: "987654321098",
      pan: "FGHIJ5678K",
      image: "selfie2.jpg",
      updatetime: "2024-03-07 11:45:00",
    },
  ];

  const fetchKycData = async () => {
    // try {
    //   const response = await fetch("", {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: `Bearer ${authToken}`,
    //     },
    //   });
    //   const responseData = await response.json();
    //   if (!response.ok) {
    //     throw new Error(
    //       responseData.message || "Failed to submit data to the server"
    //     );
    //   }
    //   var i = 1;
    //   console.log("Customer", responseData.data);
    //   const KycData = responseData.data.map((item, index) => {
    //     return {
    //       uniqueId: `row_${index}`,
    //     };
    //   });
    //   setKycData(KycData);
    //   console.log("Data submitted successfully:", KycData);
    //   const KycDataWithSelection = KycData.map((row) => ({
    //     ...row,
    //     isSelected: false,
    //   }));
    //   setKycData(KycDataWithSelection);
    // } catch (error) {
    //   console.error("Error submitting data:", error.message);
    // }
  };

  const handleEditClick = (row) => {
    setId(row.id);

    setShow(true);
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    const isConfirmed = window.confirm("Do You Want to Delete this KYC?");
    if (isConfirmed) {
      try {
        const response = await fetch("", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({ id: id, mode: "delete" }),
        });
        console.log("response categery", response);

        if (response.status === 200) {
          notifysuccess();
          window.location.reload();
        }
        if (
          response.status === 413 ||
          response.status === 500 ||
          response.status === 404
        ) {
          notifyfail();
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error
      }
    }
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchKycData();
    }
  }, []);

  return (
    <div className={classes.GridWrapper}>
      <DataGridSetup
        heading="KYC List"
        rows={rows}
        columns={columns}
        loading={loading}
        permission={permission}
        date={false}
      />
      {show && (
        <KycDetail
          id={id}
          notifysuccess={notifysuccess}
          notifyfail={notifyfail}
          Hide={hideHandler}
        />
      )}
      <Toast />
    </div>
  );
}

export default KycList;
