import { memo } from 'react';

import resets from '../_resets.module.css';
import { Delete11Icon } from './Delete11Icon.js';
import classes from './Deleted.module.css';

const Deleted = memo(function Deleted(props = {}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.label}>
        <div className={classes.delete11}>
          <Delete11Icon className={classes.icon} />
        </div>
        <div className={classes.deleted}>Deleted</div>
      </div>
    </div>
  );
});

export { Deleted };
