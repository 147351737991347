/* 
Author: Sreenivassan R
Module: Privacy Management
*/
import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "../ModalDetails.module.css";
import { fetchTermsData } from "../../util/policy";
import PrivacyCreateModal from "./PrivacyCreateModal";
import DataGridDefault from "../UI/DataGridDefault";

function PrivacyManagement() {
  const [termsGet, setTermsGet] = useState([]);
  const [termsGetEdit, setTermsGetEdit] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState();

  const authToken = localStorage.getItem("token");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      fetchTermsData(authToken, setTermsGet, setLoading);
    }
  }, []);

  let columns = [
    {
      field: "datetime",
      headerName: "Date",
      width: 300,
      renderCell: (params) => (
        <div className={classes.DateTimeFlex}>
          <span className={classes.dateText}>
            {params.value.split("  ")[0]}
          </span>
          <span className={classes.timeText}>
            {params.value.split("  ")[1]}
          </span>
        </div>
      ),
    },
    {
      field: "html",
      headerName: "Description",
      width: 1000,
      renderCell: (params) => <div dangerouslySetInnerHTML={params.value} />,
    },

    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: (val) => {
        var id = val.id;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(val.row)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleEditClick = (row) => {
    console.log(row);
    setId(row._id);
    setTermsGetEdit(row);
    setShow(true);
  };

  function termsAddHandler() {
    setTermsGetEdit("");
    setShow(true);
  }
  function hideHandler() {
    setShow(false);
  }
  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
      heading="Privacy Policy"
      rows={termsGet}
      columns={columns}
      loading={loading}
      permission={permission}
      date={false}
      createFunction={termsAddHandler}
      />

      {show && (
        <PrivacyCreateModal
          id={id}
          updatePrivacyDataGet={termsGetEdit}
          notifysuccess={notifysuccess}
          notifyfail={notifyfail}
          Hide={hideHandler}
        />
      )}
      <Toast />
    </div>
  );
}

export default PrivacyManagement;
