/* 
Author: Sreenivassan R
Module: OTP
*/
import React, { Fragment, useEffect, useRef, useState } from "react";
import UserFirstScreen from "../UI/UserFirstScreen";
import UserDetailScreen from "../UI/UserDetailScreen";
import classes from "../common.module.css";
import otpclasses from "./Otp.module.css";
import Button from "../UI/Button";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoadingSpinner from "../UI/LoadingSpinner";
import { otpHandler } from "../../util/otp";
function Otp() {
  const navigate = useNavigate();
  const state = useLocation().state;
  const params = useParams();
  console.log(params, "state");
  const [otp, setOtp] = useState({ one: "", two: "", three: "", four: "" });
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];

  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

function verifyOTP(){
  otpHandler(params, otp, setIsLoading, state, navigate, setError)
}
  
  const handleKeyPress = (event, index) => {
    event.preventDefault()
    if (event.key === "Enter") {
      verifyOTP();
    }
  };
  const focusNext = (index, value) => {
    console.log(`Value entered in field ${index + 1}: ${value}`);
    if (index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleChange = (e, index) => {
    const input = e.target;
    const value = input.value;
    if (value.length === 1) {
      setOtp((prevState) => ({
        ...prevState,
        [input.name]: value,
      }));
      focusNext(index, value);
    }
  };

  useEffect(() => {
    const otpValue = Object.values(otp).join("");
    console.log("OTP entered:", otpValue);
  }, [otp]);

  return (
    <Fragment>
      <div className={classes.flexScreen}>
        <div className={classes.gridScreen}>
          <UserFirstScreen />
          <div className={classes.gridRight}>
          <UserDetailScreen>
                <div>
                  <div className={classes.loginContainer}>
                    <div
                      className={`${classes.userContents} ${otpclasses.contentUsers}`}
                    >
                      <h2>Enter Code</h2>
                      <p>We’ve sent an SMS with an OTP code to your email</p>
                    </div>

                    <div className={otpclasses.otpGrid}>
                      {inputRefs.map((inputRef, index) => (
                        <input
                          key={index}
                          type="number"
                          maxLength="1"
                          min={0}
                          name={`digit-${index + 1}`}
                          ref={inputRef}
                          onChange={(e) => handleChange(e, index)}
                          onKeyUp={handleKeyPress}
                          autoFocus={index === 0}
                        />
                      ))}
                    </div>

                    {error && <p className={otpclasses.otpError}>{error}</p>}
                    <div className={otpclasses.buttonStyle}>
                      <Button type="button" onClick={verifyOTP}>  {isLoading ? <LoadingSpinner/>  : 'Verify'}</Button>
                    </div>
                  </div>
                </div>
              </UserDetailScreen>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Otp;
