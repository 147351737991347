/* 
Author: Sreenivassan R
*/
const ADMINURL = process.env.REACT_APP_ADMINURL ;
export const forgotHandler = async function SendOTP (setIsLoading, email, navigate, setError) {
    try {
      setIsLoading(true);
      const response = await fetch(
        ADMINURL+"/admin/forgetpassword",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      setIsLoading(false);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      if (responseData.sts === "0") {
        navigate("/otp/" + responseData.data.id, {
          state: { id: responseData.data.id, email: responseData.data.email },
        });
      } else {
        setError(responseData.msg);
      }
    } catch (error) {
      console.error("Error occurred while sending OTP:", error);
      setError(error);
    }
  };