/* 
Author: Sreenivassan R
*/
import React, { useEffect, useRef, useState } from "react";
import classes from "./FileUploadTwo.module.css";
import pngImage from "../../assetes/images/imagePNG.png";
import jpgImage from "../../assetes/images/imageJPG.png";
import deleteimage from "../../assetes/images/deleteImage.png";
import ModalComponent from "./ModalComponent";

function FileUploadTwo({
  label,
  name,
  setFormData,
  imgurl,
  signurl,
  logourl,
  imageArea,
  profileImageWidth,
  paddingfileUploadroot,
  disabled,
  imageType,
  asterisk,
  setErr,
  err,
}) {
  const [profileImage, setProfileImage] = useState(imgurl);
  const [signImage, setSignImage] = useState(signurl);
  const [cardImage, setCardImage] = useState(logourl);
  const [profileFileName, setProfileFileName] = useState("");
  const [signFileName, setSignFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [imageShow, setImageShow] = useState();
  const [imgError, setImgError] = useState("");
  const fileInputRef = useRef(null);
  const signInputRef = useRef(null);
  const cardInputRef = useRef(null);

  function handleDateChange(value) {
    // Update the form data state
    setFormData((prevState) => ({
      ...prevState, // Spread the previous state to maintain other form data fields
      // Conditional logic to determine which key in formData to update:
      // 1. If imageType is "profile", use the 'name' variable as the key.
      // 2. If imageType is "signature", use the key "signature".
      // 3. Otherwise, assume the imageType is "logo" and use "logo" as the key.
      [imageType === "profile"
        ? name
        : imageType === "signature"
        ? "signature"
        : "logo"]: value,
    }));
  }

  /* To Remove Image */
  function handleDeleteFile(imageType) {
    if (imageType === "profile") {
      setProfileImage(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        img: "",
      }));
    } else if (imageType === "sign") {
      setSignImage(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        signature: "",
      }));
    } else if (imageType === "logo") {
      setCardImage(null);
      setFormData((prevFormData) => ({
        ...prevFormData,
        logo: "",
      }));
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    } else if (signInputRef.current) {
      signInputRef.current.value = null;
    }
    setImgError(false);
  }

  /* To get the image on Drop */
  const handleDrop = (event, type) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleImageChange({ target: { files } }, type);
    }
  };

  /* To make the contanier Dragable */
  const handleDragOver = (event) => {
    event.preventDefault();
  };

  /* To make the contanier Dragable */
  const handleClick = (imageType, ref, files) => {
    if (ref && ref.current) {
      if (imageType === "profile") {
        fileInputRef.current.click();
      } else if (imageType === "profile") {
        signInputRef.current.click();
      } else {
        cardInputRef.current.click();
      }
    }
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    /* Early return if no file is selected */
    if (!selectedImage) {
      return;
    }

    const sizeInMB = (selectedImage.size / (1024 * 1024)).toFixed(2);
    const imgType = selectedImage.type;
    const extension = imgType.split("/")[1];

    setFileExtension(extension);
    setSignFileName(
      selectedImage.name
    ); /* Use the file name instead of the file object */
    setFileSize(sizeInMB);
    setProfileFileName(
      selectedImage.name
    ); /* Use the file name instead of the file object */
    console.log(extension);

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      if (imageType === "profile") {
        setProfileImage(base64String);
      } else if (imageType === "signature") {
        setSignImage(base64String);
      } else {
        setCardImage(base64String);
      }
      handleDateChange(base64String);
    };

    if (sizeInMB > 2) {
      setImgError("File size must be less than 2MB");
      return;
    }

    /* Read the file as a base64 encoded string */
    reader.readAsDataURL(selectedImage);
    if (selectedImage) {
      {
        setErr &&
          setErr((prev) => ({
            ...prev,
            [name]: false, // Clear error for lotterycategoryid
          }));
      }
    }
  };

  function imageViewHandler() {
    setImageShow(true);
  }

  const hideHandler = () => {
    setImageShow(false);
  };

  useEffect(() => {
    if (imageType === "profile") {
      setProfileImage(imgurl);
    } else if (imageType === "signature") {
      setSignImage(signurl);
    } else {
      setCardImage(logourl);
    }
  }, [imgurl, signurl, logourl, imageType]);

  return (
    <div className={classes.fileWrap}>
      <div className={classes.customInputFlex}>
        <label>{label}</label>
        {asterisk && <span>*</span>}
      </div>
      <div className={`${classes.frame19} ${paddingfileUploadroot}`}>
        {imageType === "profile" && !profileImage ? (
          <>
            {!disabled && (
              <div
                className={classes.frame15}
                onClick={() => handleClick(fileInputRef)}
                onDrop={(e) => handleDrop(e, "profile")}
                onDragOver={handleDragOver}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleImageChange}
                  accept="image/png"
                />
                <div className={classes.helpText}>
                  <p>Drag Files here or Upload</p>
                  {!imgError && <p>Upload upto 2Mb</p>}
                  {imgError && <p className={classes.errorImage}>{imgError}</p>}
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {imageType === "signature" && !signImage ? (
          <>
            {!disabled && (
              <div
                className={classes.frame15}
                onClick={() => handleClick(signInputRef)}
                onDrop={(e) => handleDrop(e, "signature")}
                onDragOver={handleDragOver}
              >
                <input
                  type="file"
                  ref={signInputRef}
                  onChange={handleImageChange}
                  accept="image/png"
                />
                <div className={classes.helpText}>
                  <p>Drag Files here or Upload</p>
                  {!imgError && <p>Upload upto 2Mb</p>}
                  {imgError && <p className={classes.errorImage}>{imgError}</p>}
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {imageType === "logo" && !cardImage ? (
          <>
            {!disabled && (
              <div
                className={classes.frame15}
                onClick={() => handleClick(signInputRef)}
                onDrop={(e) => handleDrop(e, "signature")}
                onDragOver={handleDragOver}
              >
                <input
                  type="file"
                  ref={signInputRef}
                  onChange={handleImageChange}
                  accept="image/png"
                />
                <div className={classes.helpText}>
                  <p>Drag Files here or Upload</p>
                  {!imgError && <p>Upload upto 2Mb</p>}
                  {imgError && <p className={classes.errorImage}>{imgError}</p>}
                </div>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {profileImage && (
          <div className={`${classes.image_area} ${imageArea}`}>
            <div onClick={imageViewHandler} className={classes.imageViewArea}>
              <img
                src={fileExtension === "jpeg" ? jpgImage : pngImage}
                className={`${classes.UserprofieImage} ${profileImageWidth}`}
                alt="FileType"
              />
              <p>
                {" "}
                {profileFileName && (
                  <>
                    {fileSize && (
                      <>
                        <div className={classes.fileName}>
                          {profileFileName}
                        </div>
                        <p>{`${fileSize}MB`}</p>
                        <p>Click Here to Preview</p>
                      </>
                    )}
                  </>
                )}
              </p>
            </div>

            <button onClick={() => handleDeleteFile("profile")} type="button">
              <img
                src={deleteimage}
                className={`${classes.UserprofieImage} ${profileImageWidth}`}
                alt="FileType"
              />
            </button>
          </div>
        )}

        {signImage && (
          <div className={`${classes.image_area} ${imageArea}`}>
            <div onClick={imageViewHandler} className={classes.imageViewArea}>
              <img
                src={fileExtension === "jpeg" ? jpgImage : pngImage}
                className={`${classes.UserprofieImage} ${profileImageWidth}`}
                alt="FileType"
              />
              <p>
                {" "}
                {signFileName && (
                  <>
                    {fileSize && (
                      <>
                        <div className={classes.fileName}>{signFileName}</div>
                        <p>{`${fileSize}MB`}</p>
                        <p>Click Here to Preview</p>
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            {asterisk && (
              <button onClick={() => handleDeleteFile("sign")} type="button">
                <img
                  src={deleteimage}
                  className={`${classes.UserprofieImage} ${profileImageWidth}`}
                  alt="FileType"
                />
              </button>
            )}
          </div>
        )}

        {cardImage && (
          <div className={`${classes.image_area} ${imageArea}`}>
            <div onClick={imageViewHandler} className={classes.imageViewArea}>
              <img
                src={fileExtension === "jpeg" ? jpgImage : pngImage}
                className={`${classes.UserprofieImage} ${profileImageWidth}`}
                alt="FileType"
              />
              <p>
                {" "}
                {signFileName && (
                  <>
                    {fileSize && (
                      <>
                        <div className={classes.fileName}>{signFileName}</div>
                        <p>{`${fileSize}MB`}</p>
                        <p>Click Here to Preview</p>
                      </>
                    )}
                  </>
                )}
              </p>
            </div>
            {asterisk && (
              <button onClick={() => handleDeleteFile("logo")} type="button">
                <img
                  src={deleteimage}
                  className={`${classes.UserprofieImage} ${profileImageWidth}`}
                  alt="FileType"
                />
              </button>
            )}
          </div>
        )}

        {imageShow && (
          <ModalComponent Hide={hideHandler}>
            {signImage && (
              <div className={classes.imageAlignment}>
                <img
                  src={signImage}
                  className={`${classes.UserprofieImage} ${profileImageWidth}`}
                  alt="Signature"
                />
              </div>
            )}
            {profileImage && (
              <div className={classes.imageAlignment}>
                <img
                  src={profileImage}
                  className={`${classes.UserprofieImage} ${profileImageWidth}`}
                  alt="Profile"
                />
              </div>
            )}
            {cardImage && (
              <div className={classes.imageAlignment}>
                <img
                  src={cardImage}
                  className={`${classes.UserprofieImage} ${profileImageWidth}`}
                  alt="Logo"
                />
              </div>
            )}
          </ModalComponent>
        )}

      </div>
      {err ? (
        <span className={classes.errorMsg}>
          This {label} field is required.
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

export default FileUploadTwo;
