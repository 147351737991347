/* 
Author: Sreenivassan R, Manickam S
*/
import React, { Fragment, useEffect, useMemo, useState } from "react";
import classes from "./LotteryUserManagementAdd.module.css";
import CustomInput from "../UI/CustomInput";
import DropDown from "../UI/DropDown";
import MobileInput from "../UI/MobileInput";
import Password from "../UI/Password";
import Button from "../UI/Button";
import SingleDate from "../SingleDate";
import FileUpload from "../UI/FileUpload";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "../Toast";
import { toast } from "react-toastify";
import { getAdmin, roleApi, stateApi } from "../../util/userManagement";
import { stateDropDown } from "../../util/dropDown";
import MultiSelectDropDown from "../UI/MultiSelectDropDown";
const ADMINURL = process.env.REACT_APP_ADMINURL;
function validateInput(input) {
  /* Remove non-alphabetic characters */
  input.target.value = input.target.value.replace(/[^a-zA-Z]/g, "");

  /* Limit the input length to 50 characters */
  if (input.target.value.length > 50) {
    input.target.value = input.target.value.slice(0, 50);
  }
}

const today = new Date(); /* Get today's date */
const eighteenYearsAgo = new Date(
  today
); /*  Create a new date object from today */
eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
var currentdob = new Date(eighteenYearsAgo).toISOString();
function LotteryUserManagementAdd() {
  const navigate = useNavigate();
  const [role, setRole] = useState([]);
  const [stateProvince, setstateProvince] = useState([]);
  const [optionState, setOptionState] = useState([]);
  const [optionStateName, setOptionStateName] = useState([]);
  const authToken = localStorage.getItem("token");
  const userData = useLocation();
  let AdminId = userData.state.id;

  let userPermission = userData.state.permission;
  const [passwdchk, setPasswdchk] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [prevpass, setPrevPass] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    DOB: currentdob,
    gender: "",
    empId: "",
    roleId: "",
    nationality: "",
    adhaarno: "",
    address: "",
    city: "",
    country: "",
    state: "",
    pincode: "",
    token: "",
    email: "",
    mobileno: "",
    emgcontactname: "",
    emgcontactnumber: "",
    username: "",
    password: "",
    img: "",
    prevpassword: "",
    imgurl: "",
    statue: true,
    Confirmpassword: "",
    stateaccess: [],
  });
  const [err, setErr] = useState({
    name: false,
    DOB: false,
    gender: false,
    empId: false,
    roleId: false,
    nationality: false,
    adhaarno: false,
    address: false,
    city: false,
    country: false,
    state: false,
    pincode: false,
    email: false,
    emgcontactname: false,
    emgcontactnumber: false,
    mobileno: false,
    username: false,
    password: false,
    Confirmpassword: false,
  });

  const notifysuccess = (msg) =>
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = (msg) =>
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  /* To Validate Email */
  function validateEmail(email) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (!authToken) {
        navigate("/");
      } else {
        try {
          const roleoptions = await roleApi(authToken);
          const stateoption = await stateApi(authToken);
          setRole(roleoptions);
          setstateProvince(stateoption);
          if (AdminId) {
            var adm = await getAdmin(AdminId, authToken);
            setFormData({ ...adm, Confirmpassword: adm.password });
            setPasswdchk([true, true, true, true, true]);
            setPrevPass(adm.password);
            console.log("formData", formData);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };
    fetchData();
  }, [authToken, AdminId]);

  const stateAccesses = (selectedValue) => {
    const optionStateArray = Array.isArray(optionState) ? optionState : [];

    const selectedValues = optionStateArray.includes(selectedValue)
      ? optionStateArray.filter((val) => val !== selectedValue)
      : [...optionStateArray, selectedValue];

    setOptionState(selectedValues);

    /*  Update formData.stateaccess */
    setFormData((prevFormData) => ({
      ...prevFormData,
      stateaccess: selectedValues,
    }));
  };

  useEffect(() => {
    if (stateProvince && Array.isArray(formData.stateaccess)) {
      const selectedStateNames = stateProvince
        .filter((state) => formData.stateaccess.includes(state._id))
        .map((state) => state.statename);

      setOptionState(formData.stateaccess);
      setOptionStateName(selectedStateNames);
    }
  }, [formData.stateaccess]);

  const dataHandler = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }
  };

  /*  To Validate Password Strength */
  const passwordcheckFn = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }
    var newcheck = [];
    const checkOneCapitalRegax = /[A-Z]/;
    newcheck.push(checkOneCapitalRegax.test(value));
    const checkOneSmallRegax = /[a-z]/;
    newcheck.push(checkOneSmallRegax.test(value));
    const checkOneNumberRegax = /\d/;
    newcheck.push(checkOneNumberRegax.test(value));
    const checkOneSplRegax = /[!@#$%^&*(),.?":{}|<>]/;
    newcheck.push(checkOneSplRegax.test(value));
    newcheck.push(value.length >= 8 ? true : false);
    newcheck.sort((a, b) => {
      if (a === b) {
        return 0;
      }
      if (a === true) {
        return -1;
      }
      return 1;
    });
    setPasswdchk(newcheck);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData, "submitdata");
    var check = 1;
    Object.keys(err).map((errname) => {
      if (prevpass === formData["password"]) {
        if (
          (AdminId && !["password", "Confirmpassword"].includes(errname)) ||
          !AdminId
        ) {
          if (
            typeof formData[errname] !== "undefined" &&
            ((typeof formData[errname] === "string" &&
              formData[errname].trim() === "") ||
              formData[errname] == "0")
          ) {
            check = 0;
            console.log(errname, "err");
            console.log(err);
            setErr((prev) => ({ ...prev, [errname]: true }));
          }
        }
      } else {
        if (
          typeof formData[errname] !== "undefined" &&
          ((typeof formData[errname] === "string" &&
            formData[errname].trim() === "") ||
            formData[errname] == "0")
        ) {
          check = 0;
          console.log(errname, "err");
          console.log(err);
          setErr((prev) => ({ ...prev, [errname]: true }));
        }
      }
    });
    if (!check) {
      return false;
    }
    /* Check Mobile Number Length */
    if (String(formData["mobileno"]).length !== 10) {
      notifyfail("Mobile No. should have 10 Digits....");
      setErr((prev) => ({ ...prev, mobileno: true }));
      return;
    }
    if (String(formData["emgcontactnumber"]).length !== 10) {
      notifyfail("Mobile No. should have 10 Digits....");
      setErr((prev) => ({ ...prev, emgcontactnumber: true }));
      return;
    }
    if (
      prevpass !== formData["password"] &&
      passwdchk.some((err) => err === false)
    ) {
      notifyfail("Password Strength check Failed..");
      setErr((prev) => ({ ...prev, Confirmpassword: true, password: true }));
      return;
    }
    if (
      !prevpass !== formData["password"] &&
      formData["password"] !== formData["Confirmpassword"]
    ) {
      notifyfail("Password Mismatch..");
      setErr((prev) => ({ ...prev, Confirmpassword: true, password: true }));
      return;
    }
    if (!validateEmail(formData.email)) {
      notifyfail("Invalid Email.");
      setErr((prev) => ({ ...prev, email: true }));
      return;
    }
    try {
      if (!AdminId) {
        var postdata = { ...formData, mode: "insert" };
      } else {
        if (prevpass === formData["password"]) {
          var { __v, token, confirmparrword, ...remaindata } = formData;
          var postdata = { ...remaindata, mode: "update", id: AdminId };
        } else {
          var { __v, token, ...remaindata } = formData;
          var postdata = { ...remaindata, mode: "update", id: AdminId };
        }
      }
      const response = await fetch(ADMINURL + "/admin/save", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(postdata),
      });
      const responseData = await response.json();
      console.log("loteryList", responseData);

      if (!response.ok) {
        throw new Error(
          responseData.message || "Failed to submit data to the server"
        );
      }
      if (response.status === 200) {
        if (responseData.sts === "1") {
          notifyfail(responseData.msg);
        } else {
          notifysuccess("Success");
          setTimeout(() => {
            navigate("/Root/LotteryUserAdd", { state: userPermission });
          }, 1000);
        }
      }
    } catch (error) {
      notifyfail(error.message);
    }
  };
  const genderDropDown = [
    { _id: "male", gender: "Male" },
    { _id: "female", gender: "Female" },
    { _id: "others", gender: "Others" },
  ];
  const nationalityDropDown = [{ _id: "Indian", nationality: "Indian" }];
  const countryDropDown = [{ _id: "India", country: "India" }];
  const title = AdminId ? "Edit" : "Create";

  function cancelHandler() {
    navigate("/Root/LotteryUserAdd", { state: userPermission });
  }

  return (
    <Fragment>
      <div className={classes.AddUserWrapper}>
        <h4 className={classes.titleText}>{title} User</h4>
        <form onSubmit={handleSubmit}>
          <div className={classes.UserAddFlex}>
            <div className={classes.UserAddRight}>
              <div className={classes.UserAddCommon}>
                <CustomInput
                  label="Full Name"
                  type="name"
                  name="name"
                  placeholder="Full Name"
                  customInput={
                    err.name ? classes.errorInput : classes.inputBorder
                  }
                  value={formData.name}
                  onChange={dataHandler}
                  err={err.name}
                  onInput={(e) => validateInput(e)}
                />
                <div>
                  <label className={classes.titleLable}>Date Of Birth</label>
                  <SingleDate
                    name="DOB"
                    value={formData.DOB}
                    dateHandler={dataHandler}
                    setFormData={setFormData}
                  />
                </div>
                <DropDown
                  label="Gender"
                  options={genderDropDown}
                  name="gender"
                  field="gender"
                  value={formData.gender}
                  dateHandler={dataHandler}
                  setFormData={setFormData}
                  validation={err.gender ? classes.errorInput : ""}
                  err={err.gender}
                />
                <CustomInput
                  label="Employee ID"
                  type="text"
                  name="empId"
                  placeholder="Employee ID"
                  customInput={
                    err.empId ? classes.errorInput : classes.inputBorder
                  }
                  value={formData.empId}
                  onChange={dataHandler}
                  err={err.empId}
                />
                <MultiSelectDropDown
                  label="State Accesses"
                  placeholder="State Accesses"
                  options={stateProvince}
                  dropDown={classes.dropOne}
                  value={optionStateName}
                  onChange={(selectedValue) => stateAccesses(selectedValue)}
                  setFormData={setFormData}
                  field="statename"
                  validation={
                    err.state ? classes.errorInput : classes.inputBorder
                  }
                  err={err.state}
                />
                <DropDown
                  label="Role"
                  placeholder="Role"
                  options={role}
                  name={{
                    name: "role",
                    id: "roleId",
                  }}
                  value={formData.roleId}
                  dateHandler={dataHandler}
                  setFormData={setFormData}
                  field="role"
                  err={err.roleId}
                  validation={err.roleId ? classes.errorInput : ""}
                />
                <DropDown
                  label="Nationality"
                  placeholder="Nationality"
                  options={nationalityDropDown}
                  name="nationality"
                  value={formData.nationality}
                  dateHandler={dataHandler}
                  setFormData={setFormData}
                  field="nationality"
                  err={err.nationality}
                  validation={err.nationality ? classes.errorInput : ""}
                  setErr={setErr}
                />
                <CustomInput
                  label="Aadhaar Number"
                  type="number"
                  placeholder="Aadhaar Number"
                  err={err.adhaarno}
                  name="adhaarno"
                  customInput={
                    err.adhaarno ? classes.errorInput : classes.inputBorder
                  }
                  maxLength={12}
                  value={formData.adhaarno}
                  onChange={dataHandler}
                />
              </div>
              <div
                className={`${classes.UserAddCommon} ${classes.secondContainer}`}
              >
                <CustomInput
                  label="Address"
                  type="text"
                  placeholder="Address"
                  name="address"
                  customInput={
                    err.address ? classes.errorInput : classes.inputBorder
                  }
                  value={formData.address}
                  onChange={dataHandler}
                  err={err.address}
                />
                <div className={classes.AddFlex}>
                  <CustomInput
                    label="City"
                    type="name"
                    placeholder="City"
                    name="city"
                    customInput={
                      err.city ? classes.errorInput : classes.inputBorder
                    }
                    value={formData.city}
                    onChange={dataHandler}
                    err={err.city}
                  />
                  <DropDown
                    label="State"
                    placeholder="State"
                    name="state"
                    options={stateDropDown}
                    value={formData.state}
                    dropDown={classes.dropDownState}
                    dateHandler={dataHandler}
                    setFormData={setFormData}
                    field="state"
                    err={err.state}
                    validation={err.state ? classes.errorInput : ""}
                    setErr={setErr}
                  />
                </div>
                <div className={classes.AddFlex}>
                  <DropDown
                    label="Country"
                    placeholder="Country"
                    name="country"
                    options={countryDropDown}
                    value={formData.country}
                    dateHandler={dataHandler}
                    setFormData={setFormData}
                    field="country"
                    err={err.country}
                    validation={err.country ? classes.errorInput : ""}
                    setErr={setErr}
                  />
                  <CustomInput
                    label="Pincode"
                    type="number"
                    placeholder="Pincode"
                    name="pincode"
                    customInput={
                      err.pincode ? classes.errorInput : classes.inputBorder
                    }
                    maxLength={6}
                    value={formData.pincode}
                    onChange={dataHandler}
                    err={err.pincode}
                  />
                </div>
              </div>
            </div>
            <div className={classes.UserAddRight}>
              <div
                className={`${classes.UserAddCommon} ${classes.fileContainer}`}
              >
                <FileUpload
                  label="Upload Image"
                  name="img"
                  imgurl={formData.imgurl}
                  imgname={formData.img}
                  setFormData={setFormData}
                  AdminId={AdminId}
                  imageType="profile"
                />
              </div>
              <div className={classes.UserAddCommon}>
                <CustomInput
                  label="Email Address"
                  type="email"
                  placeholder="Email Address"
                  customInput={
                    err.email ? classes.errorInput : classes.inputBorder
                  }
                  name="email"
                  value={formData.email}
                  onChange={dataHandler}
                  err={err.email}
                />
                <MobileInput
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  name="mobileno"
                  value={formData.mobileno}
                  onChange={dataHandler}
                  validation={err.mobileno ? classes.errorInput : ""}
                  err={err.mobileno}
                />
                <CustomInput
                  label="Emergency Contact Name"
                  type="name"
                  name="emgcontactname"
                  placeholder="Emergency Contact Name"
                  customInput={
                    err.emgcontactname
                      ? classes.errorInput
                      : classes.inputBorder
                  }
                  value={formData.emgcontactname}
                  err={err.emgcontactname}
                  onChange={dataHandler}
                  onInput={(e) => validateInput(e)}
                />
                <MobileInput
                  label="Emergency Number"
                  placeholder="Emergency Number"
                  name="emgcontactnumber"
                  value={formData.emgcontactnumber}
                  onChange={dataHandler}
                  validation={err.emgcontactnumber ? classes.errorInput : ""}
                  err={err.emgcontactnumber}
                  onInput={(e) => {
                    const inputValue = e.target.value.trim();
                    const maxLength = 10;
                    if (inputValue.length > maxLength) {
                      e.target.value = inputValue.slice(0, maxLength);
                    }
                  }}
                />
              </div>
              <div
                className={`${classes.UserAddCommon} ${classes.secondContainer}`}
              >
                <CustomInput
                  label="User Name"
                  type="text"
                  placeholder="User Name"
                  err={err.username}
                  customInput={
                    err.username ? classes.errorInput : classes.inputBorder
                  }
                  name="username"
                  value={formData.username}
                  onChange={dataHandler}
                />
                <Password
                  label="Password"
                  placeholder="Password"
                  customPassword={classes.passwordInput}
                  customPasswordEye={classes.PasswordEye}
                  value={formData.password}
                  name="password"
                  onChange={passwordcheckFn}
                  validation={err.password ? classes.errorInput : ""}
                  disabled={AdminId ? true : false}
                  err={AdminId ? false : err.password}
                />
                <div className={classes.root}>
                  <div className={classes.passwordContainMin8Characters1}>
                    <div className={classes.textBlock}>
                      Password contain min 8 characters,
                    </div>
                    <div className={classes.textBlock}>
                      1 Upper case, 1 Lower case, 1 Numerical &amp; 1 Special
                      character
                    </div>
                  </div>
                  <div className={classes.frame39352}>
                    {passwdchk.map((chk, index) => {
                      var classname = chk
                        ? classes[`rectangle${index + 1}_fill`]
                        : classes[`rectangle${index + 1}_emp`];
                      return <div className={classname}></div>;
                    })}
                  </div>
                </div>
                <Password
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  customPasswordEye={classes.PasswordEye}
                  customPassword={classes.passwordInput}
                  name="Confirmpassword"
                  value={formData.Confirmpassword}
                  validation={err.Confirmpassword ? classes.errorInput : ""}
                  onChange={dataHandler}
                  disabled={AdminId ? true : false}
                  err={AdminId ? false : err.Confirmpassword}
                />
              </div>
              <div className={classes.AddLotteryButtonFlex}>
                <div className={classes.AddLotteryButton}>
                  <Button
                    customButton={classes.buttonOutline}
                    customButtonWidth={classes.buttonWidth}
                    type="button"
                    onClick={cancelHandler}
                  >
                    Cancel
                  </Button>
                  <Button customButtonWidth={classes.buttonWidth}>Save</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Toast />
    </Fragment>
  );
}

export default LotteryUserManagementAdd;
