/* 
Author: Sreenivassan R
Module: Notification Management
*/
import React, { useEffect, useMemo, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import classes from "./NotificationMangemnet.module.css";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationCreateModal from "./NotificationCreateModal";
import { fetchNotifyData, notificationDelete } from "../../util/notification";
import DataGridDefault from "../UI/DataGridDefault";

function NotificationMangement() {
  const [notifyDataGet, setnotifyDataGet] = useState([]);
  const [bannerDataGetEdit, setbannerDataGetEdit] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState();

  const authToken = localStorage.getItem("token");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      setLoading(true);
      fetchNotifyData(setnotifyDataGet, authToken);
    }
    setLoading(false);
  }, []);

  let columns = [
    { field: "title", headerName: "Title", flex: 2 },
    { field: "body", headerName: "Description", flex: 3 },
    {
      field: "notificationType",
      headerName: "Notification Type",
      flex: 1,
    },
    {
      field: "group",
      headerName: "Group",
      flex: 1,
    },
    {
      field: "scheduledTime",
      headerName: "Schedule Time",
      flex: 1,
      renderCell: (params) => (
        <div className={classes.DateTimeFlex}>
          <span className={classes.dateText}>
            {params.value.split("  ")[0]}
          </span>
          <span className={classes.timeText}>
            {params.value.split("  ")[1]}
          </span>
        </div>
      ),
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      flex: 1,
      cellClassName: "actions",
     /* Validating today's date to the  End Date to show Edit and Delete */
      getActions: (val) => {
        var id = val.id;
        let date = val.row.scheduledTime;
        let endDate = new Date(date);
        let today = new Date();
        if (today > endDate) {
          return [
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className={classes.textPrimary}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              className={classes.textPrimary}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(val.row)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

 /* Handler function on Edit Icon Click */
  const handleEditClick = (row) => {
    console.log(row);
    setId(row._id);
    setbannerDataGetEdit(row);
    setShow(true);
  };

 /* Handler function on Delete Icon Click */
  function handleDeleteClick(id) {
    notificationDelete(id, authToken, notifysuccess, notifyfail);
  }

  /* Handler function to show the Notification Create Modal */
  function notifyAddHandler() {
    setId("");
    setbannerDataGetEdit("");
    setShow(true);
  }

  /* Handler function to hide the Notification Modal */
  function hideHandler() {
    setShow(false);
  }

  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading="Notification List"
        rows={notifyDataGet}
        columns={columns}
        permission={permission}
        loading={loading}
        createFunction={notifyAddHandler}
        sortField={"scheduledTime"}
        lists={notifyDataGet}
        report="notification"
      />
      {show && (
        <NotificationCreateModal
          id={id}
          updateNotificationDataGet={bannerDataGetEdit}
          notifysuccess={notifysuccess}
          notifyfail={notifyfail}
          Hide={hideHandler}
        />
      )}
      <Toast />
    </div>
  );
}

export default NotificationMangement;
