import { memo } from 'react';

import resets from '../_resets.module.css';
import classes from './ByUserName.module.css';
import { UserScan1Icon } from './UserScan1Icon.js';

const ByUserName = memo(function ByUserName( {username}) {
  return (
    <div className={`${resets.clapyResets} ${classes.root}`}>
      <div className={classes.label}>
        <div className={classes.userScan1}>
          <UserScan1Icon className={classes.icon} />
        </div>
        <div className={classes.byUserName}>by {username}</div>
      </div>
    </div>
  );
});

export { ByUserName };
